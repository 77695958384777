import { Carousel } from "antd";
import React from "react";
import "./carousel.scss";

import laboratoryAI from "@assets/images/laboratoryAI.jpg";
import educationAI from "@assets/images/educationAI.jpg";
import militaryAI from "@assets/images/militaryAI.jpg";
import { useTranslation } from "react-i18next";

const contentStyle = {
  height: "100%",
  color: "#fff",
};

const CarouselComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="carousel-container-cls">
      <Carousel autoplay dotPosition="top">
        <div style={contentStyle} className="box-conatiner">
          <img
            className="img-custom-cls"
            src={laboratoryAI}
            alt="laboratoryAI"
          />
        </div>
        <div style={contentStyle} className="box-conatiner">
          <img
            className="img-custom-cls"
            src={educationAI}
            alt="laboratoryAI"
          />
        </div>
        <div style={contentStyle} className="box-conatiner">
          <img className="img-custom-cls" src={militaryAI} alt="laboratoryAI" />
        </div>
      </Carousel>
    </div>
  );
};
export default CarouselComponent;
