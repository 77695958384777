import axios from "axios";
export const BASE_URL = "https://laoscitec.api.minastik.com";
const request = (requestObject) => {
    axios.defaults.baseURL = BASE_URL;
    return axios.request(requestObject)
}

export const getNews_list = async (pageNumber,language) => {
    const lan = sessionStorage.getItem("LANGUAGE");
    const data = await request({
        url: "/api/blog",
        method: "GET",
        params: { 
            pageNumber: pageNumber, 
            language: (language = lan), 
            pageSize: 9 ,
            type: "news"
        }
    })
    return data;
}

export const postNews_list = async (data,language) => {
    return request({
        url: "/api/blog",
        method: "POST",
        data,
    })
}

export const updateNews_list = async (id,data) => {
    const lan = sessionStorage.getItem("LANGUAGE");
    return request({
        url: `/api/blog/edit/${id}`,
        method: "PUT",
        data,
    })
}

export const deleteNews_list = async (id) => {
    return request({
        url: `/api/blog/delete/${id}`,
        method: "DELETE"
    })
}

export const getNewsByGroupId = async (groupId) => {
    return request ({
        url:`/api/blog/group`,
        method: "GET",
        params: {
            groupId: groupId
        }
    })
}