import style from "./Header.module.scss";

import classNames from "classnames/bind";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faBars,
  faMagnifyingGlass,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from "react";
import { getCommon } from "../../api-url/common/index";
import {
  getCategory,
  getCategoryL,
  getCategoryGroupId,
  getCategoryById,
} from "@apiUrl/category/index.js";
import logo from "../../assets/images/logo.jpg";
import { useTranslation } from "react-i18next";
const cx = classNames.bind(style);

export const Header = () => {
  const navigate = useNavigate();
  const [showSeachBar, setShowSearchBar] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [phoneNumber_1, setPhoneNumber_1] = useState("");
  const [phoneNumber_2, setPhoneNumber_2] = useState("");

  const [productList, setProductList] = useState();
  const [productListOther, setProductListOther] = useState([]);

  const [valueSearch, setValueSearch] = useState("");

  const { t, i18n } = useTranslation();
  const lan = sessionStorage.getItem("LANGUAGE");

  const handleChangeLang = (lang) => {
    window.location.reload();
    sessionStorage.setItem("LANGUAGE", lang);
    i18n.changeLanguage(lang);
  };
  let menuRef = useRef();
  const openMenuButtonRef = useRef(null);
  const handleShowMenuMb = () => {
    setShowMenuMobile((prev) => !prev);
    setShowSearchBar(false);
    setShowSubMenu(false);
  };
  useEffect(() => {
    const handler = (event) => {
      if (
        !menuRef.current.contains(event.target) &&
        !openMenuButtonRef.current.contains(event.target)
      ) {
        setShowMenuMobile(false);
      }
    };
    document.addEventListener("mousedown", handler);
    setShowMenuMobile(false);

    return () => {
      document.removeEventListener("mousedown", handler);
      setShowMenuMobile(false);
    };
  }, [menuRef]);

  const handleClickSearchBtn = () => {
    setShowSearchBar((prev) => !prev);
  };
  const handleClickSubMenu = () => {
    setShowSubMenu((prev) => !prev);
    // setTimeout(() => {
    //   setShowSubMenu(false);
    // }, 2000);
  };

  const handleHiddenMenumb = () => {
    // console.log("blur menu");
    setShowMenuMobile(false);
    setShowSubMenu(false);
  };
  const handleBlurMenuMb = () => {
    // console.log("blur menu");
    setShowMenuMobile(false);
  };
  const handlemouseOut = () => {
    setTimeout(() => {
      setShowSubMenu(false);
    }, 200);
  };
  const handleSearch = () => {
    setValueSearch("");
    setShowSearchBar(false);
    setShowMenuMobile(false);
  };

  useEffect(() => {
    getCommon()
      .then((res) => res.data)
      .then((data) => {
        setPhoneNumber_1(data.data[0].value);
        setPhoneNumber_2(data.data[1].value);
      })
      .catch((err) => console.log(err));
    const isLaos = lan === "laos" ? true : false;
    // console.log("abc");
    getCategory(isLaos)
      .then((res) => res.data.data)
      .then((data) => {
        setProductList(data);
        return data;
        // sessionStorage.setItem("listCategory", data);
      })
      .then((data) => {
        data.forEach((item, index) => {
          getCategoryGroupId(item.groupId[0])
            .then((res) => res.data.data)
            .then((dataInput) => {
              dataInput.forEach((itemData) => {
                if (itemData._id !== item._id) {
                  setProductListOther((pre) => [...pre, itemData]);
                }
              });
            });
        });
      });
    // console.log(ArrayList.length ? `/${ArrayList[0].toLowerCase()}` :false)
  }, [lan]);

  // console.log("arrayList",array)
  const handleSubmit = (valueSearch) => {
    setValueSearch("");
    setShowSearchBar(false);
    if (showSeachBar) {
      setShowMenuMobile(false);
      return navigate(`/search-result?name=${valueSearch}`);
    }
  };
  const handleShowOff = () => {
    setShowMenuMobile(false);
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      // console.log("User pressed: ", event.key);

      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ call submit function here
        handleSubmit(valueSearch);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [valueSearch, showSeachBar]);
  const closeMenuMB = () => {
    setShowMenuMobile(false);
  };
  const handSendata = (product, productMain) => {
    if (lan === "laos") {
      sessionStorage.setItem("NameMainCategory", product.name);
      sessionStorage.setItem("NameMainCategoryOther", productMain.name);
    } else {
      sessionStorage.setItem("NameMainCategoryOther", product.name);
      sessionStorage.setItem("NameMainCategory", productMain.name);
    }
  };
  // console.log(productList[0]?.name)
  return (
    <div>
      <div className={cx("warpper_header")}>
        <header className={cx("header_top")}>
          <div className={cx("logo")}>
            <p style={{ cursor: "pointer" }}>
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </p>
          </div>
          <div className={cx("language_option")}>
            <div className={cx("language_E")}>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleChangeLang("en");
                }}
              >
                ENG
              </p>
            </div>
            <span className={cx("divide_lan")}></span>
            <div className={cx("language_L")}>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleChangeLang("laos");
                }}
              >
                LAO
              </p>
            </div>
          </div>
        </header>
        <div
          // onFocus={handleHiddenMenumb}
          // onMouseLeave={handleBlurMenuMb}
          onClick={handleShowMenuMb}
          className={cx("menu_mobile")}
          ref={openMenuButtonRef}
        >
          <FontAwesomeIcon icon={faBars} />
        </div>
        <header
          ref={menuRef}
          onMouseLeave={handleHiddenMenumb}
          className={cx(
            "header_bottom",
            showMenuMobile && "header_bottom_mobile"
          )}
        >
          <Link to="/" onClick={closeMenuMB} className={cx("navbar_header")}>
            {t("header.home")}
          </Link>
          <Link
            to="/about"
            onClick={closeMenuMB}
            className={cx("navbar_header")}
          >
            {t("header.about")}
          </Link>
          <div
            className={cx("navbar_header", showSubMenu && "navbar_select")}
            onClick={handleClickSubMenu}
            onMouseLeave={handlemouseOut}
            // onMouseOut={handlemouseOut}
          >
            <div>
              <ul>
                {t("header.products")}
                <span className={cx("icon_sub_menu")}>
                  <FontAwesomeIcon icon={faAngleUp} />
                </span>
              </ul>
              {showSubMenu && (
                <div className={cx("sub_menu")}>
                  {productList?.map((product, index) => {
                    // console.log(`/${product.name.toLowerCase()}`);

                    return (
                      <Link
                        onClick={() =>
                          handSendata(productListOther[index], product)
                        }
                        to={`/category/${product.name.toLowerCase()}`}
                        key={index}
                      >
                        <li onClick={closeMenuMB}>{product.name}</li>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <Link
            to="/news"
            onClick={closeMenuMB}
            className={cx("navbar_header")}
          >
            {t("header.news")}
          </Link>
          <Link
            to="/#contactUs"
            onClick={closeMenuMB}
            className={cx("navbar_header")}
          >
            {t("header.contactUs")}
          </Link>
          <div className={cx("navbar_header", "phone_icon")}>
            <FontAwesomeIcon icon={faPhoneVolume} />
          </div>
          {!showSeachBar && (
            <div
              className={cx("phone_number_container", showSeachBar && "w-0")}
            >
              <div className={cx("navbar_header")}>{phoneNumber_1}</div>
              {/* <div className={cx("navbar_header")}>{phoneNumber_2}</div> */}
              {showMenuMobile && (
                <div
                  className={cx("navbar_headermb", "search_iconmb")}
                  onClick={handleClickSearchBtn}
                >
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </div>
                </div>
              )}
            </div>
          )}
          {showSeachBar && (
            <div
              className={cx(
                "navbar_header",
                "search-container",
                !showSeachBar && "w-0"
              )}
            >
              <div className={cx("input_box")}>
                <input
                  value={valueSearch}
                  onChange={(e) => setValueSearch(e.target.value)}
                  placeholder={t("header.searchHolder")}
                />

                {showMenuMobile && (
                  <div
                    className={cx("navbar_headermb", "search_iconmb")}
                    onClick={handleClickSearchBtn}
                  >
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>
                  </div>
                )}
              </div>

              <div className={cx("search_input")}>
                <Link
                  to={{
                    pathname: `/search-result`,
                    search: `?name=${valueSearch}`,
                  }}
                >
                  <div className={cx("icon-container")} onClick={handleSearch}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </div>
                </Link>
              </div>
            </div>
          )}
          <div
            className={cx("navbar_header", "search_icon")}
            onClick={handleClickSearchBtn}
          >
            <div className="icon-container">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
