import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { withTranslation } from "react-i18next";
import GlobalStyle from "@components/globalStyle";
import "antd/dist/antd.min.css";
import ScrollToTop from "@routes/scrollPage";
import store from "./store/index";
import { Provider } from "react-redux";

const AppComponent = withTranslation()(App);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <GlobalStyle>
        <Suspense fallback="loading">
          <ScrollToTop>
            <AppComponent />
          </ScrollToTop>
        </Suspense>
      </GlobalStyle>
    </Provider>
  </BrowserRouter>
);
