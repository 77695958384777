import { faClapperboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import style from "./noData.module.scss";
import { useTranslation } from "react-i18next";

const cx = classNames.bind(style);

export const NotData = () => {
  const { t } = useTranslation();

  return (
    <div className={cx("nodata", "loading_replace")}>
      <div className={cx("notData_container")}>
        <div className={cx("notData")}>
          <FontAwesomeIcon className={cx("icon")} icon={faClapperboard} />
          <span>{t("nodata.nodata")}</span>
        </div>
      </div>
    </div>
  );
};
