import axios from "axios";
export const BASE_URL = "https://laoscitec.api.minastik.com";
const request = (requestObject) => {
  axios.defaults.baseURL = "https://laoscitec.api.minastik.com";
  return axios.request(requestObject);
};

export const getCategory = async (language) => {
  return request({
    url: "/api/category",
    method: "GET",
    params: {
      language: language ? "laos" : "en" , 
    }
  });
};
export const getCategoryL = async (language) => {
  return request({
    url: "/api/category",
    method: "GET",
    params: {
      language: (language = "laos"),
    },
  });
};
export const getCategoryById = async (parentId) => {
  return request({
    url: `/api/category/parent/${parentId}`,
    method: "GET",
  });
};

export const getCategoryGroupId = async (groupId) => {
  return request({
    url: `/api/category/group?groupId=${groupId}`,
    method: "GET",
  });
};

export const getCategoryLaos = async (language) => {
  return request({
    url: "/api/category",
    method: "GET",
    params: {
      language: (language = "laos"),
    },
  });
};

export const postCategory = async (data) => {
  return request({
    url: `/api/category`,
    method: "pOST",
    data,
  });
};

export const updateCategory = async (id, data) => {
  return request({
    url: `/api/category/edit/${id}`,
    method: "PUT",
    data,
  });
};
export const deleteCategory = async (id) => {
  return request({
    url: `/api/category/delete/${id}`,
    method: "DELETE",
  });
};
