import { Home } from "@modules/common/home";
import { AboutUs } from "@modules/common/about-us";
import { News } from "@modules/product/news";
import { ContactUs } from "@modules/common/contact-us";
import { MainCategory } from "@modules/product/category-list";
import { CategoryEducation } from "@modules/product/category-list_education";
import { CategoryMilatary } from "@modules/product/category-list_milatary";
import { ProductListEducation } from "@modules/product/product-list-education";
import { Search } from "../components/Search";
import { ProductList } from "@modules/product/product-list";
import { ProductDetail } from "@modules/product/product-detail";
import { Navigate } from "react-router-dom";
import { ProductListMB } from "../modules/product/product-list-mobie";
export const productRoutes = [
  {
    title: "Home",
    path: "/",
    component: Home,
    subItems: [],
    privateRoute: false,
  },
  {
    title: "Home",
    path: "/home",
    component: () => <Navigate to="/" />,
    subItems: [],
    privateRoute: false,
  },
  {
    title: "About Us",
    path: "/about",
    component: AboutUs,
    subItems: [],
    privateRoute: false,
  },
  {
    title: "News",
    path: "/news",
    component: News,
    subItems: [],
    privateRoute: false,
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    component: ContactUs,
    subItems: [],
    privateRoute: false,
  },
  {
    title: "Laboratory",
    path: "/category/:something",
    component: MainCategory,
    subItems: [],
    privateRoute: false,
  },

  {
    title: "Laboratory",
    path: "/category/:something/:categoryId",
    component: ProductList,
    subItems: [],
    privateRoute: false,
  },

  {
    title: "Laboratory",
    path: "/category/:something/:categoryId/:productDetail",
    component: ProductDetail,
    subItems: [],
    privateRoute: false,
  },

  {
    title: "Search",
    path: "/search-result/*",
    component: Search,
    subItems: [],
    privateRoute: false,
  },
  {
    title: "Product not Exit",
    path: "/*",
    component: ProductDetail,
    subItems: [],
    privateRoute: false,
  },
];
