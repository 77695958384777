import style from "./ProductList.module.scss";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import "./index.replace.scss";
import { Tabs, Pagination } from "antd";
import milatary from "../../../assets/images/milatary.png";
import { Loading } from "../../../components/Loading";
import { NotData } from "../../../components/NotData";
import { getProduct_Admin } from "../../../api-url/admin/index";
import { useTranslation } from "react-i18next";

const cx = classNames.bind(style);
const { TabPane } = Tabs;
const pageSize = 9;
export const CategoryMilatary = () => {
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState("Military & Security");
  const tabPosition = "left";
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [changeTab, setChangeTab] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(pageSize);
  const [specificData, setSpecificData] = useState([]);
  const [paging, setPaging] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const [components, setComponents] = useState(<Loading />);
  setTimeout(() => {
    setComponents(<NotData />);
  }, 10000);
  const getProduct = useCallback(async (pageNumber) => {
    getProduct_Admin(pageNumber)
      .then((res) => res.data)
      .then((data) => {
        // console.log(data.Paging);
        setPaging(data.Paging);
        return data.data;
      })
      .then((data) => {
        // console.log(data);
        setData((prev) => [...prev, ...data]);
      })
      .catch((err) => console.log(err));
  }, []);
  const onChange = (pageNumber, pageSize) => {
    // console.log(paging.totalCount);
    // console.log(pageSize);
    setCurrent(pageNumber);
    setMinIndex((pageNumber - 1) * pageSize);
    setMaxIndex(pageNumber * pageSize);
    setPageNumber(Math.ceil(paging.totalCount / paging.pageSize));
  };

  useEffect(() => {
    getProduct_Admin(1)
      .then((res) => {
        // console.log(res);
        return res?.data;
      })
      .then((data) => {
        // console.log(data);
        setPaging(data.Paging);
        return data?.data;
      })
      .then((data) => {
        // console.log(data);

        setData((prev) => [...prev, ...data]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const callback = async () => {
      if (paging?.pageSize && paging?.totalCount) {
        const totalPage = Math.ceil(paging?.totalCount / paging?.pageSize);
        if (totalPage && totalPage > 1) {
          for (let i = 2; i <= totalPage; i++) {
            await getProduct(i);
          }
        }
      }
    };
    callback();
  }, [getProduct, paging?.pageSize, paging?.totalCount]);
  // console.log(data);
  useEffect(() => {
    setSpecificData(() => {
      const newState = data.filter(
        (item) => item?.categoryId?.name === activeKey
      );
      // console.log("Received", newState);
      return newState.reverse();
    });
  }, [activeKey, data]);

  const handleChangeTab = (key) => {
    // console.log(key);
    setChangeTab(key);
    setActiveKey(key);
    setCurrent(1);
    setMinIndex(0);
    setMaxIndex(1 * pageSize);
  };
  return (
    <>
      <>
        <div
          className={cx(
            "wrapper_product_list_milatary",
            "product-list-milatary-container"
          )}
        >
          <div className={cx("header_prodList")}>
            <h2>{t("categoryList_milatary.milataryT")}</h2>
          </div>
          <div className={cx("body_prodList")}>
            <div className={cx("list_product")}>
              <div className={cx("header_product")}>
                <div className={cx("product_name")}>
                  <Tabs
                    activeKey={activeKey}
                    tabPosition={tabPosition}
                    onChange={handleChangeTab}
                  >
                    <TabPane
                      tab={t("categoryList_milatary.milatary")}
                      key="Military & Security"
                    >
                      <div className={cx("product_imgShow")}>
                        <div className={cx("milatary_content")}>
                          <img alt="" src={milatary}></img>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane
                      tab={t("categoryList_milatary.trainning_aids")}
                      key="Trainning Aids"
                    >
                      {specificData.length ? (
                        <>
                          <div className={cx("product")}>
                            {specificData?.map(
                              (data, index) =>
                                index >= minIndex &&
                                index < maxIndex && (
                                  <div
                                    className={cx("product_img")}
                                    key={index}
                                  >
                                    <Link
                                      to={{
                                        pathname: `/product_detail/${data.name.replace(
                                          / /g,
                                          "_"
                                        )}`,
                                        search: `?id=${data._id}&groupId=${data.groupId}`,
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={`https://laoscitec.api.minastik.com${data.images[0]}`}
                                          alt=""
                                        />
                                      </div>
                                      <p className={cx("sub_title")}>
                                        {data.name}
                                      </p>
                                    </Link>
                                  </div>
                                )
                            )}
                          </div>

                          <Pagination
                            pageSize={paging?.pageSize - 1}
                            current={current}
                            total={specificData?.length}
                            onChange={onChange}
                          />
                        </>
                      ) : (
                        components
                      )}
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
