import { News } from "@modules/product/news";
import { NewsDetail } from "@modules/product/news-detail";

export const news = [
  {
    title: "News",
    path: "/news",
    component: News,
    subItems: [],
    privateRoute: false,
  },
  {
    title: "News Detail",
    path: "/news/:id",
    component: NewsDetail,
    subItems: [],
    privateRoute: false,
  },
];
