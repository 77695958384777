import style from "./Breadcrumb.module.scss";
import React, { useMemo } from "react";
import classNames from "classnames/bind";
import { Breadcrumb } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRef } from "react";

const cx = classNames.bind(style);
function BreadCrumb() {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = useLocation();
  const searchPathName = useRef();
  // console.log("pathname", pathname);
  const splitPath = pathname.split("/");
  const operationBre = splitPath.filter((items) => items === undefined);
  const newPathName = pathname?.replace("/category", "");
  if (newPathName === "/") return <></>;
  const pages = newPathName.split("/");
  pages.shift();
  // console.log("pages", splitPath);
  return operationBre === undefined ? (
    <></>
  ) : (
    <div>
      <div className={cx("wrapper_breadcrumb")}>
        <div className={cx("breadcrumb_route")}>
          <Breadcrumb>
            <Link to="/">
              <Breadcrumb.Item>{t("header.home")}</Breadcrumb.Item>
            </Link>
            <span className={cx("arrow-right")}></span>
            {pages.map((page, index) => {
              // console.log("breadCrumb", decodeURI(`/${page}`));
              let pathname = "/category";
              if (index === pages.length - 1) pathname = "#";
              else
                for (let i = 0; i <= index; i++) {
                  pathname += decodeURI(`/${pages[i]}`);
                }
              const newPath = pathname.split("/");
              const a = newPath.filter((item) => item === "news");
              if (a[0] === "news") {
                pathname = pathname.replace("/category", "");
              }
              // console.log("abcccc", !!a);
              // console.log("abcccc", a[0] === "news");

              return (
                <Breadcrumb key={index}>
                  {index === pages.length - 1 ? (
                    <p to={pathname.toLowerCase()}>
                      <Breadcrumb.Item>
                        {t(
                          `link_breadcrumbs.${decodeURI(
                            page.replace(/_/g, " ")
                          )}`
                        ).replace("link_breadcrumbs.", "")}
                      </Breadcrumb.Item>
                    </p>
                  ) : (
                    <Link to={pathname}>
                      <Breadcrumb.Item>
                        {t(
                          `link_breadcrumbs.${decodeURI(
                            page.replace(/_/g, " ")
                          )}`
                        ).replace("link_breadcrumbs.", "")}
                      </Breadcrumb.Item>
                    </Link>
                  )}

                  <span className={cx("arrow-right")}></span>
                </Breadcrumb>
              );
            })}
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
}

export default BreadCrumb;
