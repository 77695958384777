import { MainCategory } from "@modules/product/category-list";    

export const category = [
    {
        title: "Main Category",
        path: "/main-category",
        component: MainCategory,
        subItems: [],
        privateRoute: false,
    },
]