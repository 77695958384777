import classNames from "classnames/bind";
import style from "./news-detail.module.scss";
import { getNews_details, BASE_URL } from "@apiUrl/news-detail/index.js";

import { getNewsByGroupId } from "@apiUrl/news/index.js";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { convertDataToDateString, parserFromAPI } from "@utils/functions";

import { Loading } from "@components/Loading";

import calendar from "@assets/icons/calendar.svg";

const lan = sessionStorage.getItem("LANGUAGE");

const cx = classNames.bind(style);

export const NewsDetail = () => {
  const groupId = sessionStorage.getItem("newsDetailGroupId");
  const [data, setData] = useState([]);
  const [ID, setID] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getNewsByGroupId(groupId)
      .then((res) => res.data.data)
      .then((data) => {
        const language = lan === "laos" ? 2 : 1;
        data.forEach((item, index) => {
          if (item.languageId === language) {
            setID(item._id);
          }
        });
      });
  }, [groupId]);

  useEffect(() => {
    getNews_details(ID)
      .then((res) => res.data.data)
      .then((data) => {
        if (data !== null) {
          setData(data?.blog[0]);
        }
      });
  }, [ID, lan]);

  const titleOther = sessionStorage.getItem("titleNewsDetailOther");
  const title = sessionStorage.getItem("titleNewsDetail");
  useEffect(() => {
    if (lan === "laos") {
      return navigate(`/news/${titleOther}`);
    } else {
      return navigate(`/news/${title}`);
    }
  }, [title, titleOther, lan]);
  return data?._id ? (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <div
          dangerouslySetInnerHTML={{
            __html: parserFromAPI(data.label).body.innerHTML,
          }}
          className={cx(
            "main_title custom-family-news",
            "d-flex justify-content-center"
          )}
        ></div>
        <div className={cx("date", "d-flex justify-content-end")}>
          <img src={calendar} alt={" "} />
          <p>{convertDataToDateString(data.createdAt)}</p>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: parserFromAPI(data.content).body.innerHTML,
          }}
          className={cx("subcription")}
        ></div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};
