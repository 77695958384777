import classNames from "classnames/bind";
import style from "./main-category.module.scss";
import { Link, useNavigate } from "react-router-dom";

import chemicals from "@assets/images/chemicals.png";
import equipments from "@assets/images/equipments.png";
import { useTranslation } from "react-i18next";
import {
  getCategory,
  getCategoryById,
  BASE_URL,
  getCategoryGroupId,
} from "../../../api-url/category";
import {
  getChildren,
  formatData,
  formatTreeData,
} from "../../../utils/functions";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loading } from "../../../components/Loading";
import { NotData } from "../../../components/NotData";

const cx = classNames.bind(style);

export const MainCategory = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [menuArr, setMenuArr] = useState([]);
  const [fullMenuArr, setFullMenuArr] = useState([]);
  const [fullMenuArrL, setFullMenuArrL] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [groupIdParent, setGroupIdParent] = useState(null);
  const [menuArrOther, setMenuArrOther] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [components, setComponents] = useState(<Loading />);
  const [title, setTitle] = useState(null);
  const navigate = useNavigate();
  const lan = sessionStorage.getItem("LANGUAGE");
  const otherCat = sessionStorage.getItem("NameMainCategory");
  const otherCatOther = sessionStorage.getItem("NameMainCategoryOther");

  useEffect(() => {
    if (isLoading) {
      setComponents(<Loading />);
    }
  }, [isLoading, menuArr]);

  useEffect(() => {
    if (menuArr?.length <= 0 && !isLoading) {
      setComponents(<NotData />);
    }
  }, [menuArr]);

  useEffect(() => {
    setIsLoading(true);
    const isLaos = lan === "laos" ? true : false;
    getCategory(isLaos)
      .then((res) => res.data.data)
      .then(async (dataInput) => {
        // console.log("dataInputdataInput", dataInput);
        await getChildren(dataInput, formatTreeData, getCategoryById);
        return dataInput;
      })
      .then((dataInput) => {
        // console.log("dataInput", dataInput);
        let data = dataInput.find((data) => {
          return (
            data.name.toLowerCase() ===
            decodeURI(pathname.replace("/category/", ""))
          );
        });
        return data;
      })
      .then((dataInput) => {
        // console.log(dataInput);
        setTitle(dataInput?.name);
        setGroupIdParent(dataInput?.groupId);
        setFullMenuArrL(dataInput?.children);
        const dataInputNew = dataInput;
        dataInputNew?.children.sort(function (a, b) {
          let beforeData = a.name.toUpperCase();
          let afterData = b.name.toUpperCase();

          if (beforeData < afterData) {
            return -1;
          }
          if (beforeData > afterData) {
            return 1;
          }
          return 0;
        });
        setMenuArr(dataInputNew?.children);
        setIsLoading(false);
        dataInput?.children.forEach((item, index) => {
          getCategoryGroupId(item.groupId[0])
            .then((res) => res.data.data)
            .then((dataInput) => {
              dataInput.forEach((itemData) => {
                if (itemData._id !== item._id) {
                  setMenuArrOther((pre) => [...pre, itemData]);
                }
              });
            });
        });
      })
      .catch((err) => console.log(err));
  }, [lan, pathname]);

  useEffect(() => {
    setFullData(fullMenuArr.concat(fullMenuArrL));
  }, [fullMenuArr, fullMenuArrL]);
  const handleSendData = (groupID, name, anotherName) => {
    sessionStorage.setItem("groupID", groupID);
    sessionStorage.setItem("groupParentID", groupIdParent);
    if (lan === "laos") {
      sessionStorage.setItem("nameAnotherProduct", name);
      sessionStorage.setItem("nameProduct", anotherName.name);
    } else {
      sessionStorage.setItem("nameProduct", name);
      sessionStorage.setItem("nameAnotherProduct", anotherName.name);
    }
  };
  useEffect(() => {
    if (lan === "laos") {
      return navigate(`/category/${otherCatOther}`);
    } else {
      // console.log("abc");
      return navigate(`/category/${otherCat?.toLowerCase()}`);
    }
  }, [lan, navigate, otherCat, otherCatOther]);
  return !isLoading && menuArr?.length > 0 ? (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <div className={cx("main_title", "d-flex justify-content-center")}>
          <p className={cx("custom-family")}>{title}</p>
        </div>

        <div className={cx("row")}>
          {menuArr?.map((arr, index) => {
            // console.log("breadaaaa", arr.name.replace(/ /g, "_"));
            return (
              <div className={cx("col-lg-4", "custom-clss")} key={index}>
                <div className={cx("wrapper-item")}>
                  <Link
                    onClick={() =>
                      handleSendData(
                        arr?.groupId,
                        arr.name,
                        menuArrOther[index]
                      )
                    }
                    to={{
                      pathname: `/${pathname.replace(
                        "/",
                        ""
                      )}/${arr.name.replace(/ /g, "_")}`,
                    }}
                    className={cx("category-item")}
                  >
                    <img alt="" src={`${BASE_URL}${arr.images}`} />
                    <div
                      className={cx(
                        "box-function",
                        "d-flex justify-content-center align-items-center"
                      )}
                    >
                      <p className={cx("custom_font_family")}>{arr?.name}</p>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    components
  );
};
