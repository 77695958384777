import style from "./Search.module.scss";

import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getProduct_search } from "../../api-url/admin/index";
import { NotData } from "../NotData/index";
import { Loading } from "../Loading/index";

const cx = classNames.bind(style);

export const Search = () => {
  const { t } = useTranslation();
  const [dataArr, setDataArr] = useState([]);
  const [searchParams] = useSearchParams();
  const [components, setComponents] = useState(<Loading />);

  const name = searchParams.get("name");
  setTimeout(() => {
    setComponents(<NotData />);
  }, 10000);
  const getProduct = useCallback(async (name) => {
    getProduct_search(name)
      .then((res) => res.data)
      .then((data) => {
        return data.data.reverse();
      })
      .then((dataInput) => {
        // console.log(dataInput);
        setDataArr(dataInput);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    getProduct(name);
  }, [getProduct, name]);

  return (
    <div>
      <div className={cx("warpper_search", "container")}>
        <div className={cx("header_search", "row")}>
          <p>
            {t(`search.search`)} <span>"{name}"</span>
          </p>
        </div>

        {dataArr.length ? (
          <div className={cx("result_search", "row")}>
            {dataArr.map((data, index) => {
              // console.log(data.categoryId);
              return (
                <div key={index} className={cx("product_search", "col-lg-4")}>
                  <Link
                    key={index}
                    to={`/product_detail/${data.name.replace(/ /g, "_")}?id=${
                      data._id
                    }&groupId=${data.groupId}`}
                  >
                    <div className={cx("items_search")}>
                      <div className={cx("img_search")}>
                        <img
                          alt=""
                          src={`https://laoscitec.api.minastik.com${data.images[0]}`}
                        />
                      </div>
                      <p>{data.name}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          components
        )}
      </div>
    </div>
  );
};

export default Search;
