import axios from "axios";
const request = (requestObject) => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  if (accessToken)
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  axios.defaults.baseURL = "https://laoscitec.api.minastik.com";
  return axios.request(requestObject);
};

export const login = async (data) => {
  return request({
    url: `/api/auth/login`,
    method: "POST",
    data,
  });
};
export const signup = async (data) => {
  return request({
    url: `/api/auth/signup`,
    method: "POST",
    data,
  });
};
export const forgot = async (email) => {
  return request({
    url: "/api/auth/forgot",
    method: "GET",
    params: { email: email },
  });
};
export const changePass = async (data) => {
  return request({
    url: "api/auth/change-pass",
    method: "PUT",
    data,
  });
};
