import style from "./ProductDetail.module.scss";

import classNames from "classnames/bind";

import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  getGroupIdProduct,
  getProductManager_Admin,
} from "../../../api-url/admin/index";
import { Loading } from "../../../components/Loading";
import { NotData } from "../../../components/NotData";
import { useNavigate, useSearchParams } from "react-router-dom";
import { parserFromAPI } from "@utils/functions";
const cx = classNames.bind(style);
export const ProductDetail = ({ props }) => {
  const [changeImg, setChangeImg] = useState(0);

  const [imgChange, setImgChange] = useState([]);
  const [searchParams] = useSearchParams();
  const [dataArr, setDataArr] = useState([]);
  const [dataDetail, setDataDetail] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const [paging, setPaging] = useState({});
  const [components, setComponents] = useState(<Loading />);
  setTimeout(() => {
    setComponents(<NotData />);
  }, 10000);
  const lan = sessionStorage.getItem("LANGUAGE");

  const nameAnotherProduct = sessionStorage.getItem("nameAnotherProduct");
  const NameMainCategoryOther = sessionStorage.getItem("NameMainCategoryOther");
  const NameMainCategory = sessionStorage.getItem("NameMainCategory");
  const nameProduct = sessionStorage.getItem("nameProduct");
  const idSession = sessionStorage.getItem("id");
  const groupIdSession = sessionStorage.getItem("groupId");
  const nameProductDetail = sessionStorage.getItem("nameProductDetail");
  const idOther = sessionStorage.getItem("idOther");
  const nameProductDetailOther = sessionStorage.getItem(
    "nameProductDetailOther"
  );
  const navigate = useNavigate();
  // console.log(searchParams.get("id"));

  const getProduct = useCallback(async (pageNumber) => {
    getProductManager_Admin(pageNumber)
      .then((res) => res.data)
      .then((data) => {
        setPaging(data.Paging);

        return data.data;
      })
      .then((dataInput) => {
        return dataInput.map((data, index) => ({
          key: data._id,
          Title: data.name,
          Date: "25/10/2020",
          Category: data.categoryId,
          languageId: data.languageId,
          Number_add: 9823,
          Gallery: data.images,
          Content_L: "BTR-3u armoured personnel carier",
          Select_value: "Sub-Category",
          Content_E: JSON.parse(data.description),
          groupId: data.groupId,
        }));
      })
      .then((data) => {
        // console.log(data);
        setDataArr((pre) => [...pre, ...data]);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    getProduct(pageNumber);
  }, [getProduct, pageNumber]);
  useEffect(() => {
    const callback = async () => {
      if (paging?.pageSize && paging?.totalCount) {
        const totalPage = Math.ceil(paging?.totalCount / paging?.pageSize);
        if (totalPage && totalPage > 1) {
          for (let i = 2; i <= totalPage; i++) {
            await getProduct(i);
          }
        }
      }
    };
    callback();
  }, [getProduct, paging?.pageSize, paging?.totalCount]);
  useEffect(() => {
    const id = searchParams.get("id");
    const groupId = searchParams.get("groupId");
    if (id && dataArr.length !== 0) {
      const dataDetail = dataArr.find((item) => {
        // console.log(item.key);
        return item.groupId === Number(groupId);
      });
      //console.log(dataDetail);
      if (dataDetail) {
        // console.log(dataDetail);
        setDataDetail(dataDetail);
        setImgChange(dataDetail.Gallery);
      }
    }
  }, [dataArr, searchParams]);
  // useEffect(() => {
  //   getGroupIdProduct(groupIdSession)
  //     .then((res) => res.data.data)
  //     .then((data) => {
  //       data.forEach((item, index) => {
  //         if (item._id !== idSession) {
  //           setDataDetail(item);
  //           setImgChange(item.images);
  //           // sessionStorage.setItem("id", item._id);
  //           // sessionStorage.setItem("nameProductDetail", item.name);
  //         }
  //       });
  //     });
  // }, [lan, groupIdSession, idSession]);

  useEffect(() => {
    // const id = searchParams.get("id");
    // const groupId = searchParams.get("groupId");
    if (lan === "laos") {
      return navigate(
        `/category/${NameMainCategoryOther}/${nameAnotherProduct}/${nameProductDetailOther}?id=${idOther}&groupId=${groupIdSession}`
      );
    } else {
      return navigate(
        `/category/${NameMainCategory}/${nameProduct}/${nameProductDetail}?id=${idSession}&groupId=${groupIdSession}`
      );
    }
  }, [
    NameMainCategory,
    NameMainCategoryOther,
    groupIdSession,
    idOther,
    idSession,
    lan,
    nameAnotherProduct,
    nameProduct,
    nameProductDetail,
    nameProductDetailOther,
    navigate,
  ]);
  const handleChangeImg = (index) => {
    setChangeImg(index);
  };
  const handleNextImg = (e) => {
    setChangeImg((prev) => (prev === imgChange.length - 1 ? 0 : prev + 1));
  };
  const handlePrevImg = () => {
    setChangeImg((prev) => (prev === 0 ? imgChange.length - 1 : prev - 1));
  };
  return dataArr.length ? (
    <>
      <div className={cx("wrapper_prodetail")}>
        <div className={cx("header_product_detail")}>
          <h2>{dataDetail?.Title}</h2>
        </div>
        <div className={cx("content_product_detail")}>
          <div className={cx("content_img")}>
            {imgChange.length ? (
              <div className={cx("img_view")}>
                <img
                  src={`https://laoscitec.api.minastik.com${imgChange[changeImg]}`}
                  alt=""
                />
              </div>
            ) : (
              <></>
            )}
            <div className={cx("img_selection")}>
              <div onClick={handlePrevImg} className={cx("icon_left")}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </div>
              <div className={cx("list-image")}>
                {imgChange.map((src, index) => {
                  return (
                    <div key={index}>
                      <img
                        className={cx(
                          "img_select",
                          changeImg === index && "img_selected"
                        )}
                        onClick={() => handleChangeImg(index)}
                        src={`https://laoscitec.api.minastik.com${src}`}
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
              <div onClick={handleNextImg} className={cx("icon_right")}>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
          </div>
          <div className={cx("content_detail")}>
            {/* <pre> {dataDetail?.Content_E}</pre> */}
            <div
              dangerouslySetInnerHTML={{
                __html: parserFromAPI(dataDetail?.Content_E).body.innerHTML,
              }}
              className={cx("subcription")}
            ></div>
          </div>
        </div>
      </div>
    </>
  ) : (
    components
  );
};
