import style from "./Products.module.scss";
import classNames from "classnames/bind";
import "./index.replace.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Table, Popconfirm, Typography, TreeSelect } from "antd";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, notification, message } from "antd";

import pen from "@assets/icons/icon_categories/pen.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  faPlus,
  faTrashCan,
  faCircleXmark,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  getCategory,
  getCategoryById,
  getCategoryGroupId,
} from "../../../api-url/category/index";
import {
  getProductManager_Admin,
  postProduct_Admin,
  updateProduct_Admin,
  deleteProduct_Admin,
  getProductManager_Admin_laos,
  getProductManager_Admin_en,
  getGroupIdProduct,
} from "../../../api-url/admin/index";
import { useTranslation } from "react-i18next";
import { Loading } from "../../Loading";
import { NotData } from "../../NotData";
import {
  checkTypeImage,
  dataURLtoFile,
  getBase64,
  handlePaste,
  parserFromAPI,
} from "../../../utils/functions";
import axios, { Axios } from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const cx = classNames.bind(style);
const $ = document.querySelector.bind(document);

// const imgChange = [imgSelector_1, imgInorganic, imgSelector_2, imgSelector_3];
export const Product = () => {
  const { t } = useTranslation();

  const [nameAdd, setNameAdd] = useState("");
  const [nameLaoAdd, setNameLaoAdd] = useState("");
  const [contentEAdd, setContentEAdd] = useState("");
  const [contentLaosAdd, setContentLaosAdd] = useState("");

  const [edittingRow, setEdittingRow] = useState(null);
  const [viewRow, setViewRow] = useState(null);
  const [keyEdit, setkeyEdit] = useState("");

  const [changeTitle, setChangeTitle] = useState("");
  const [contentView, setContentView] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  const [previewImage, setPreviewImage] = useState("");
  const [imgChange, setImgChange] = useState([]);
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [paging, setPaging] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [groupId, setGroupId] = useState("");
  const lan = sessionStorage.getItem("LANGUAGE");
  const [urlImgae, setUrlImage] = useState([]);
  const [urlEdit, setUrlEdit] = useState([]);
  const [deleteImg, setDeleteImg] = useState(false);
  const [components, setComponents] = useState(<Loading />);
  const [isLoading, setIsLoading] = useState(true);
  const quillContentE = useRef();
  const quillContentL = useRef();

  useEffect(() => {
    if (isLoading) {
      setComponents(<Loading />);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data.length <= 0 && !isLoading) {
      setComponents(<NotData />);
    }
  }, [data]);

  const imgHandler = (e) => {
    // handlers object will be merged with default handlers object
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  const saveToServer = async (file) => {
    const image = new FormData();
    image.append("images", file);
    const fileExtension = file.type.slice(6);

    if (checkTypeImage(fileExtension)) {
      await axios
        .post("https://laoscitec.api.minastik.com/api/upload-image", image)
        .then((res) => res.data.data)
        .then((data) => {
          insertToEditor(data[0].lgUrl);
        });
    } else {
      notification["error"]({
        message: `${t("admin_news.notice.updateFail")}`,
        description: `${t("admin_news.notice.descriptionFailUploadImage")}`,
        style: {
          color: "rgba(0, 0, 0, 0.65)",
          border: "1px solid #ffa39e",
          backgroundColor: "#fff1f0",
          borderRadius: "5px",
        },
      });
    }
  };

  const insertToEditor = (url) => {
    const ContentEnglish = quillContentE.current.getEditor();
    const Contentlaos = quillContentL.current.getEditor();
    if (ContentEnglish.hasFocus()) {
      const range = ContentEnglish.getSelection();
      ContentEnglish.insertEmbed(
        range.index,
        "image",
        `https://laoscitec.api.minastik.com${url}`
      );
    } else if (Contentlaos.hasFocus()) {
      const range = Contentlaos.getSelection();
      Contentlaos.insertEmbed(
        range.index,
        "image",
        `https://laoscitec.api.minastik.com${url}`
      );
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, true] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ image: "image" }],
          [{ link: "link" }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
        ],
        handlers: {
          image: imgHandler, // Reference to imgHandler
        },
      },
    }),
    []
  );

  // const [groupId, setGroupId] = useState("");
  //select Tree
  const [selectedNode, setSelectedNode] = useState(null);
  const getProduct = useCallback(async (pageNumber) => {
    const lan = sessionStorage.getItem("LANGUAGE");
    getProductManager_Admin(pageNumber)
      .then((res) => res.data)
      .then((data) => {
        setPaging(data.Paging);
        return data.data;
      })
      .then((dataInput) => {
        // console.log(dataInput);
        const dataNew = dataInput;
        // console.log(dataNew);
        return dataNew.map((data, index) => ({
          key: data._id,
          No: index + 1,
          Title: data.name,
          Category: data.categoryId?.name,
          languageId: data.languageId,
          // Number_add: nameLaoAdd,
          Gallery: data.images,
          content_E_HTML: data.description,
          content_L_HTML: data.description,
          Content_L: parserFromAPI(data.description).documentElement
            .textContent,
          Select_value: data.categoryId?._id,
          Content_E: parserFromAPI(data.description).documentElement
            .textContent,
          groupId: data.groupId,
        }));
      })
      .then((data) => {
        // console.log(data);
        setData(data);
        // console.log(data.Gallery);
        // setUrlImage(data.Gallery);

        const groupId = data.reduce((acc, item, id) => {
          acc[id] = item.groupId;
          return acc;
        }, []);
        // console.log(groupId);
        // if (lan === "en") {
        getProductManager_Admin_laos(pageNumber, groupId)
          .then((dataL) => dataL.data.data)
          .then((dataL) => {
            const dataApi = data.map((datasub, indexE) => {
              // console.log(datasub);
              const newData = dataL.map((dataLsub, indexL) => {
                if (datasub.groupId === dataLsub.groupId) {
                  return {
                    No: datasub.No,
                    key: datasub.key,
                    Title: datasub.Title,
                    Category: datasub.Category,
                    languageId: datasub.languageId,
                    Number_add: dataLsub.name,
                    Gallery: datasub.Gallery,
                    Content_L: parserFromAPI(dataLsub.description)
                      .documentElement.textContent,
                    Select_value: datasub.Select_value,
                    Content_E: parserFromAPI(datasub.Content_E).documentElement
                      .textContent,
                    groupId: datasub.groupId,
                    content_E_HTML: datasub.content_E_HTML,
                    content_L_HTML: dataLsub.description,
                  };
                } else return null;
              });
              // console.log(newData);
              return [...newData];
            });
            return dataApi;
          })
          .then((data) => {
            let newData = [];
            for (let i = 0; i < data.length; i++) {
              for (let j = 0; j < data[i].length; j++) {
                newData.push(data[i][j]);
              }
            }
            var filtered = newData.filter(function (el) {
              return el != null;
            });
            return filtered;
          })
          .then((data) => {
            setData(data);
            setUrlImage(data.Gallery);
          });
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    getProduct(pageNumber);
  }, [getProduct, pageNumber]);

  const formatTreeData = (data) => {
    data.value = data._id;
    data.title = data.name;
  };
  const getChildren = async (rootArr) => {
    const cloneArr = [...rootArr];
    while (cloneArr.length > 0) {
      const tail = cloneArr.pop();
      formatTreeData(tail);
      const res = await getCategoryById(tail._id);
      tail.children = res.data.data;
      res.data.data.forEach((item) => {
        cloneArr.push(item);
      });
    }
  };
  useEffect(() => {
    const currentUrl = window.location.href;
    getCategory()
      .then((res) => res.data)
      .then((data) => data.data)
      .then(async (dataInput) => {
        await getChildren(dataInput);
        setTreeData(dataInput);
        // console.log(dataInput);
      });
  }, []);
  //img_view
  const [changeImg, setChangeImg] = useState(0);
  const handleChangeImg = (index) => {
    setChangeImg(index);
  };
  const handleNextImg = (e) => {
    setChangeImg((prev) => (prev === imgChange.length - 1 ? 0 : prev + 1));
  };
  const handlePrevImg = () => {
    setChangeImg((prev) => (prev === 0 ? imgChange.length - 1 : prev - 1));
  };
  /// MOdal
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    const noticeError = $(".noticeOff");
    noticeError?.classList.remove("noticeOn");
    setIsModalVisible(true);
    setViewRow(null);
  };

  const handleOk = async (e) => {
    const noticeError = $(".noticeOff");
    // const lan = sessionStorage.getItem("LANGUAGE");

    const thumbUrl = fileList.map((url) => url.thumbUrl);
    const url = thumbUrl.map((url) => url);
    url
      .map((url) => url.replace("data:image/png;base64,", ""))
      .map((url) => url);
    console.log({
      images: urlEdit,
      name: nameAdd,
      description: JSON.stringify(contentEAdd),
      languageId: 1,
      categoryId: selectedNode?._id,
    });

    // console.log(urlImgae);
    postProduct_Admin({
      images: urlEdit,
      name: nameAdd,
      description: JSON.stringify(contentEAdd),
      languageId: 1,
      categoryId: selectedNode?._id,
    })
      .then((res) => {
        setUrlImage([]);
        return res.data.data.groupId;
      })
      .then((resGroupId) => {
        getCategoryGroupId(selectedNode.groupId[0])
          .then((res) => res.data.data)
          .then((dataLaos) => {
            postProduct_Admin({
              images: urlEdit,
              name: nameLaoAdd,
              description: JSON.stringify(contentLaosAdd),
              languageId: 2,
              categoryId: dataLaos[1]?._id,
              groupId: resGroupId,
            }).then((res) => {
              notification["success"]({
                message: `${t("admin_static.notice.success")}`,
                description: `${t("admin_static.notice.descriptionsSuc")}`,
                style: {
                  color: "rgba(0, 0, 0, 0.65)",
                  border: "1px solid #b7eb8f",
                  backgroundColor: "#f6ffed",
                  borderRadius: "5px",
                },
              });
              setIsLoading(true);
              setIsModalVisible(false);
              getProductManager_Admin(pageNumber)
                .then((res) => res.data)
                .then((data) => {
                  setPaging(data.Paging);
                  return data.data;
                })
                .then((dataInput) => {
                  // console.log(dataInput);
                  const dataNew = dataInput;

                  return dataNew.map((data, index) => ({
                    key: data._id,
                    No: index + 1,
                    Title: data.name,
                    Category: data.categoryId?.name,
                    languageId: data.languageId,
                    // Number_add: nameLaoAdd,
                    Gallery: data.images,
                    Content_L: JSON.parse(data.description),
                    Select_value: data.categoryId?._id,

                    Content_E: JSON.parse(data.description),
                    groupId: data.groupId,
                  }));
                })
                .then((data) => {
                  const groupId = data.reduce((acc, item, id) => {
                    acc[id] = item.groupId;
                    return acc;
                  }, []);
                  // console.log(groupId);
                  getProductManager_Admin_laos(pageNumber, groupId)
                    .then((dataL) => dataL.data.data)
                    .then((dataL) => {
                      const dataApi = data.map((datasub, indexE) => {
                        const newData = dataL.map((dataLsub, indexL) => {
                          if (datasub.groupId === dataLsub.groupId) {
                            return {
                              No: datasub.No,
                              key: datasub.key,
                              Title: datasub.Title,
                              Category: datasub.Category,
                              languageId: datasub.languageId,
                              Number_add: dataLsub.name,
                              Gallery: datasub.Gallery,
                              Content_L: JSON.parse(dataLsub.description),
                              Select_value: datasub.Select_value,
                              Content_E: datasub.Content_E,
                              groupId: datasub.groupId,
                            };
                          } else return null;
                        });
                        // console.log(newData);
                        return [...newData];
                      });
                      return dataApi;
                    })
                    .then((data) => {
                      let newData = [];
                      for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < data[i].length; j++) {
                          newData.push(data[i][j]);
                        }
                      }
                      var filtered = newData.filter(function (el) {
                        return el != null;
                      });
                      return filtered;
                    })
                    .then((data) => {
                      setIsLoading(false);

                      setData(data);
                    });
                })
                .catch((err) => {
                  console.log(err);
                  notification["error"]({
                    message: `${t("admin_static.notice.updateFail")}`,
                    description: err.response.data.errors[0].message,
                    style: {
                      color: "rgba(0, 0, 0, 0.65)",
                      border: "1px solid #ffa39e",
                      backgroundColor: "#fff1f0",
                      borderRadius: "5px",
                    },
                  });
                });
            });
          });
      })
      .catch((err) => {
        notification["error"]({
          message: `${t("admin_static.notice.updateFail")}`,
          description: err.response.data.errors[0].message,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #ffa39e",
            backgroundColor: "#fff1f0",
            borderRadius: "5px",
          },
        });
        // noticeError?.classList.add("noticeOn");
        setIsModalVisible(true);
      });
    setUrlImage([]);
    setUrlEdit([]);
    setNameAdd("");
    setNameLaoAdd("");
    setContentEAdd("");
    setContentLaosAdd("");
    setSelectedNode(null);
    setFileList([]);
  };
  // if (fileList.length) {
  //   if (fileList.length === 2) {
  //     console.log(urlImgae);
  //     const aad = urlImgae.splice(0, 1);
  //     console.log("newwwwwwww", aad);
  //   }
  // }
  // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>", urlImgae);
  // useEffect(() => {
  //   console.log("urlImage", urlImgae);
  //   console.log("ediurl", urlEdit);
  //   // console.log("");
  // }, [urlImgae, urlEdit]);
  const handleChange = ({ fileList: newFileList }) => {
    // console.log(getBase64(newFileList.originFileObj));
    setFileList(newFileList);
    // console.log(newFileList);
    // console.log(urlImgae);
    const groupImg = [];
    newFileList.map((file, index) => {
      let formData = new FormData();
      formData.append("images", file?.originFileObj);
      // console.log("file", file);
      if (file?.originFileObj) {
        console.log(">>>>>");
        axios
          .post("https://laoscitec.api.minastik.com/api/upload-image", formData)
          .then((res) => {
            // setUrlImage([]);
            return res.data.data;
          })
          .then((data) => {
            // console.log("data", data);

            groupImg.push(data[0].lgUrl);

            // setUrlImage([]);
            // setUrlImage((prev) => [...prev, data[0].lgUrl]);
            // console.log(groupImg);
            return groupImg;
          })

          .then((data) => {
            setDeleteImg(true);
            console.log("groupImg", data);
            // setUrlImage(data);
            setUrlEdit(data);
          })
          .catch((err) => {
            setFileList([]);

            notification["error"]({
              message: `CHANGE IMAGE FAILED`,
              description: `File too large`,
              style: {
                color: "rgba(0, 0, 0, 0.65)",
                border: "1px solid #ffa39e",
                backgroundColor: "#fff1f0",
                borderRadius: "5px",
              },
            });
          });
      } else {
        setDeleteImg(true);
        formData.append("images", file);
        axios
          .post("https://laoscitec.api.minastik.com/api/upload-image", formData)
          .then((res) => {
            // setUrlImage([]);
            return res.data.data;
          })
          .then((data) => {
            // console.log("data", data);

            groupImg.push(data[0].lgUrl);

            // setUrlImage([]);
            // setUrlImage((prev) => [...prev, data[0].lgUrl]);
            // console.log(groupImg);
            return groupImg;
          })

          .then((data) => {
            // console.log("groupImg", data);
            // setUrlImage(data);
            setUrlEdit(data);
          })
          .catch((err) => {
            setFileList([]);

            notification["error"]({
              message: `CHANGE IMAGE FAILED`,
              description: `File too large`,
              style: {
                color: "rgba(0, 0, 0, 0.65)",
                border: "1px solid #ffa39e",
                backgroundColor: "#fff1f0",
                borderRadius: "5px",
              },
            });
          });
      }
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setNameAdd("");
    setNameLaoAdd("");
    setContentEAdd("");
    setContentLaosAdd("");
    setEdittingRow(null);
    setSelectedNode(null);
    setViewRow(null);
    setFileList([]);
    setUrlImage([]);
    setUrlEdit([]);
  };

  const hanldeChangePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const Delete = async (key) => {
    try {
      // console.log(groupId);
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        newData.splice(index, 1);
        deleteProduct_Admin(key).then((res) => {
          getGroupIdProduct(groupId)
            .then((data) => data.data.data)
            .then((data) => data[0]._id)
            .then((data) => {
              deleteProduct_Admin(data)
                .then((res) => {
                  notification["success"]({
                    message: `${t("admin_static.notice.success")}`,
                    description: `${t("admin_static.notice.descriptionsSuc")}`,
                    style: {
                      color: "rgba(0, 0, 0, 0.65)",
                      border: "1px solid #b7eb8f",
                      backgroundColor: "#f6ffed",
                      borderRadius: "5px",
                    },
                  });
                  getProductManager_Admin(pageNumber)
                    .then((res) => res.data)
                    .then((data) => {
                      setPaging(data.Paging);
                      return data.data;
                    })
                    .then((dataInput) => {
                      // console.log(dataInput);
                      const dataNew = dataInput;
                      setIsLoading(true);

                      return dataNew.map((data, index) => ({
                        key: data._id,
                        No: index + 1,
                        Title: data.name,
                        Category: data.categoryId?.name,
                        languageId: data.languageId,
                        // Number_add: nameLaoAdd,
                        Gallery: data.images,
                        Content_L: JSON.parse(data.description),
                        Select_value: data.categoryId?.value,
                        Content_E: JSON.parse(data.description),
                        groupId: data.groupId,
                      }));
                    })
                    .then((data) => {
                      setData(data);
                      const groupId = data.reduce((acc, item, id) => {
                        acc[id] = item.groupId;
                        return acc;
                      }, []);
                      // console.log(groupId);

                      getProductManager_Admin_laos(pageNumber, groupId)
                        .then((dataL) => dataL.data.data)
                        .then((dataL) => {
                          const dataApi = data.map((datasub, indexE) => {
                            const newData = dataL.map((dataLsub, indexL) => {
                              if (datasub.groupId === dataLsub.groupId) {
                                return {
                                  No: datasub.No,
                                  key: datasub.key,
                                  Title: datasub.Title,
                                  Category: datasub.Category,
                                  languageId: datasub.languageId,
                                  Number_add: dataLsub.name,
                                  Gallery: datasub.Gallery,
                                  Content_L: JSON.parse(dataLsub.description),
                                  Select_value: datasub.Select_value,
                                  Content_E: datasub.Content_E,
                                  groupId: datasub.groupId,
                                };
                              } else return null;
                            });
                            // console.log(newData);
                            return [...newData];
                          });
                          return dataApi;
                        })
                        .then((data) => {
                          let newData = [];
                          for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < data[i].length; j++) {
                              newData.push(data[i][j]);
                            }
                          }
                          var filtered = newData.filter(function (el) {
                            return el != null;
                          });
                          return filtered;
                        })
                        .then((data) => {
                          setIsLoading(false);

                          setData(data);
                        });

                      // if (lan === "laos") {
                      //   getProductManager_Admin_en(pageNumber, groupId)
                      //     .then((dataL) => dataL.data.data)
                      //     .then((dataL) => {
                      //       // console.log(dataL);
                      //       const dataApi = data.map((datasub, indexE) => {
                      //         const newData = dataL.map((dataLsub, indexL) => {
                      //           if (datasub.groupId === dataLsub.groupId) {
                      //             return {
                      //               No: datasub.No,
                      //               key: datasub.key,
                      //               Title: datasub?.Title,
                      //               Number_add: dataLsub.name,
                      //               Category: datasub.Category,
                      //               languageId: datasub.languageId,
                      //               Gallery: datasub.Gallery,
                      //               Content_L: JSON.parse(
                      //                 dataLsub?.description
                      //               ),
                      //               Select_value: datasub.Select_value,
                      //               Content_E: datasub.Content_E,
                      //               groupId: datasub.groupId,
                      //             };
                      //           } else return null;
                      //         });
                      //         // console.log(newData);
                      //         return [...newData];
                      //       });
                      //       return dataApi;
                      //     })
                      //     .then((data) => {
                      //       let newData = [];
                      //       for (let i = 0; i < data.length; i++) {
                      //         for (let j = 0; j < data[i].length; j++) {
                      //           newData.push(data[i][j]);
                      //         }
                      //       }
                      //       var filtered = newData.filter(function (el) {
                      //         return el != null;
                      //       });
                      //       return filtered;
                      //     })
                      //     .then((data) => setData(data));
                      // }
                    })
                    .catch((err) => console.log(err));
                })
                .catch((err) => {
                  setIsModalVisible(true);
                  notification["error"]({
                    message: `${t("admin_static.notice.updateFail")}`,
                    description: err.response.data.errors[0].message,
                    style: {
                      color: "rgba(0, 0, 0, 0.65)",
                      border: "1px solid #ffa39e",
                      backgroundColor: "#fff1f0",
                      borderRadius: "5px",
                    },
                  });
                });
            });
        });
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      notification["error"]({
        message: `${t("admin_static.notice.updateFail")}`,
        description: `${t("admin_static.notice.descriptionsFail")}`,
        style: {
          color: "rgba(0, 0, 0, 0.65)",
          border: "1px solid #ffa39e",
          backgroundColor: "#fff1f0",
          borderRadius: "5px",
        },
      });
    }
  };
  const save = async (e) => {
    // const noticeError = $(".noticeOff");
    const newData = [...data];
    const index = newData.findIndex((item) => keyEdit.key === item.key);
    const updateData = (newData[index] = {
      key: newData[index].key,
      Date: newData[index].Date,
      No: newData[index].No,
      Title: nameAdd,
      Gallery: fileList,
      Category: selectedNode?.title,
      Content_E: contentEAdd,
      Content_L: contentLaosAdd,
      Number_add: nameLaoAdd,
      Select_value: selectedNode?.value,
    });
    newData[index] = updateData;
    // update API
    const thumbUrl = fileList.map((url) => url.thumbUrl);
    const url = thumbUrl.map((url) => url);
    url
      .map((url) => url.replace("data:image/png;base64,", ""))
      .map((url) => url);
    // console.log(urlImgae);
    const newUrlImage = urlImgae.concat(urlEdit);
    updateProduct_Admin(updateData.key, {
      images: deleteImg ? urlEdit : urlImgae,
      name: updateData.Title,
      description: JSON.stringify(updateData.Content_E),
      languageId: 1,
      categoryId: selectedNode?._id,
    })
      .then((data) => {
        setIsModalVisible(false);
        setIsLoading(true);
        setUrlImage([]);
        setUrlEdit([]);
        setNameAdd("");
        setNameLaoAdd("");
        setContentEAdd("");
        setContentLaosAdd("");
        setSelectedNode(null);
        setFileList([]);

        getGroupIdProduct(groupId)
          .then((data) => data.data.data)
          .then((dataGroupIdLaos) => {
            // console.log(data[1]._id);
            getCategoryGroupId(selectedNode.groupId[0])
              .then((res) => res.data.data)
              .then((dataLaos) => {
                updateProduct_Admin(dataGroupIdLaos[1]._id, {
                  images: deleteImg ? urlEdit : urlImgae,
                  name: nameLaoAdd,
                  description: JSON.stringify(contentLaosAdd),
                  languageId: 2,
                  categoryId: dataLaos[1]?._id,
                }).then((data) => {
                  setDeleteImg(false);
                  notification["success"]({
                    message: `${t("admin_static.notice.success")}`,
                    description: `${t("admin_static.notice.descriptionsSuc")}`,
                    style: {
                      color: "rgba(0, 0, 0, 0.65)",
                      border: "1px solid #b7eb8f",
                      backgroundColor: "#f6ffed",
                      borderRadius: "5px",
                    },
                  });
                  getProductManager_Admin(pageNumber)
                    .then((res) => res.data)
                    .then((data) => {
                      setPaging(data.Paging);
                      return data.data;
                    })
                    .then((dataInput) => {
                      // console.log(dataInput);
                      const dataNew = dataInput;

                      return dataNew.map((data, index) => ({
                        key: data._id,
                        No: index + 1,
                        Title: data.name,
                        Category: data.categoryId?.name,
                        languageId: data.languageId,
                        // Number_add: nameLaoAdd,
                        Gallery: data.images,
                        Content_L: JSON.parse(data.description),
                        Select_value: data.categoryId?._id,

                        Content_E: JSON.parse(data.description),
                        groupId: data.groupId,
                      }));
                    })
                    .then((data) => {
                      const groupId = data.reduce((acc, item, id) => {
                        acc[id] = item.groupId;
                        return acc;
                      }, []);
                      // console.log(groupId);
                      getProductManager_Admin_laos(pageNumber, groupId)
                        .then((dataL) => dataL.data.data)
                        .then((dataL) => {
                          const dataApi = data.map((datasub, indexE) => {
                            const newData = dataL.map((dataLsub, indexL) => {
                              if (datasub.groupId === dataLsub.groupId) {
                                return {
                                  No: datasub.No,
                                  key: datasub.key,
                                  Title: datasub.Title,
                                  Category: datasub.Category,
                                  languageId: datasub.languageId,
                                  Number_add: dataLsub.name,
                                  Gallery: datasub.Gallery,
                                  Content_L: JSON.parse(dataLsub.description),
                                  Select_value: datasub.Select_value,
                                  Content_E: datasub.Content_E,
                                  groupId: datasub.groupId,
                                };
                              } else return null;
                            });
                            // console.log(newData);
                            return [...newData];
                          });
                          return dataApi;
                        })
                        .then((data) => {
                          let newData = [];
                          for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < data[i].length; j++) {
                              newData.push(data[i][j]);
                            }
                          }
                          var filtered = newData.filter(function (el) {
                            return el != null;
                          });
                          return filtered;
                        })
                        .then((data) => {
                          setIsLoading(false);

                          setData(data);
                        });
                    });
                });
              });
          })
          .catch((err) => {
            // noticeError?.classList.add("noticeOn");
            notification["error"]({
              message: `${t("admin_static.notice.updateFail")}`,
              description: err.response.data.errors[0].message,
              style: {
                color: "rgba(0, 0, 0, 0.65)",
                border: "1px solid #ffa39e",
                backgroundColor: "#fff1f0",
                borderRadius: "5px",
              },
            });
            setIsModalVisible(true);
          });
      })
      .catch((err) => {
        setIsModalVisible(true);

        notification["error"]({
          message: `${t("admin_static.notice.updateFail")}`,
          description: err.response.data.errors[0].message,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #ffa39e",
            backgroundColor: "#fff1f0",
            borderRadius: "5px",
          },
        });
      });
  };
  const handleEditRow = (row) => {
    setUrlImage(row.Gallery);
    // console.log(row.Gallery);
    const getBase64FromUrl = async (url) => {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    };

    setkeyEdit(row);
    setGroupId(row.groupId);
    setEdittingRow(row);
    setViewRow(null);
    setNameAdd(row?.Title);
    setNameLaoAdd(row?.Number_add);
    setContentLaosAdd(row?.content_L_HTML);
    setContentEAdd(row?.content_E_HTML);
    setSelectedNode({ value: row?.Select_value });
    // console.log(row);
    const ArrThumbnail = row.Gallery;
    const ArrThumbnailnew = [];
    ArrThumbnail.map((url, index) => {
      getBase64FromUrl(`https://laoscitec.api.minastik.com${url}`)
        .then((log) => {
          // console.log(log);
          ArrThumbnailnew.push(log);

          return ArrThumbnailnew;
        })
        .then((data) => {
          if (ArrThumbnail.length === index + 1) {
            setFileList(() => {
              return data.map((url, index) => {
                const file = dataURLtoFile(url, `${index}.png`);

                if (file && typeof file === "object") file.thumbUrl = url;

                return file || {};
              });
            });
          }
        });
    });

    // setFileList(() => {
    //   return row.Gallery.map((url, index) => {
    //     const file = dataURLtoFile(url, `${index}.png`);

    //     if (file && typeof file === "object") file.thumbUrl = url;
    //     return file || {};
    //   });
    // });
    showModal(true);
    setUrlEdit([]);

    // setUrlImage([]);
  };
  const handleViewRow = (row) => {
    // console.log(row);
    setViewRow(row);
    setIsModalVisible(true);
    setNameAdd("");
    setNameLaoAdd("");
    setContentEAdd("");
    setContentLaosAdd("");
    setEdittingRow(null);
    setSelectedNode(null);
    setFileList([]);
    setImgChange(row.Gallery);
    setContentView(row.Content_E);
    setChangeTitle(row.Title);
  };
  const onChange = (newValue) => {};
  const handleSelect = (value, node, extra) => {
    // console.log("node", node);
    setSelectedNode(node);
  };
  const handleSelectDelete = (row) => {
    setSelectedNode(row);
    setGroupId(row.groupId);
    // console.log(row);
  };
  const columns = [
    {
      title: `${t("admin_product.colums.no")}`,
      dataIndex: "No",
      width: "5%",
      editable: true,
    },
    {
      title: `${t("admin_product.colums.name")}`,
      dataIndex: "Title",
      width: "15%",
      editable: true,
    },
    {
      title: `${t("admin_product.colums.category")}`,
      dataIndex: "Category",
      width: "15%",
      editable: true,
    },
    {
      title: `${t("admin_product.colums.gallery")}`,
      dataIndex: "Gallery",
      width: "15%",
      editable: true,
      render: (text, record, index) => {
        // text.map((record) => console.log(record));
        return (
          <>
            <div className="img_gallery" key={index}>
              {text.map((record, index) => {
                if (index < 2)
                  return (
                    <img
                      key={index}
                      alt=""
                      src={`https://laoscitec.api.minastik.com${record}`}
                    />
                  );
                else return null;
              })}
            </div>
          </>
        );
      },
    },
    {
      title: `${t("admin_product.colums.content")}`,
      dataIndex: "Content_E",
      width: "40%",
      editable: true,
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: `${t("admin_product.colums.action")}`,
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <div className={cx("operation_btn")}>
            <Typography.Link>
              {/* <Link
                style={{ color: "black" }}
                // to="/"
                to={`/product_detail/${viewRow?.Title.replace(/ /g, "_")}?id=${
                  viewRow?.key
                }&groupId=${viewRow?.groupId}`}
                target="_blank"
              > */}
              <div onClick={() => handleViewRow(record)}>
                <FontAwesomeIcon icon={faEye} />{" "}
                {t("admin_product.handle.view")}
              </div>
              {/* </Link> */}
            </Typography.Link>
            <Typography.Link>
              <div onClick={() => handleEditRow(record)}>
                <img src={pen} alt="" /> {t("admin_product.handle.edit")}
              </div>
            </Typography.Link>
            <Popconfirm
              title={t("admin_product.handle.confirm")}
              onConfirm={() => Delete(record.key)}
              icon={<FontAwesomeIcon icon={faCircleXmark} />}
              okText={t("admin_product.handle.delete")}
              cancelText={t("admin_product.handle.cancel")}
            >
              <Typography.Link onClick={() => handleSelectDelete(record)}>
                <FontAwesomeIcon icon={faTrashCan} />
                {t("admin_product.handle.delete")}
              </Typography.Link>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleCancel_img = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  // console.log(urlImgae);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("admin_product.handle.upload")}
      </div>
    </div>
  );

  const handleChangeContentE = (e) => {
    const target = document.getElementById("contentE");
    handlePaste(target);
    setContentEAdd(e);
  };

  const handleChangeContentL = (e) => {
    const target = document.getElementById("contentL");
    handlePaste(target);
    setContentLaosAdd(e);
  };

  return (
    <div className={cx("product_wrapper", "product_table")}>
      <div className={cx("modal_wrapper")}>
        <Button type="primary" onClick={showModal}>
          <FontAwesomeIcon icon={faPlus} />
          {t("admin_product.handle.add")}
        </Button>
      </div>

      <Modal
        wrapClassName="modal_products"
        title={
          viewRow
            ? changeTitle
            : edittingRow
            ? `${t("admin_product.handle.titleEdit")}`
            : `${t("admin_product.handle.add")}`
        }
        visible={isModalVisible}
        onOk={edittingRow ? (type) => save(type) : (type) => handleOk(type)}
        onCancel={handleCancel}
        cancelText={t("admin_product.handle.cancel")}
        okText={
          edittingRow
            ? `${t("admin_product.handle.upload")}`
            : `${t("admin_product.handle.addBt")}`
        }
        okButtonProps={
          viewRow
            ? { style: { display: "none" } }
            : { style: { display: "block" } }
        }
      >
        {viewRow ? (
          <div className="view_page">
            <div className={cx("content_img")}>
              <div className={cx("img_view")}>
                <img
                  src={`https://laoscitec.api.minastik.com${imgChange[changeImg]}`}
                  alt=""
                />
              </div>
              <div className={cx("img_selection")}>
                <div onClick={handlePrevImg} className={cx("icon_left")}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </div>
                <div className={cx("list-image")}>
                  {imgChange.map((src, index) => {
                    return (
                      <div key={index}>
                        <img
                          className={cx(
                            "img_select",
                            changeImg === index && "img_selected"
                          )}
                          onClick={() => handleChangeImg(index)}
                          src={`https://laoscitec.api.minastik.com${src}`}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
                <div onClick={handleNextImg} className={cx("icon_right")}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </div>
            <div className={cx("content_wrap")}>
              <pre className={cx("content_view")}>{contentView}</pre>
            </div>
          </div>
        ) : (
          <>
            <h3>Name / ຊື່</h3>
            <form>
              <div className={cx("div_label")}>
                <label>{lan === "en" ? "ENG" : "ປະເທດລາວ"}</label>
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  value={nameAdd}
                  onChange={(e) => setNameAdd(e.target.value)}
                />
              </div>
              <div className="noticeOff">{t("admin_product.notice")}</div>
              <br></br>
              <div className={cx("div_label")}>
                <label>{lan === "en" ? "ປະເທດລາວ" : "ENG"}</label>
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  value={nameLaoAdd}
                  onChange={(e) => setNameLaoAdd(e.target.value)}
                />
              </div>
            </form>

            <br></br>

            <h3>Category / ເນື້ອໃນ</h3>
            <form>
              <div className="label_tree_product">
                {/* <label>{t("admin_product.handle.select")}</label> */}
                <label>Select</label>
                <TreeSelect
                  placeholder={t("admin_product.handle.plSelect")}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  treeData={treeData}
                  treeDefaultExpandAll
                  defaultValue="Please select"
                  onChange={onChange}
                  value={selectedNode?.value}
                  onSelect={handleSelect}
                />
              </div>
            </form>

            <br></br>

            <h3>Gallery / ຄັງຮູບ</h3>
            <form>
              <div className={cx("description_label")}>
                <>
                  <Upload
                    action="https://laoscitec.api.minastik.com/api/image-upload"
                    beforeUpload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    className={cx("upload_image")}
                  >
                    {fileList.length >= 5 ? null : uploadButton}
                  </Upload>
                  <Modal
                    wrapClassName="modal_img"
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel_img}
                  >
                    <img
                      alt="example"
                      style={{
                        width: "100%",
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </>
              </div>
            </form>

            <br></br>

            <h3>Description / ລາຍລະອຽດ</h3>
            <form>
              <div className={cx("description_label")}>
                <label>{lan === "en" ? "ENG" : "ປະເທດລາວ"}</label>
                {/* <textarea
                  value={contentEAdd}
                  onChange={(e) => setContentEAdd(e.target.value)}
                /> */}

                <ReactQuill
                  ref={quillContentE}
                  theme="snow"
                  id="contentE"
                  className="quill-cls-custom"
                  modules={modules}
                  value={contentEAdd}
                  onChange={handleChangeContentE}
                />
              </div>
              <div className={cx("description_label")}>
                <label>{lan === "en" ? "ປະເທດລາວ" : "ENG"}</label>

                {/* <textarea
                  value={contentLaosAdd}
                  onChange={(e) => setContentLaosAdd(e.target.value)}
                /> */}
                <ReactQuill
                  ref={quillContentL}
                  theme="snow"
                  id="contentL"
                  modules={modules}
                  value={contentLaosAdd}
                  onChange={handleChangeContentL}
                />
              </div>
            </form>
          </>
        )}
      </Modal>

      {data.length ? (
        <Table
          bordered
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            total: paging?.totalCount,
            pageSize: paging?.pageSize,
            showTitle: false,
            onChange: hanldeChangePagination,
          }}
        />
      ) : (
        components
      )}
    </div>
  );
};

export default Product;
