import React from "react";
import { Form, Input, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { forgot } from "../../../api-url/login";
import "./ForgotPassword.scss";
import { useCallback } from "react";

const $ = document.querySelector.bind(document);

function ForgotPassword() {
  const navigate = useNavigate();

  const forgotAccount = useCallback(
    async (email) => {
      const error_email = $(".email_error_notice");
      try {
        forgot(email)
          .then((data) => {
            alert("Sent your email!");
            return navigate("/admin_laoscitec/login");
          })
          .catch(() => error_email.classList.add("email_error"));
        // .catch(error_email.classList.add("email_error"));
      } catch {
        error_email.classList.add("email_error");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onFinish = (values) => {
    // console.log("Success:", values);

    forgotAccount(values.email);
  };

  return (
    <div>
      <div className="background_login">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="forgot_form">
            <div className="close">
              <Link to="/admin_laoscitec/login">
                <CloseOutlined />
              </Link>
            </div>

            <div className="login_title">
              <h1>Forgot password?</h1>
              <p>
                Please enter your email address or usename and we will email you
                a link to reset your password
              </p>
            </div>
            <Form.Item
              label=""
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email !",
                },
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit">
                Request password reset email
              </Button>
            </Form.Item>
            <div className="email_error_notice">
              Email not exists ! Please check your against
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;
