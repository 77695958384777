import style from "./Footer.module.scss";
import { useState, useEffect } from "react";
import { getCommon } from "../../api-url/common/index";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import mail from "@assets/icons/mail.svg";
import phone from "@assets/icons/phone.svg";
import {
  getCategory,
  getCategoryById,
  getCategoryL,
  getCategoryGroupId,
} from "../../api-url/category";
import { getChildren, formatTreeData } from "../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
const cx = classNames.bind(style);

export const Footer = () => {
  const { t } = useTranslation();
  const lan = sessionStorage.getItem("LANGUAGE");
  const [phoneNumber_1, setPhoneNumber_1] = useState("");
  const [phoneNumber_2, setPhoneNumber_2] = useState("");
  const [emailContact, setEmailContact] = useState("");
  const [subLaboratary, setSubLaboratary] = useState();
  const [subEducation, setSubEducation] = useState();
  const [subMilatary, setSubMilatary] = useState();
  const [title, setTitle] = useState([]);
  const [menuArr, setMenuArr] = useState([]);
  const [otherCat, setOtherCat] = useState([]);

  useEffect(() => {
    getCommon()
      .then((res) => res.data)
      .then((data) => {
        setPhoneNumber_1(data.data[0].value);
        setPhoneNumber_2(data.data[1].value);
        setEmailContact(data.data[2].value);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    const isLaos = lan === "laos" ? true : false;
    // console.log("abc");
    getCategory(!isLaos)
      .then((res) => res.data.data)
      .then((data) => {
        // setProductList(data);
        // console.log("footer data", data);
        setOtherCat(data);
        return data;
        // sessionStorage.setItem("listCategory", data);
      });
  }, []);
  useEffect(() => {
    if (lan === "laos") {
      getCategoryL()
        .then((res) => res.data)
        .then((data) => data.data)
        .then(async (dataInput) => {
          setTitle(dataInput);

          await getChildren(dataInput, formatTreeData, getCategoryById);
          const subLaborataryFilter = dataInput[0].children.filter(
            (item) => item.languageId === 2
          );
          const subEducationFilter = dataInput[1].children.filter(
            (item) => item.languageId === 2
          );
          const subMilataryFilter = dataInput[2].children.filter(
            (item) => item.languageId === 2
          );
          setSubLaboratary(subLaborataryFilter);
          setSubEducation(subEducationFilter);
          setSubMilatary(subMilataryFilter);
          setMenuArr(dataInput);
        })

        .catch((err) => console.log(err));
    } else {
      getCategory()
        .then((res) => res.data)
        .then((data) => data.data)
        .then(async (dataInput) => {
          // console.log(dataInput);
          setTitle(dataInput);
          await getChildren(dataInput, formatTreeData, getCategoryById);
          const subLaborataryFilter = dataInput[0].children.filter(
            (item) => item.languageId === 1
          );
          const subEducationFilter = dataInput[1].children.filter(
            (item) => item.languageId === 1
          );
          const subMilataryFilter = dataInput[2].children.filter(
            (item) => item.languageId === 1
          );
          setSubLaboratary(subLaborataryFilter);
          setSubEducation(subEducationFilter);
          setSubMilatary(subMilataryFilter);
          setMenuArr(dataInput);
        })

        .catch((err) => console.log(err));
    }
  }, [lan]);
  const handleSentData = (
    groupParentId,
    groupId,
    name,
    MainCategory,
    id,
    idParent
  ) => {
    sessionStorage.setItem("groupID", groupId);
    sessionStorage.setItem("groupParentID", groupParentId);
    // sessionStorage.setItem("nameProduct", name);
    // sessionStorage.setItem("NameMainCategory", MainCategory);
    getCategoryGroupId(groupParentId)
      .then((res) => res.data.data)
      .then((data) => {
        console.log("daaaaa", data);
        data.forEach((item, index) => {
          if (item._id !== idParent) {
            if (lan === "laos") {
              sessionStorage.setItem("NameMainCategoryOther", MainCategory);
              sessionStorage.setItem("NameMainCategory", item.name);
            } else {
              sessionStorage.setItem("NameMainCategoryOther", item.name);
              sessionStorage.setItem("NameMainCategory", MainCategory);
            }
          }
        });
      })
      .then(() => {
        getCategoryGroupId(groupId)
          .then((res) => res.data.data)
          .then((data) => {
            data.forEach((item, index) => {
              if (item._id !== id) {
                if (lan === "laos") {
                  sessionStorage.setItem("nameAnotherProduct", name);
                  sessionStorage.setItem("nameProduct", item.name);
                } else {
                  sessionStorage.setItem("nameProduct", name);
                  sessionStorage.setItem("nameAnotherProduct", item.name);
                }
              }
            });
          });
      });
  };
  const handleSendDataCat = (name, otherName) => {
    if (lan === "laos") {
      sessionStorage.setItem("NameMainCategoryOther", name);
      sessionStorage.setItem("NameMainCategory", otherName.name);
    } else {
      sessionStorage.setItem("NameMainCategoryOther", otherName.name);
      sessionStorage.setItem("NameMainCategory", name);
    }
  };
  return (
    <div className={cx("wrapper_footer")}>
      <div className={cx("footer_top")}>
        <div className={cx("footer_infor")}>
          <div className={cx("footer_title")}>
            <div style={{ minHeight: "150px", flex: 1 }}>
              <h2>
                <Link
                  onClick={() =>
                    handleSendDataCat(title[0]?.name.toLowerCase(), otherCat[0])
                  }
                  to={`/category/${title[0]?.name
                    .toLowerCase()
                    .replace(/ /g, "_")}`}
                >
                  {title[0]?.name}
                </Link>
              </h2>
              {subLaboratary?.map((item, index) => (
                <div key={index}>
                  <h5>
                    <Link
                      onClick={() =>
                        handleSentData(
                          title[0]?.groupId[0],
                          item?.groupId[0],
                          item.name,
                          title[0]?.name.toLowerCase(),
                          item._id,
                          title[0]._id
                        )
                      }
                      to={{
                        pathname: `/category/${title[0]?.name
                          .toLowerCase()
                          .replace(/ /g, "_")}/${decodeURI(
                          item.name.replace(/ /g, "_")
                        )}`,
                        search: `?groupIdParent=${title[0]?.groupId[0]}&groupId=${item?.groupId[0]}`,
                      }}
                      state={{
                        foo: JSON.stringify(menuArr[0]),
                      }}
                    >
                      {item.name}
                    </Link>
                  </h5>
                </div>
              ))}
            </div>
            {/* <div className={cx("footer__contact")}>
              <div className={cx("footer_icon")}>
                <img src={phone} alt="" />
              </div>
              <div className={cx("footer_detail")}>
                <p>{phoneNumber_1}</p>
                <p>{phoneNumber_2}</p>
              </div>
            </div> */}
          </div>
          <div className={cx("footer_title")}>
            <div style={{ minHeight: "150px" }}>
              <h2>
                <Link
                  onClick={() =>
                    handleSendDataCat(title[1]?.name.toLowerCase(), otherCat[1])
                  }
                  to={`/category/${title[1]?.name
                    .toLowerCase()
                    .replace(/ /g, "_")}`}
                >
                  {title[1]?.name}
                </Link>
              </h2>
              {subEducation?.map((item, index) => (
                <div key={index}>
                  <h5>
                    <Link
                      onClick={() =>
                        handleSentData(
                          title[1]?.groupId[0],
                          item?.groupId[0],
                          item.name,
                          title[1]?.name.toLowerCase(),
                          item._id,
                          title[1]._id
                        )
                      }
                      to={{
                        pathname: `/category/${title[1]?.name
                          .toLowerCase()
                          .replace(/ /g, "_")}/${decodeURI(
                          item.name.replace(/ /g, "_")
                        )}`,
                        search: `?groupIdParent=${title[1]?.groupId[0]}&groupId=${item?.groupId[0]}`,
                      }}
                      state={{
                        foo: JSON.stringify(menuArr[0]),
                      }}
                    >
                      {item.name}
                    </Link>
                  </h5>
                </div>
              ))}
            </div>
            {/* <div className={cx("footer__contact", "email")}>
              <div className={cx("footer_icon", "icon_rotate")}>
                <FontAwesomeIcon icon={faLocationDot} />
              </div>
              <div className={cx("footer_detail")}>
                <p>{emailContact}</p>
              </div>
            </div> */}
          </div>
          <div className={cx("footer_title")}>
            <h2>
              <Link
                onClick={() =>
                  handleSendDataCat(title[2]?.name.toLowerCase(), otherCat[2])
                }
                to={`/category/${title[2]?.name
                  .toLowerCase()
                  .replace(/ /g, "_")}`}
              >
                {title[2]?.name}
              </Link>
            </h2>
            {subMilatary?.map((item, index) => (
              <div key={index}>
                <h5>
                  <Link
                    onClick={() =>
                      handleSentData(
                        title[2]?.groupId[0],
                        item?.groupId[0],
                        item.name,
                        title[2]?.name.toLowerCase(),
                        item._id,
                        title[2]._id
                      )
                    }
                    to={{
                      pathname: `/category/${title[2]?.name
                        .toLowerCase()
                        .replace(/ /g, "_")}/${decodeURI(
                        item.name.replace(/ /g, "_")
                      )}`,
                      search: `?groupIdParent=${title[2]?.groupId[0]}&groupId=${item?.groupId[0]}`,
                    }}
                    state={{
                      foo: JSON.stringify(menuArr[0]),
                    }}
                  >
                    {item.name}
                  </Link>
                </h5>
              </div>
            ))}
          </div>
        </div>
        {/* <div className={cx("footer_contactmb")}>
          <div className={cx("footer__contact")}>
            <div className={cx("footer_icon")}>
              <img src={phone} alt="" />
            </div>
            <div className={cx("footer_detail")}>
              <p>{phoneNumber_1}</p>
               <p>{phoneNumber_2}</p> 
            </div>
          </div>
          <div className={cx("footer__contact")}>
            <div className={cx("footer_icon", "icon_rotate")}>
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <div className={cx("footer_detail")}>
              <p>{emailContact}</p>
            </div>
          </div>
        </div> */}
      </div>
      <div className={cx("footer_bottom")}>
        <a target="_blank" href="https://minastik.com/" rel="noreferrer">
          {" "}
          <p>(C) 2022 Laoscitec. Designed by Minastik</p>
        </a>
      </div>
    </div>
  );
};
export default Footer;
