import axios from "axios";
import { BASE_URL } from "@apiUrl/news";
// handle table pagination
export function handlePagination(paging, sorter, setFilterConditions) {
  let sortKey = "orderBy";
  let currentKey = "orderByDesc";
  if (sorter.order === "descend") {
    sortKey = "orderByDesc";
    currentKey = "orderBy";
  } else {
    sortKey = "orderBy";
    currentKey = "orderByDesc";
  }
  setFilterConditions((oldState) => ({
    ...oldState,
    pageIndex: paging.current,
    pageSize: paging.pageSize,
    [sortKey]: sorter.order ? sorter.field : undefined,
    [currentKey]: undefined,
  }));
}

//get array of selected rows from a table
export function getSelectedRowKeys(dataSource) {
  const arr = [];
  dataSource.map((item) => {
    if (item.isSelected) {
      arr.push(item.id);
    }
    return null;
  });
  return arr;
}

// remove falsy key-value pair from an obj
export const removeUndefinedAttribute = (obj) => {
  const params = {};
  Object.keys(obj).map((key) => {
    if (obj[key]) {
      params[key] = obj[key];
    }
    return {};
  });
  return params;
};

// convert string of queryParams (retrieved from url bar) into object of queryParams
export const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring);
  const obj = {};
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      if (params.get(key) !== "undefined") obj[key] = params.getAll(key);
    } else {
      if (params.get(key) !== "undefined") obj[key] = params.get(key);
    }
  }

  return obj;
};

// convert object of queryParams into string of queryParams
// Ex: {
//   searchTerm: 'abc',
//   startDate: '15/06/2022',
//   endDate: '20/06/2022'
// } will be converted into searchTerm=abc&startDate=15/06/2022&endDate=20/06/2022
export function buildQueryString(object) {
  if (typeof object !== "object") return "";
  const args = [];
  for (const key in object) {
    destructure(key, object[key]);
  }
  return args.join("&");

  function destructure(key, value) {
    if (key && (value || value === false || value === 0)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else if (toString(value) === "[object Object]") {
        for (const i in value) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else
        args.push(
          encodeURIComponent(key) +
            (value != null && value !== "" && value !== undefined
              ? "=" + encodeURIComponent(value)
              : "")
        );
    }
  }
}

export function dataURLtoFile(dataurl, filename) {
  try {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
export const getBase64_test = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      // console.log("Called", reader);
      baseURL = reader.result;
      // console.log(baseURL);
      resolve(baseURL);
    };
    // console.log(fileInfo);
  });
};
export const getChildren = async (rootArr, formatTreeData, getCategoryById) => {
  const cloneArr = [...rootArr];
  // console.log(rootArr);
  while (cloneArr.length > 0) {
    const tail = cloneArr.pop();
    formatTreeData(tail);
    const res = await getCategoryById(tail._id);
    tail.children = res.data.data;

    // console.log(res.data.data);s
    res.data.data.forEach((item) => {
      cloneArr.push(item);
    });
  }
};
export const getChildrenSelectTree = async (
  rootArr,
  formatTreeData,
  getCategoryById
) => {
  const cloneArr = [...rootArr];
  // console.log(rootArr);
  while (cloneArr.length > 0) {
    const tail = cloneArr.pop();
    formatTreeData(tail);
    const res = await getCategoryById(tail._id);
    tail.children = res.data.data;
    const newChildren = tail.children.filter((item) => item.languageId === 1);
    tail.children = newChildren;
    // console.log(res.data.data);s
    res.data.data.forEach((item) => {
      cloneArr.push(item);
    });
  }
};

export const formatData = (data) => {
  let newArr = [];
  const newData = [...data];
  const formatData = newData.reduce((data, dataPre) => {
    return [...data, ...dataPre.children];
  }, []);
  const formatDataChildren = formatData.reduce((data, dataPre) => {
    return [...data, ...dataPre.children];
  }, []);
  const formatDataMinChild = formatDataChildren.reduce((data, dataPre) => {
    return [...data, ...dataPre.children];
  }, []);
  newArr = [
    ...newData,
    ...formatData,
    ...formatDataChildren,
    ...formatDataMinChild,
  ];
  return newArr;
};

export const formatTreeData = (data) => {
  data.value = data._id;
  data.title = data.name;
};

export const parserFromAPI = (data) => {
  const parser = new DOMParser();
  return parser.parseFromString(data, "text/html");
};

export const convertDataToDateString = (data) => {
  return `${data?.slice(8, 10)}/${data?.slice(5, 7)}/${data?.slice(0, 4)}`;
};

export const convertTableToHTML = (des, amount) => {
  return `${des}<split>${amount}<split>`;
};

export const convertHTMLToData = (data) => {
  const myArray = data.split("<split>");
  return myArray;
};
export const chunkArray = (myArray, chunk_size) => {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    const newData = { ...myChunk };
    tempArray.push(newData);
  }

  return Object(tempArray);
};

export const handlePaste = (target) => {
  target.addEventListener("paste", (event) => {
    let selection = window.getSelection();
    event.preventDefault();
    const files = event.clipboardData.files;
    let paste = event.clipboardData.getData("text");
    if (event.clipboardData.files[0]?.type?.startsWith("image/")) {
      // console.log("second")
      const image = new FormData();
      image.append("images", files[0]);
      const fileExtension = files[0].type.slice(6);
      (async (fileExtension, image, selection) => {
        if (checkTypeImage(fileExtension)) {
          await axios
            .post("https://laoscitec.api.minastik.com/api/upload-image", image)
            .then((res) => res.data.data)
            .then((data) => {
              // console.log("third")
              return data[0].lgUrl;
            })
            .then((data) => {
              // console.log(data)
              selection.deleteFromDocument();
              let image = document.createElement("img");
              image.src = `${BASE_URL}${data}`;
              selection.getRangeAt(0).insertNode(image);
            });
        }
      })(fileExtension, image, selection);
    } else {
      if (!selection.rangeCount) return;
      selection.deleteFromDocument();
      selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      // console.log(selection)
    }
  });
};

export const checkTypeImage = (fileExtension) => {
  const IMAGE_TYPE = ["jpg", "jpeg", "png"];
  let isValidFile = false;

  for (let index of IMAGE_TYPE) {
    if (fileExtension === index) {
      isValidFile = true;
    }
  }
  return isValidFile;
};
