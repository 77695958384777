import style from "./adminLayout.moulde.scss";
import Image from "@assets/images/img3.jpg";
import logo from "@assets/images/logo.jpg";

import classNames from "classnames/bind";
import { Button, Modal } from "antd";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { Categories } from "../Categories/Categories";
import { Product } from "../Products/Products";
import { News } from "../News/News";
import { StacticsContent } from "../StacticsContent/StacticsContent";
import { Other } from "../Other/Other";

import { getLanguage_Admin } from "../../../api-url/admin/index";
import { selectUser } from "../../../reducers/index";
import { useSelector } from "react-redux";
import { changePass } from "../../../api-url/login";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const cx = classNames.bind(style);
export const AdminLayout = ({ setAuth }) => {
  const { t, i18n } = useTranslation();

  const tabs = [
    {
      title: `${t("admin.categories")}`,
      component: Categories,
    },
    {
      title: `${t("admin.product")}`,
      component: Product,
    },
    {
      title: `${t("admin.news")}`,
      component: News,
    },
    {
      title: `${t("admin.stactics_content")}`,
      component: StacticsContent,
    },
    {
      title: `${t("admin.other")}`,
      component: Other,
    },
  ];
  const user = useSelector(selectUser);
  const [activedTab, setActivedTab] = useState(tabs[0]);
  const [englishLanguage, setEnglishLanguages] = useState([]);
  const [laosLanguage, setLaosLanguages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [passwordPresent, setPasswordPresent] = useState("");
  const [passwordChange, setPasswordChange] = useState("");
  const Component = activedTab.component;
  const $ = document.querySelector.bind(document);
  useEffect(() => {
    getLanguage_Admin()
      .then((data) => data.data)
      .then((data) => data.data)
      .then((data) => {
        setEnglishLanguages(data[0]);
        setLaosLanguages(data[1]);
      });
  }, []);

  const handleChangeLang = (lang) => {
    // window.location.reload();
    // sessionStorage.setItem("LANGUAGE", lang);
    i18n.changeLanguage(lang);
  };
  const handleClickTab = (item) => {
    setActivedTab(item);
  };
  const handleLogout = () => {
    localStorage.removeItem("ACCESS_TOKEN");
    setAuth(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
    setPasswordPresent("");
    setPasswordChange("");
  };

  const handleOk = (e) => {
    const wrongPass = $(".wrongPass");
    changePass({
      password: passwordPresent,
      newPassword: passwordChange,
    })
      .then((data) => console.log(data))
      .then(() => {
        setIsModalVisible(false);
      })
      .catch((err) => wrongPass.classList.add("noticeWrongPass"));
  };

  const handleCancel = () => {
    const wrongPass = $(".wrongPass");
    setIsModalVisible(false);
    setPasswordPresent("");
    setPasswordChange("");
    wrongPass.classList.remove("noticeWrongPass");
  };

  const handleChangePassword = () => {
    const change_pass = $(".changePassword");
    // console.log(change_pass);
    change_pass.classList.add("changePass");
  };
  const handleMoveChangePass = () => {
    const change_pass = $(".changePassword");

    change_pass.classList.remove("changePass");
  };
  const handleShowChangePass = () => {};
  return (
    <>
      <div className={cx("admin_layout_wrapper")}>
        <div className={cx("admin_menu")}>
          <div className={cx("menu_header")}>
            <div className={cx("logo")}>
              <p>
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </p>
            </div>
            <div className={cx("language_option")}>
              {/* <div
                onClick={() => {
                  handleChangeLang("en");
                }}
                className={cx("language_E")}
              >
                <p>{englishLanguage.name}</p>
              </div> */}
              {/* <span className={cx("divide_lan")}></span> */}
              {/* <div
                onClick={() => {
                  handleChangeLang("laos");
                }}
                className={cx("language_L")}
              >
                <p>{laosLanguage.name}</p>
              </div> */}
            </div>
            {tabs.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleClickTab(item)}
                  className={cx(
                    "content_selection",
                    item.title === activedTab.title && "content_selected"
                  )}
                >
                  <p>{item.title}</p>
                </div>
              );
            })}
          </div>
          <div className={cx("menu_bottom")}>
            <p onClick={handleLogout}>{t("admin.log_out")}</p>
            <FontAwesomeIcon
              className={cx("icon")}
              icon={faArrowRightFromBracket}
            />
          </div>
        </div>
        <div className={cx("admin_content")}>
          <div className={cx("admin_content_header")}>
            <div className={cx("content_header_title")}>
              <p>{activedTab.title}</p>
            </div>
            <div
              onMouseLeave={handleMoveChangePass}
              onMouseEnter={handleChangePassword}
              className={cx("content_header_user")}
            >
              <p>{user.email}</p>
              <img alt="" src={Image} />
              <div
                onClick={handleShowChangePass}
                className={cx("changePassword")}
              >
                <Button type="primary" onClick={showModal}>
                  Change Password
                </Button>
              </div>
              <Modal
                title="Change Password "
                wrapClassName="changePassWord"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <form onSubmit={handleOk}>
                  <label>Password :</label>
                  <br></br>
                  <input
                    placeholder="Enter your password..."
                    type="text"
                    name="passwordPresent"
                    value={passwordPresent}
                    onChange={(e) => setPasswordPresent(e.target.value)}
                  />
                  <div className="wrongPass">Password Wrong !</div>
                  <br></br>
                  <label className="label_newpass">New Password :</label>
                  <br></br>
                  <input
                    placeholder="Enter new password..."
                    type="text"
                    name="passwordChange"
                    value={passwordChange}
                    onChange={(e) => setPasswordChange(e.target.value)}
                  />
                  <br></br>
                </form>
              </Modal>
            </div>
          </div>
          <div className={cx("admin_content_detail")}>
            <Component />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
