import React from "react";
import { CloseOutlined } from "@ant-design/icons";
// import { CREATE_USER } from "../../../reducers/index";
// import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./NewAccount.scss";
import { Form, Input, Button } from "antd";
import { signup } from "../../../api-url/login";
// import { useDispatch } from "react-redux";
// import { getUsers, createUser } from "../../../sever/apis";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const $ = document.querySelector.bind(document);

function NewAccount() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const validatePassword = (password) => {
    return password.match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@])[a-zA-Z\d@]{8,}$/
    );
  };

  const handelNotice = (e) => {
    if (validatePassword(e.target.value)) {
    } else {
      console.log("Please enter correct email address!");
    }
  };
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // const input = $("input#register_fristname");
    const error_username = $(".username_taken");
    // console.log("Received values of form: ", values);
    signup({
      email: values.email,
      password: values.password,
      firstName: values.firstname,
      lastName: values.lastname,
      telephone: values.phone,
    })
      .then((data) => {
        alert(`Create Account Succesfully!`);
        return navigate("/admin_laoscitec/login");
      })
      .catch((err) => {
        error_username.classList.add("error_username");
      });
  };

  return (
    <div className="background_login">
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{
          residence: ["VietNam", "City/Province", "District"],
          prefix: "84",
        }}
        scrollToFirstError
      >
        <div className="close">
          <Link to="/admin_laoscitec/login">
            <CloseOutlined />
          </Link>
        </div>
        <div className="create_form">
          <div className="create_title">
            <h1>Create Account</h1>
          </div>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (validatePassword(value)) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    Error(
                      "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"
                    )
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password onBlur={handelNotice} />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="firstname"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input your first name!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="username_taken">
            Email or phone number already exists !
          </div>
          <Form.Item
            name="lastname"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please input your last name!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Create Account
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default NewAccount;
