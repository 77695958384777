import style from "./about-us.module.scss";
import classNames from "classnames/bind";
import "react-quill/dist/quill.snow.css";
import { Loading } from "@components/Loading";

import "./about.replace.scss";
import { getAboutUs } from "@apiUrl/stacticContent";
import { useEffect, useState } from "react";
import { parserFromAPI } from "@utils/functions";
const cx = classNames.bind(style);

export const AboutUs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAboutUs()
      .then((res) => res.data.data)
      .then((data) => {
        if (data?.length > 0) {
          setData(data);
        }
      });
  }, []);

  return data?.length ? (
    <div className={cx("wrapper", "wrapper_replace")}>
      <div className={cx("container", "container_replace")}>
        <div className={cx("row")}>
          <div
            dangerouslySetInnerHTML={{
              __html: parserFromAPI(data[0]?.label).body.innerHTML,
            }}
            className={cx(
              "main_title custom-family-news",
              "d-flex justify-content-center"
            )}
          ></div>

          <div
            dangerouslySetInnerHTML={{
              __html: parserFromAPI(data[0]?.content).body.innerHTML,
            }}
            className={cx("subcription", "sub_replace")}
          ></div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};
