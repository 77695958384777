import axios from "axios";
export const BASE_URL = "https://laoscitec.api.minastik.com";
const request = (requestObject) => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  if (accessToken)
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  axios.defaults.baseURL = "https://laoscitec.api.minastik.com";
  return axios.request(requestObject);
};

export const getHome = async (id = "home", language) => {
  const lan = sessionStorage.getItem("LANGUAGE");
  return request({
    url: `/api/blog/type/${id}`,
    method: "GET",
    params: { language: (language = lan) },
  });
};
export const getHome_E = async (id = "home", language) => {
  // const lan = sessionStorage.getItem("LANGUAGE");
  return request({
    url: `/api/blog/type/${id}`,
    method: "GET",
    params: { language: (language = "en") },
  });
};
export const getHome_L = async (id = "home", language) => {
  // const lan = sessionStorage.getItem("LANGUAGE");
  return request({
    url: `/api/blog/type/${id}`,
    method: "GET",
    params: { language: (language = "laos") },
  });
};
export const updateHome = async (id, data, language) => {
  return request({
    url: `/api/blog/edit/${id}`,
    method: "PUT",
    data,
  });
};

export const getAboutUs = async (id = "about", language) => {
  const lan = sessionStorage.getItem("LANGUAGE");
  const data = await request({
    url: `/api/blog/type/${id}`,
    method: "GET",
    params: { language: (language = lan) },
  });
  return data;
};

export const getAboutUs_E = async (id = "about", language) => {
  const data = await request({
    url: `/api/blog/type/${id}`,
    method: "GET",
    params: { language: (language = "en") },
  });
  return data;
};

export const getAboutUs_L = async (id = "about", language) => {
  const data = await request({
    url: `/api/blog/type/${id}`,
    method: "GET",
    params: { language: (language = "laos") },
  });
  return data;
};
export const updateAboutUs = async (id, data, language) => {
  // const lan = sessionStorage.getItem("LANGUAGE");
  return request({
    url: `/api/blog/edit/${id}`,
    method: "PUT",
    data,
    // params: { language: (language = lan) }
  });
};