import classNames from "classnames/bind";
import "./antd.scss";
import style from "./news.module.scss";

import { Pagination } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { getNews_list, BASE_URL, getNewsByGroupId } from "@apiUrl/news";
import calendar from "@assets/icons/calendar.svg";

import { NotData } from "../../../components/NotData";
import { Loading } from "@components/Loading";
import { convertDataToDateString, parserFromAPI } from "@utils/functions.js";

const cx = classNames.bind(style);
const lan = sessionStorage.getItem("LANGUAGE");

const NavItem = ({ data, image, date, title }) => {
  const handleSendData = (id, groupID) => {
    sessionStorage.setItem("newsDetailGroupId", groupID);

    getNewsByGroupId(groupID)
      .then((res) => res.data.data)
      .then((data) => {
        data.forEach((item, index) => {
          if (item._id !== id) {
            if (lan === "laos") {
              sessionStorage.setItem("titleNewsDetailOther", title);
              sessionStorage.setItem("titleNewsDetail", item.label);
            } else {
              sessionStorage.setItem("titleNewsDetail", title);
              sessionStorage.setItem("titleNewsDetailOther", item.label);
            }
          }
        });
      });
  };
  return (
    <div className={cx("new_item", "col-lg-4")}>
      <Link
        onClick={() => handleSendData(data._id, data.groupId)}
        to={{
          pathname: `/news/${data.label.replace(/ /g, "_")}`,
        }}
      >
        <img
          alt={" "}
          src={`${BASE_URL}${image}`}
          className={cx("new_item--image")}
        />
        <div className={cx("date", "d-flex align-items-center")}>
          <img src={calendar} alt={" "} />
          <p>{date}</p>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: parserFromAPI(title).body.innerHTML,
          }}
          className={cx("title-item")}
        ></div>
      </Link>
    </div>
  );
};

export const News = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [components, setComponents] = useState(<Loading />);

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setComponents(<NotData />);
    }, 10000);
    setComponents(<Loading />);
    getNews_list(page)
      .then((res) => res.data)
      .then((res) => {
        setTotal(res.Paging.totalCount);
        // console.log(res)
        return res.data;
      })
      .then((data) => {
        setData(data);
      });
    // console.log(data)
  }, [page]);

  return data?.length ? (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <div className={cx("row")}>
          <div className={cx("main_title", "d-flex justify-content-center")}>
            <p className={cx("custom-family")}>{t("newsList.dailyNews")}</p>
          </div>
        </div>

        <div className={cx("news-list-items", "row")}>
          {data?.map((post, index) => (
            <NavItem
              key={index}
              data={post}
              image={post.thumbnail}
              date={convertDataToDateString(post.createdAt)}
              title={post.label}
            />
          ))}
        </div>

        <div className={cx("page-controller")}>
          <Pagination
            total={total}
            pageSize={9}
            current={page}
            onChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    components
  );
};
