import AdminContainer from "../components/Admin/Main_Layout/AdminContainer";
export const adminRoutes = [
  {
    title: "Admin",
    path: "/admin_laoscitec/*",
    component: AdminContainer,
    subItems: [],
    privateRoute: false,
    adminRoute: true,
  },
];
