import classNames from "classnames/bind";
import style from "./main-category_education.module.scss";
import { Link } from "react-router-dom";

import chemicals from "@assets/images/chemicals.png";
import iStock from "@assets/images/LST_Lab/iStock-1284341981-scaled.jpg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getCategory, getCategoryById } from "../../../api-url/category";
import {
  getChildren,
  formatData,
  formatTreeData,
} from "../../../utils/functions";
import { Loading } from "../../../components/Loading";
import { NotData } from "../../../components/NotData";
const cx = classNames.bind(style);

export const CategoryEducation = () => {
  const { t } = useTranslation();
  const [menuArr, setMenuArr] = useState([]);
  const [components, setComponents] = useState(<Loading />);

  useEffect(() => {
    getCategory()
      .then((res) => res.data)
      .then((data) => data.data)
      .then(async (dataInput) => {
        await getChildren(dataInput, formatTreeData, getCategoryById);
        // console.log(dataInput);
        setMenuArr(dataInput);
      })

      .catch((err) => console.log(err));
  }, []);
  setTimeout(() => {
    setComponents(<NotData />);
  }, 10000);
  return menuArr.length ? (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <div className={cx("main_title", "d-flex justify-content-center")}>
          <p>{t("categoryList_education.education")}</p>
        </div>

        <div className={cx("row")}>
          <div className={cx("col-lg-6")}>
            <div className={cx("wrapper-item")}>
              <Link
                to="/education/speacial_vehicle"
                state={{
                  foo: JSON.stringify(menuArr[1]),
                }}
                className={cx("category-item")}
              >
                <img alt="" src={iStock} />
                <div
                  className={cx(
                    "box-function",
                    "d-flex justify-content-center align-items-center"
                  )}
                >
                  <p>{t("categoryList_education.speacial_vehicle")}</p>
                </div>
              </Link>
            </div>
          </div>

          <div className={cx("col-lg-6")}>
            <div className={cx("wrapper-item")}>
              <Link
                to={{
                  pathname: "/education/modernization",
                  // state: JSON.stringify(menuArr[0]?.children[1]),
                }}
                state={{
                  foo: JSON.stringify(menuArr[1]),
                }}
                className={cx("category-item")}
              >
                <img alt="" src={chemicals} />
                <div
                  className={cx(
                    "box-function",
                    "d-flex justify-content-center align-items-center"
                  )}
                >
                  <p>{t("categoryList_education.modernization")}</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    components
  );
};
