import style from "./News.module.scss";
import classNames from "classnames/bind";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.replace.scss";

import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table, Popconfirm, Typography, notification } from "antd";
import { Button, Modal, Pagination } from "antd";

import pen from "@assets/icons/icon_categories/pen.png";

import {
  parserFromAPI,
  convertDataToDateString,
  handlePaste,
  checkTypeImage,
} from "../../../utils/functions";

import {
  getNews_list,
  postNews_list,
  deleteNews_list,
  updateNews_list,
  getNewsByGroupId,
  BASE_URL,
} from "@apiUrl/news";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrashCan,
  faCircleXmark,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { faCalendarMinus } from "@fortawesome/free-regular-svg-icons";
import { Loading } from "../../Loading";
import { NotData } from "../../NotData";
import axios from "axios";
const cx = classNames.bind(style);
const $ = document.querySelector.bind(document);

const lan = "en";

export const convertFromApi = (data) => {
  return [].concat.apply(
    [],
    data.map((item, index) => [
      {
        key: `${item._id}`,
        _id: `${item._id}`,
        No: `${index + 1}`,
        Title: `${parserFromAPI(item.label).documentElement.textContent} `,
        Date: `${convertDataToDateString(item.createdAt)}`,
        Content: `${parserFromAPI(item.content).documentElement.textContent}`,
        thumbnail: `${item.thumbnail}`,
        languageId: lan === "laos" ? 2 : 1,
        groupId: `${item.groupId}`,
      },
    ])
  );
};

export const convertFromPage = (data) => {
  return [].concat.apply(
    [],
    data.map((item, index) => [
      {
        label: `${item.Title}`,
        unsignedLabel: `${item.Title}`,
        content: `${item.Content}`,
        languageId: lan === "laos" ? 2 : 1,
        type: "news",
        thumbnail: `${item.thumbnail}`,
        groupId: `${item.groupId}`,
      },
    ])
  );
};

export const News = () => {
  const { t } = useTranslation();

  const [titleEng, setTitleEng] = useState("");
  const [titleEngTemp, setTitleEngTemp] = useState("");
  const [contentEng, setContentEng] = useState("");

  const [titleLaos, setTitleLaos] = useState("");
  const [titleLaosTemp, setTitleLaosTemp] = useState("");
  const [contentLaos, setContentLaos] = useState("");

  const [fileList, setFileList] = useState([]);
  const [url, setUrl] = useState("");
  const [_ID, set_ID] = useState("");
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);
  const [edittingRow, setEdittingRow] = useState(null);
  const [viewRow, setViewRow] = useState(null);
  const [keyEdit, setkeyEdit] = useState("");
  const [titleTest, setTitleTest] = useState("");
  const [contentView, setContentView] = useState("");
  const [components, setComponents] = useState(<Loading />);
  const [isLoading, setIsLoading] = useState(true);

  const quillContentE = useRef();
  const quillContentL = useRef();

  /// MOdal
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setComponents(<Loading />);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data.length <= 0 && !isLoading) {
      setComponents(<NotData />);
    }
  }, [data]);

  useEffect(() => {
    const noticeError = $(".noticeOffEng");
    const noticeSumbError = $(".noticeSumbitFail");
    setIsLoading(true);
    getNews_list(page)
      .then((res) => res.data)
      .then((data) => {
        setTotal(data.Paging.totalCount);
        return data.data;
      })
      .then((data) => convertFromApi(data))
      .then((data) => {
        setIsLoading(false);
        setData(data);
      })
      .then(() => {
        noticeError?.classList.remove("noticeOnEng");
        noticeSumbError?.classList.remove("noticeFailOn");
      });
  }, [page]);

  const checkTitleEng = () => {
    // const noticeError = $(".noticeOffEng");
    // if (
    //   parserFromAPI(titleEng).documentElement.textContent ===
    //   parserFromAPI(titleEngTemp).documentElement.textContent
    // ) {
    //   noticeError?.classList.add("noticeOnEng");
    //   return false;
    // } else {
    //   noticeError?.classList.remove("noticeOnEng");
    //   return true;
    // }
  };

  const checkTitleLaos = () => {
    const noticeError = $(".noticeOffLaos");
    if (
      parserFromAPI(titleLaos).documentElement.textContent ===
      parserFromAPI(titleLaosTemp).documentElement.textContent
    ) {
      noticeError?.classList.add("noticeOnLaos");
      return false;
    } else {
      noticeError?.classList.remove("noticeOnLaos");
      return true;
    }
  };

  const checkIsValid = () => {
    if (
      parserFromAPI(titleEng).documentElement.textContent.length &&
      parserFromAPI(titleLaos).documentElement.textContent.length &&
      parserFromAPI(contentEng).documentElement.textContent.length &&
      parserFromAPI(contentLaos).documentElement.textContent.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleChangeContentE = (e) => {
    const target = document.getElementById("contentE");
    handlePaste(target);
    setContentEng(e);
  };

  const handleChangeContentL = (e) => {
    const target = document.getElementById("contentL");
    handlePaste(target);
    setContentLaos(e);
  };

  const handleFileInputChange = async (e) => {
    const image = new FormData();
    image.append("images", e.target.files[0]);
    const fileExtension = e.target.files[0].type.slice(6);

    if (checkTypeImage(fileExtension)) {
      axios
        .post("https://laoscitec.api.minastik.com/api/upload-image", image)
        .then((res) => res.data.data)
        .then((data) => {
          setUrl(data[0].lgUrl);
        });
    } else {
      notification["error"]({
        message: `${t("admin_news.notice.updateFail")}`,
        description: `${t("admin_news.notice.descriptionFailUploadImage")}`,
        style: {
          color: "rgba(0, 0, 0, 0.65)",
          border: "1px solid #ffa39e",
          backgroundColor: "#fff1f0",
          borderRadius: "5px",
        },
      });
    }
  };

  const imgHandler = (e) => {
    // handlers object will be merged with default handlers object
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  const saveToServer = async (file) => {
    const image = new FormData();
    image.append("images", file);
    const fileExtension = file.type.slice(6);

    if (checkTypeImage(fileExtension)) {
      await axios
        .post("https://laoscitec.api.minastik.com/api/upload-image", image)
        .then((res) => res.data.data)
        .then((data) => {
          insertToEditor(data[0].lgUrl);
        });
    } else {
      notification["error"]({
        message: `${t("admin_news.notice.updateFail")}`,
        description: `${t("admin_news.notice.descriptionFailUploadImage")}`,
        style: {
          color: "rgba(0, 0, 0, 0.65)",
          border: "1px solid #ffa39e",
          backgroundColor: "#fff1f0",
          borderRadius: "5px",
        },
      });
    }
  };

  const insertToEditor = (url) => {
    const ContentEnglish = quillContentE.current.getEditor();
    const Contentlaos = quillContentL.current.getEditor();
    if (ContentEnglish.hasFocus()) {
      const range = ContentEnglish.getSelection();
      ContentEnglish.insertEmbed(range.index, "image", `${BASE_URL}${url}`);
    } else if (Contentlaos.hasFocus()) {
      const range = Contentlaos.getSelection();
      Contentlaos.insertEmbed(range.index, "image", `${BASE_URL}${url}`);
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, true] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons

          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

          [{ image: "image" }],
          [{ link: "link" }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
        ],
        handlers: {
          image: imgHandler,
        },
      },
    }),
    []
  );

  const handleOk = (e) => {
    const fillOut = $(".noticeFillOutOff");
    if (checkIsValid()) {
      fillOut.classList.remove("noticeFillOutOn");
      const ID = Math.floor(Math.random() * 1000000 + 1);
      postNews_list({
        label: titleEng,
        unsignedLabel: titleEng,
        content: contentEng,
        languageId: 1,
        type: "news",
        thumbnail: url,
        groupId: ID,
      })
        .then(() => {
          postNews_list({
            label: titleLaos,
            unsignedLabel: titleLaos,
            content: contentLaos,
            languageId: 2,
            type: "news",
            thumbnail: url,
            groupId: ID,
          })
            .then(() => {
              notification["success"]({
                message: `${t("admin_news.notice.success")}`,
                description: `${t("admin_news.notice.descriptionsSuc")}`,
                style: {
                  color: "rgba(0, 0, 0, 0.65)",
                  border: "1px solid #b7eb8f",
                  backgroundColor: "#f6ffed",
                  borderRadius: "5px",
                },
              });
            })
            .then(() => {
              getNews_list()
                .then((res) => res.data.data)
                .then((data) => convertFromApi(data))
                .then((data) => setData(data))
                .then(() => {
                  setTitleEng("");
                  setTitleEngTemp("");
                  setContentEng("");
                  setTitleLaos("");
                  setContentLaos("");
                  setSelectedNode(null);
                  setIsModalVisible(!checkIsValid());
                  // setFileList([]);
                  setUrl("");
                });
            });
        })
        .catch((err) => {
          notification["error"]({
            message: `${t("admin_news.notice.updateFail")}`,
            description: err.response.data.errors[0].message,
            style: {
              color: "rgba(0, 0, 0, 0.65)",
              border: "1px solid #ffa39e",
              backgroundColor: "#fff1f0",
              borderRadius: "5px",
            },
          });
          console.log(err);
        });
    } else {
      // console.log("Somethings");
      fillOut.classList.add("noticeFillOutOn");
    }
  };

  const handleCancel = () => {
    // setFileList([]);
    setUrl("");
    setIsModalVisible(false);
    setTitleEng("");
    setContentEng("");
    setTitleLaos("");
    setContentLaos("");
    setEdittingRow(null);
    setSelectedNode(null);
    setViewRow(null);
  };
  //
  //select Tree
  const [selectedNode, setSelectedNode] = useState(null);

  const cancel = () => {};

  const Delete = (key) => {
    const index = data.findIndex((item) => key === item.key);
    if (index > -1) {
      // console.log(data);
      const groupId = data[index].groupId;
      const id = data[index]._id;

      deleteNews_list(key).then(() => {
        getNewsByGroupId(groupId)
          .then((res) => res.data.data)
          .then((data) => {
            return data[0]._id;
          })
          .then((id) => deleteNews_list(id))
          .then(() => {
            getNews_list()
              .then((res) => res.data.data)
              .then((data) => convertFromApi(data))
              .then((data) => setData(data));
            // .then(() => console.log("Success to delete"));
          })
          .then(() => {
            notification["success"]({
              message: `${t("admin_news.notice.successDelete")}`,
              description: `${t("admin_news.notice.descriptionsSuc")}`,
              style: {
                color: "rgba(0, 0, 0, 0.65)",
                border: "1px solid #b7eb8f",
                backgroundColor: "#f6ffed",
                borderRadius: "5px",
              },
            });
          })
          .catch((err) => console.log(err));
      });
    }
  };

  const save = () => {
    const noticeSumbError = $(".noticeSumbitFail");
    const noticeError = $(".noticeOffEng");

    const newData = [...data];
    // console.log(newData)
    const index = newData.findIndex((item) => keyEdit.key === item.key);
    const updateData = (newData[index] = {
      _id: newData[index]._id,
      key: newData[index].key,
      Date: newData[index].Date,
      No: newData[index].No + 1,
      Title: `${lan === "en" ? titleEng : titleLaos}`,
      Content: `${lan === "en" ? contentEng : contentLaos}`,
      Select_value: selectedNode?.value,
      thumbnail: url,
      languageId: newData[index].languageId,
      groupId: newData[index].groupId,
    });
    // console.log(updateData)
    newData[index] = updateData;
    // console.log(updateData._id);
    if (checkIsValid) {
      updateNews_list(updateData._id, {
        label: updateData.Title,
        unsignedLabel: updateData.Title,
        content: updateData.Content,
        languageId: lan === "en" ? 1 : 2,
        type: "news",
        thumbnail: updateData.thumbnail,
      })
        .then(() => {
          // console.log(updateData.groupId);
          getNewsByGroupId(updateData.groupId)
            .then((res) => res.data.data)
            .then((data) => {
              data.forEach((item, index) => {
                if (item._id !== updateData._id) {
                  set_ID(item._id);
                }
              });
            });
        })
        .then(() => {
          // console.log(_ID);
          updateNews_list(_ID, {
            label: `${lan === "en" ? titleLaos : titleEng}`,
            unsignedLabel: `${lan === "en" ? titleLaos : titleEng}`,
            content: `${lan === "en" ? contentLaos : contentEng}`,
            languageId: lan === "en" ? 2 : 1,
            type: "news",
            thumbnail: updateData.thumbnail,
          }).then(() => {
            console.log("Oke");
          });
        })
        .then(() => {
          getNews_list()
            .then((res) => res.data.data)
            .then((data) => convertFromApi(data))
            .then((data) => setData(data))
            .then(() => console.log("Success to update"));
        })
        .then(() => {
          // console.log("Oke");
          notification["success"]({
            message: `${t("admin_news.notice.success")}`,
            description: `${t("admin_news.notice.descriptionsSuc")}`,
            style: {
              color: "rgba(0, 0, 0, 0.65)",
              border: "1px solid #b7eb8f",
              backgroundColor: "#f6ffed",
              borderRadius: "5px",
            },
          });
        })
        .then(() => {
          setTitleEng("");
          setTitleEngTemp("");
          setContentEng("");
          setTitleLaos("");
          setContentLaos("");
          setSelectedNode(null);
          setIsModalVisible(!checkIsValid());
          // setFileList([]);
          setUrl("");
        })
        .catch((error) => {
          noticeError?.classList.add("noticeOnEng");
          noticeSumbError?.classList.add("noticeFailOn");

          notification["error"]({
            message: `${t("admin_news.notice.updateFail")}`,
            description: error.response.data.errors[0].message,
            style: {
              color: "rgba(0, 0, 0, 0.65)",
              border: "1px solid #ffa39e",
              backgroundColor: "#fff1f0",
              borderRadius: "5px",
            },
          });
        });
    } else {
      console.log("something");
    }

    // setIsModalVisible(checkTitleEng() && checkTitleLaos() && !checkIsValid());
  };

  const handleEditRow = (row) => {
    setkeyEdit(row);

    getNewsByGroupId(row.groupId)
      .then((res) => res.data.data)
      .then((data) => {
        const language = lan === "en" ? 1 : 2;
        data.forEach((item, index) => {
          if (item._id !== row._id) {
            language == 2 ? setTitleEng(item.label) : setTitleLaos(item.label);
            language == 2
              ? setTitleEngTemp(item.label)
              : setTitleLaosTemp(item.label);
            language == 2
              ? setContentEng(item.content)
              : setContentLaos(item.content);
          } else {
            language == 1 ? setTitleEng(item.label) : setTitleLaos(item.label);
            language == 1
              ? setTitleEngTemp(item.label)
              : setTitleLaosTemp(item.label);
            language == 1
              ? setContentEng(item.content)
              : setContentLaos(item.content);
          }
        });
      });
    // console.log(row);
    setEdittingRow(row);
    setViewRow(null);
    setSelectedNode({ value: row?.Select_value });
    showModal(true);
    setUrl(row?.thumbnail);
  };

  const handleViewRow = (row) => {
    // console.log(row);
    setViewRow(row);
    setEdittingRow(null);
    setTitleTest(row.Title);
    setContentView(row.Content);
    showModal(true);
    setUrl(row.thumbnail);
  };

  const columns = [
    {
      title: t("admin_news.colums.no"),
      dataIndex: "No",
      width: "5%",
      editable: true,
    },
    {
      title: t("admin_news.colums.title"),
      dataIndex: "Title",
      width: "15%",
      editable: true,
    },
    {
      title: t("Thumbnail"),
      dataIndex: "thumbnail",
      width: "10%",
      editable: true,
      render: (text) => {
        return (
          <>
            <div className="img_gallery">
              <img alt="" src={`${BASE_URL}${text}`} />
            </div>
          </>
        );
      },
    },
    {
      title: t("admin_news.colums.content"),
      dataIndex: "Content",
      width: "50%",
      editable: true,
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: t("admin_news.colums.date"),
      dataIndex: "Date",
      width: "10%",
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            <p>
              <FontAwesomeIcon icon={faCalendarMinus} />
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: t("admin_news.colums.action"),
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <div className={cx("operation_btn")}>
            <Typography.Link>
              <div onClick={() => handleViewRow(record)}>
                <FontAwesomeIcon icon={faEye} /> {t("admin_news.handle.view")}
              </div>
            </Typography.Link>
            <Typography.Link>
              <div onClick={() => handleEditRow(record)}>
                <img src={pen} alt="" /> {t("admin_news.handle.edit")}
              </div>
            </Typography.Link>
            <Popconfirm
              title="Do You Want to Delete?"
              onConfirm={() => Delete(record.key)}
              icon={<FontAwesomeIcon icon={faCircleXmark} />}
              okText="Delete"
              cancelText="Cancle"
            >
              <Typography.Link>
                <FontAwesomeIcon icon={faTrashCan} />{" "}
                {t("admin_news.handle.delete")}{" "}
              </Typography.Link>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleDeleteImage = () => {
    setUrl("");
  };

  return (
    <div className={cx("news_wrapper", "news_table")}>
      <div className={cx("modal_wrapper")}>
        <Button type="primary" onClick={showModal}>
          <FontAwesomeIcon icon={faPlus} />
          {t("admin_news.handle.titleAdd")}
        </Button>
      </div>

      <Modal
        wrapClassName="modal_news"
        title={
          viewRow
            ? titleTest
            : edittingRow
            ? t("admin_news.handle.titleEdit")
            : t("admin_news.handle.add")
        }
        visible={isModalVisible}
        onOk={edittingRow ? save : handleOk}
        onCancel={handleCancel}
        cancelText={t("admin_news.handle.cancel")}
        okText={
          edittingRow
            ? t("admin_news.handle.titleEdit")
            : t("admin_news.handle.addBt")
        }
        okButtonProps={
          viewRow
            ? { style: { display: "none" } }
            : { style: { display: "block" } }
        }
      >
        {viewRow ? (
          <div className="view_page">
            <img alt={" "} src={`${BASE_URL}${url}`} />
            <p>{contentView}</p>
          </div>
        ) : (
          <div className={cx("editNews")}>
            <div className={cx("row")}>
              <div className={cx("col-6")}>
                <h3>{t("admin_news.colums.titleEng")}</h3>
                <div className={cx("input")}>
                  <input
                    className={cx("titleInput")}
                    value={parserFromAPI(titleEng).documentElement.textContent}
                    onBlur={checkTitleEng}
                    onChange={(e) => {
                      setTitleEng(e.target.value);
                    }}
                  />
                  <div className="noticeOffEng">
                    {t("admin_news.notice.changeLabelEng")}
                  </div>
                  <div className="noticeFillOutOff">
                    {t("admin_news.notice.fillOut")}
                  </div>
                </div>
              </div>

              <div className={cx("col-6")}>
                <h3>{t("admin_news.colums.titleLaos")}</h3>
                <div className={cx("input")}>
                  <input
                    className={cx("titleInput")}
                    value={parserFromAPI(titleLaos).documentElement.textContent}
                    onBlur={checkTitleLaos}
                    onChange={(e) => {
                      setTitleLaos(e.target.value);
                    }}
                  />
                  <div className="noticeOffLaos">
                    {t("admin_news.notice.changeLabelLaos")}
                  </div>
                  <div className="noticeFillOutOff">
                    {t("admin_news.notice.fillOut")}
                  </div>
                </div>
              </div>
            </div>

            <br></br>

            <div className={cx("row")}>
              <div className={cx("col-6")}>
                <div className={cx("input")}>
                  <h3>{t("admin_news.colums.contentEng")}</h3>
                  <ReactQuill
                    ref={quillContentE}
                    theme="snow"
                    id="contentE"
                    modules={modules}
                    value={contentEng}
                    onChange={handleChangeContentE}
                  />
                  <div className="noticeFillOutOff">
                    {t("admin_news.notice.fillOut")}
                  </div>
                </div>
              </div>

              <div className={cx("col-6")}>
                <div className={cx("input")}>
                  <h3>{t("admin_news.colums.contentLaos")}</h3>
                  <ReactQuill
                    ref={quillContentL}
                    theme="snow"
                    id="contentL"
                    modules={modules}
                    value={contentLaos}
                    onChange={handleChangeContentL}
                  />
                  <div className="noticeFillOutOff">
                    {t("admin_news.notice.fillOut")}
                  </div>
                </div>
              </div>
            </div>

            <div className="noticeSumbitSuc">
              {t("admin_news.notice.success")}
            </div>
            <div className="noticeSumbitFail">
              {t("admin_news.notice.updateFail")}
            </div>
            <br></br>
            <h3>{t("admin_news.handle.upload")}</h3>
            <div className={cx("thumbnail_wrapper")}>
              <div className={cx("thumbnail_box")}>
                <div className={cx("thumbnail")}>
                  {url.length > 0 ? (
                    <img
                      src={`${BASE_URL}${url}`}
                      alt={" "}
                      className={cx("image")}
                    />
                  ) : (
                    <></>
                  )}
                  <div className={cx("after")}>
                    <button
                      onClick={handleDeleteImage}
                      className={cx("delete_image")}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {viewRow ? (
          <></>
        ) : (
          <input
            type={"file"}
            id="upload_image"
            accept={"image/*"}
            title={" "}
            style={{ color: "transparent" }}
            onChange={handleFileInputChange}
          />
        )}
      </Modal>

      {data.length ? (
        <>
          <Table
            bordered
            dataSource={data}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              position: ["none"],
            }}
          />

          <div className={cx("page-controller_News_admin")}>
            <Pagination
              total={total}
              pageSize={9}
              current={page}
              onChange={(page) => {
                setPage(page);
              }}
            />
          </div>
        </>
      ) : (
        components
      )}
    </div>
  );
};

export default News;
