import axios from "axios";
const request = (requestObject) => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  if (accessToken)
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  axios.defaults.baseURL = "https://laoscitec.api.minastik.com";
  return axios.request(requestObject);
};

export const getProduct_Admin = async (pageNumber = 1, language) => {
  const lan = sessionStorage.getItem("LANGUAGE");
  // if (pageNumber === 1 && count === 2) return null;
  // if (pageNumber === 1) {
  //   count++;
  // }
  return request({
    url: `/api/product`,
    method: "GET",
    params: { pageNumber: pageNumber, language: (language = lan) },
  });
};
export const getProductManager_Admin = async (
  pageNumber = 1,
  language,
  groupId
) => {
  const lan = sessionStorage.getItem("LANGUAGE");
  return request({
    url: `/api/product`,
    method: "GET",
    params: {
      pageNumber: pageNumber,
      language: (language = lan),
      groupId: groupId,
    },
  });
};
export const getGroupIdProduct = async (groupId) => {
  return request({
    url: `/api/product/group`,
    method: "GET",
    params: {
      groupId: groupId,
    },
  });
};
export const getProductManager_Admin_laos = async (
  pageNumber = 1,
  language,
  groupId
) => {
  // const lan = sessionStorage.getItem("LANGUAGE");
  return request({
    url: `/api/product`,
    method: "GET",
    params: {
      pageNumber: pageNumber,
      language: (language = "laos"),
      groupId: groupId,
    },
  });
};
export const getProductManager_Admin_en = async (
  pageNumber = 1,
  language,
  groupId
) => {
  // const lan = sessionStorage.getItem("LANGUAGE");
  return request({
    url: `/api/product`,
    method: "GET",
    params: {
      pageNumber: pageNumber,
      language: (language = "en"),
      groupId: groupId,
    },
  });
};
export const getProduct_search = async (name, language) => {
  const lan = sessionStorage.getItem("LANGUAGE");
  return request({
    url: `/api/product`,
    method: "GET",
    params: { name: name, language: (language = lan) },
  });
};

export const postProduct_Admin = async (data) => {
  return request({
    url: `/api/product`,
    method: "pOST",
    data,
  });
};

export const updateProduct_Admin = async (id, data) => {
  return request({
    url: `/api/product/edit/${id}`,
    method: "PUT",
    data,
  });
};
export const deleteProduct_Admin = async (id) => {
  return request({
    url: `/api/product/delete/${id}`,
    method: "DELETE",
  });
};

export const getLanguage_Admin = async () => {
  return request({
    url: "/api/language",
    method: "GET",
  });
};
