import axios from "axios";
const request = (requestObject) => {
  axios.defaults.baseURL = "https://laoscitec.api.minastik.com";
  return axios.request(requestObject);
};

export const getCommon = () => {
  return request({
    url: "/api/common",
    method: "GET",
  });
};

export const updateCommon = async (id, data) => {
  return request({
    url: `/api/common/${id}`,
    method: "PUT",
    data,
  });
};
