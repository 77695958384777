import classNames from "classnames/bind";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import style from "./loading.module.scss";

const cx = classNames.bind(style);

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
      color: "#606637",
    }}
    spin
  />
);

export const Loading = () => {
  return (
    <div className={cx("loading", "loading_replace")}>
      <div className={cx("container")}>
        <div className={cx("d-flex justify-content-center align-items-center")}>
          <Spin indicator={antIcon} size="large" />
        </div>
      </div>
    </div>
  );
};
