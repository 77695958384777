import Login from "@components/Login/Login/Login";
import { useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AdminLayout from "./admin-layout";
import NewAccount from "../../Login/NewAccount/NewAccount";
import ForgotPassword from "../../Login/ForgotPassword/ForgotPassword";

const AdminContainer = () => {
  const [isAuth, setAuth] = useState(false);
  return useRoutes([
    {
      path: `/login`,
      element: isAuth ? (
        <Navigate to="/admin_laoscitec" />
      ) : (
        <Login setAuth={setAuth} />
      ),
    },
    {
      path: "/",
      element: !isAuth ? (
        <Navigate to="/admin_laoscitec/login" />
      ) : (
        <AdminLayout setAuth={setAuth} />
      ),
    },
    {
      path: "/create_account",
      element: isAuth ? (
        <Navigate to="/admin_laoscitec/create_account" />
      ) : (
        <NewAccount setAuth={setAuth} />
      ),
    },
    {
      path: "/forgot_account",
      element: isAuth ? (
        <Navigate to="/admin_laoscitec/forgot_account" />
      ) : (
        <ForgotPassword setAuth={setAuth} />
      ),
    },
  ]);
};

export default AdminContainer;
