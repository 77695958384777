// import { useTranslation } from "react-i18next";
import style from "./Home.module.scss";
import classNames from "classnames/bind";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faCalendar } from "@fortawesome/free-solid-svg-icons";

import bgImgAbout from "../../../assets/images/bg_1590tkc.png";
import logoIKA from "../../../assets/images/LST_Lab/Logo_partner/IKA.jpeg";
import logoGilson from "../../../assets/images/LST_Lab/Logo_partner/Gilson.jpeg";
import logo3M from "../../../assets/images/LST_Lab/Logo_partner/3M_logo.png";
import logaAnsell from "../../../assets/images/LST_Lab/Logo_partner/ansell.png";
import logaBenchmark from "../../../assets/images/LST_Lab/Logo_partner/Benchmark_scientific.png";
import logaHana from "../../../assets/images/LST_Lab/Logo_partner/Hanna_instrument.gif";
import logaHettich from "../../../assets/images/LST_Lab/Logo_partner/Hettich_lab.jpeg";
import logaKirgen from "../../../assets/images/LST_Lab/Logo_partner/Kirgen.png";
import logaOptika from "../../../assets/images/LST_Lab/Logo_partner/OPTIKA_logo.jpeg";
import logaQiagen from "../../../assets/images/LST_Lab/Logo_partner/qiagen.png";
import logaSPL from "../../../assets/images/LST_Lab/Logo_partner/SPL_logo.png";
import emailLogo from "../../../assets/images/emailLogo.jpg";
import phoneLogo from "../../../assets/images/phoneLogo.jpg";
import locationLogo from "../../../assets/images/locationLogo.jpg";
import contactBack from "../../../assets/images/background_contact.jpg";

import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getHome } from "../../../api-url/stacticContent";
import { getCategory, getCategoryGroupId } from "../../../api-url/category";
import { useTranslation } from "react-i18next";
import { getNewsByGroupId } from "../../../api-url/news";
import { parserFromAPI, convertDataToDateString } from "@utils/functions";
import { useLocation } from "react-router-dom";

// API
import { getNews_list, BASE_URL } from "@apiUrl/news";
import { getCommon } from "../../../api-url/common/index";
import CarouselComponent from "@modules/shared/carousel/carousel";
const cx = classNames.bind(style);

export const Home = () => {
  const lan = sessionStorage.getItem("LANGUAGE");

  const [data, setData] = useState([]);
  const [arrayIcon, setArrayIcon] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productListOther, setProductListOther] = useState([]);

  const [phoneNumber_1, setPhoneNumber_1] = useState("");
  const [address, setAddress] = useState("");
  const [emailContact, setEmailContact] = useState("");

  const location = useLocation();

  useEffect(() => {
    getCommon()
      .then((res) => res.data)
      .then((data) => {
        setPhoneNumber_1(data.data[0].value);
        setAddress(data.data[1].value);
        setEmailContact(data.data[2].value);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, [location.key]);

  useEffect(() => {
    getNews_list()
      .then((res) => res.data.data)
      .then((data) => setData(data));
    getHome()
      .then((data) => data.data.data)
      .then((data) => {
        const newData = data[0].content;
        const arrayData = newData.split("*");

        setBannerHeading(data[0].label);
        setHomeThumb(data[0].thumbnail);
        return arrayData;
      })
      .then((data) => {
        return data.reduce((acc, item, id) => {
          const index = Math.floor(id / 3);

          if (acc[index]) {
            acc[index].amount = item;
          } else acc.push({ icon: item, id: id });
          if (id % 3 === 1) {
            acc[index].description = item;
          } else acc[index].amount = item;
          return acc;
        }, []);
      })
      .then((data) => {
        // console.log(data);
        setArrayIcon(data);
      });
  }, []);

  const newData = [];
  for (let i = 0; i < 5; i++) {
    newData.push(data[i]);
  }
  useEffect(() => {
    const isLaos = lan === "laos" ? true : false;
    // console.log("abc");
    getCategory(isLaos)
      .then((res) => res.data.data)
      .then((data) => {
        setProductList(data);
        return data;
        // sessionStorage.setItem("listCategory", data);
      })
      .then((data) => {
        data.forEach((item, index) => {
          getCategoryGroupId(item.groupId[0])
            .then((res) => res.data.data)
            .then((dataInput) => {
              dataInput.forEach((itemData) => {
                if (itemData._id !== item._id) {
                  setProductListOther((pre) => [...pre, itemData]);
                }
              });
            });
        });
      });
  }, []);
  const { t } = useTranslation();

  const [bannerHeading, setBannerHeading] = useState("");
  const [homeThumb, setHomeThumb] = useState("");
  const handSendData = (name, nameOther) => {
    if (lan === "laos") {
      sessionStorage.setItem("NameMainCategoryOther", name);
      sessionStorage.setItem("NameMainCategory", nameOther.name);
    } else {
      sessionStorage.setItem("NameMainCategoryOther", nameOther.name);
      sessionStorage.setItem("NameMainCategory", name);
    }
  };
  // console.log(newData);
  const handleSendDataNews = (name, groupID, id) => {
    sessionStorage.setItem("newsDetailGroupId", groupID);
    getNewsByGroupId(groupID)
      .then((res) => res.data.data)
      .then((data) => {
        data.forEach((item, index) => {
          if (item._id !== id) {
            if (lan === "laos") {
              sessionStorage.setItem("titleNewsDetailOther", name);
              sessionStorage.setItem("titleNewsDetail", item.label);
            } else {
              sessionStorage.setItem("titleNewsDetail", name);
              sessionStorage.setItem("titleNewsDetailOther", item.label);
            }
          }
        });
      });
  };
  // console.log("abccc", productListOther);
  return (
    <>
      <div className={cx("wrapper_home")}>
        <div className={cx("home_tank")}>
          {/* <div className={cx("home_infor_detail")}>
            <div className={cx("tittle_infor")}>
              <h2 className={cx("custom_font_family")}>{bannerHeading}</h2>
            </div>
            <div className={cx("wrapper_table")}>
              <div className={cx("table_infor")}>
                {arrayIcon.map((data, index) => {
                  return (
                    <div className={cx("infor_detail")} key={index}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: parserFromAPI(data.icon + data.description)
                            .body.innerHTML,
                        }}
                      ></div>
                      <span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: parserFromAPI(data.amount).body.innerHTML,
                          }}
                        ></div>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className={cx("home_btn")}>
                <p>{t("home.go")}</p>
              </div>
            </div>
          </div>

          <div className={cx("home_img")}>
            <img
              src={`https://laoscitec.api.minastik.com${homeThumb}`}
              alt=""
            ></img>
          </div> */}
          <CarouselComponent></CarouselComponent>
        </div>
        <div className={cx("home_product")}>
          <div className={cx("homeproduct_header")}>
            <p className={cx("custom_font_family")}>
              {t("home.productList.product")}
            </p>
          </div>
          <div className={cx("homeproduct_selected")}>
            {productList.map((items, index) => {
              return (
                <div key={index} className={cx("homeproduct_detail")}>
                  <Link
                    onClick={() =>
                      handSendData(items?.name, productListOther[index])
                    }
                    to={`/category/${items.name.toLowerCase()}`}
                  >
                    <div
                      className={cx("background_img", "laboratory")}
                      style={{
                        backgroundImage: `url("https://laoscitec.api.minastik.com${items.images[0]}")`,
                      }}
                    >
                      <div className={cx("homeproduct_title")}>
                        <p>{items.name}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className={cx("home_about")}>
          <img src={bgImgAbout} alt="" className={cx("home_about_bg")} />

          <div className={cx("homeabout_title_wrapp")}>
            <div className={cx("homeabout_title")}>
              {t(`home.about.textAbout`)}
            </div>
          </div>

          <div className={cx("homeabout_btn_wrapp")}>
            <Link to="/about">
              <div className={cx("homeHover")}>
                <div className={cx("homeabout_btn")}>
                  {t("home.about.button")}
                  <FontAwesomeIcon
                    className={cx("icon_about")}
                    icon={faArrowUp}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className={cx("home_daily_new")}>
          <div className={cx("home_daily_header")}>
            {t("home.dailyNew.title")}
          </div>
          <div className={cx("home_daily_content")}>
            <Link
              onClick={() =>
                handleSendDataNews(
                  newData[0]?.label,
                  newData[0]?.groupId,
                  newData[0]?._id
                )
              }
              to={{
                pathname: `/news/${newData[0]?.label.replace(/ /g, "_")}`,
              }}
              className={cx("home_daily_mainnews")}
            >
              <div className={cx("mainnews_infor")}>
                <div className={cx("mainnews_content")}>
                  <div className={cx("mainnews_date")}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>{convertDataToDateString(newData[0]?.createdAt)}</p>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parserFromAPI(newData[0]?.label).body.innerHTML,
                    }}
                    className={cx("mainnews_title")}
                  ></div>
                </div>
                <img
                  className={cx("img_mainnews")}
                  src={`${BASE_URL}${newData[0]?.thumbnail}`}
                  alt=" "
                />
                <div className={cx("after")}></div>
              </div>
            </Link>

            <div className={cx("home_daily_subnews")}>
              <Link
                onClick={() =>
                  handleSendDataNews(
                    newData[1]?.label,
                    newData[1]?.groupId,
                    newData[1]?._id
                  )
                }
                to={{
                  pathname: `/news/${newData[1]?.label.replace(/ /g, "_")}`,
                }}
                className={cx("subnews_wrapper")}
              >
                <div className={cx("subnews_title")}>
                  <div className={cx("subnews_date")}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>{convertDataToDateString(newData[1]?.createdAt)}</p>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parserFromAPI(newData[1]?.label).body.innerHTML,
                    }}
                    className={cx("subnews_header")}
                  ></div>
                </div>
                <div className={cx("subnews_img")}>
                  <img src={`${BASE_URL}${newData[1]?.thumbnail}`} alt="" />
                </div>
              </Link>

              <Link
                onClick={() =>
                  handleSendDataNews(
                    newData[2]?.label,
                    newData[2]?.groupId,
                    newData[2]?._id
                  )
                }
                to={{
                  pathname: `/news/${newData[2]?.label.replace(/ /g, "_")}`,
                }}
                className={cx("subnews_wrapper")}
              >
                <div className={cx("subnews_title")}>
                  <div className={cx("subnews_date")}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>{convertDataToDateString(newData[2]?.createdAt)}</p>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parserFromAPI(newData[2]?.label).body.innerHTML,
                    }}
                    className={cx("subnews_header")}
                  ></div>
                </div>
                <div className={cx("subnews_img")}>
                  <img src={`${BASE_URL}${newData[2]?.thumbnail}`} alt="" />
                </div>
              </Link>

              <Link
                onClick={() =>
                  handleSendDataNews(
                    newData[3]?.label.replace(/ /g, "_"),
                    newData[3]?.groupId,
                    newData[3]?._id
                  )
                }
                to={{
                  pathname: `/news/${newData[3]?.label}`,
                }}
                className={cx("subnews_wrapper")}
              >
                <div className={cx("subnews_title")}>
                  <div className={cx("subnews_date")}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>{convertDataToDateString(newData[3]?.createdAt)}</p>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parserFromAPI(newData[3]?.label).body.innerHTML,
                    }}
                    className={cx("subnews_header")}
                  ></div>
                </div>
                <div className={cx("subnews_img")}>
                  <img src={`${BASE_URL}${newData[3]?.thumbnail}`} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* <div className={cx("home_partners")}>
          <div className={cx("home_partners_header")}>{t("home.partners")}</div>
          <div className={cx("home_partners_content")}>
            <div className={cx("logo_partners", "row")}>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logoIKA} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logoGilson} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logo3M} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logaAnsell} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logaHettich} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logaKirgen} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logaBenchmark} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logaHana} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logaSPL} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logaOptika} alt="" />
              </div>
              <div
                className={cx("col-lg-2 col-md-3 col-4", "logo_partners_img")}
              >
                <img src={logaQiagen} alt="" />
              </div>
            </div>
          </div>
        </div> */}
        <div id="contactUs" className={cx("home_contactUs")}>
          {/* <img
            className={cx("home_contact_img")}
            src={contactBack}
            alt="backgoung"
          /> */}
          <div className={cx("header_contact")}>{t(`home.contact.label`)}</div>
          <div className={cx("content_contact")}>
            <div className={cx("box")}>
              <div className={cx("content_box")}>
                <div className={cx("img_box_content")}>
                  <img src={emailLogo} alt="emailLogo" />
                </div>
                <div className={cx("header-wrap")}>
                  <div className={cx("header_content_box")}>
                    {t(`home.contact.email`)}:{" "}
                  </div>
                  <div className={cx("text_content_box")}>{emailContact}</div>
                </div>
              </div>
              <div className={cx("content_box")}>
                <div className={cx("img_box_content")}>
                  <img src={phoneLogo} alt="emailLogo" />
                </div>
                <div className={cx("header-wrap")}>
                  <div className={cx("header_content_box")}>
                    {t(`home.contact.phone`)}:{" "}
                  </div>
                  <div className={cx("text_content_box")}>{phoneNumber_1}</div>
                </div>
              </div>
              <div className={cx("content_box")}>
                <div className={cx("img_box_content")}>
                  <img src={locationLogo} alt="emailLogo" />
                </div>
                <div className={cx("header-wrap")}>
                  <div className={cx("header_content_box")}>
                    {t(`home.contact.address`)}:{" "}
                  </div>
                  <div className={cx("text_content_box")}>{address}</div>
                </div>
              </div>
            </div>
            <div className={cx("box-right")}>
              <div className={cx("box-right-img")}>
                <img src={contactBack} alt="backgoung" />
              </div>
              <div className={cx("text-box-right")}>
                <span>{t(`home.contact.get`)}</span> {t(`home.contact.us`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
