import style from "./Categories.module.scss";
import classNames from "classnames/bind";
import "./index.replace.scss";
import React, { useEffect, useState } from "react";

import { Table, Popconfirm, Typography, notification } from "antd";
import { Button, Modal } from "antd";
import { TreeSelect } from "antd";

import pen from "@assets/icons/icon_categories/pen.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrashCan,
  faCircleXmark,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarMinus } from "@fortawesome/free-regular-svg-icons";
import {
  getCategory,
  postCategory,
  updateCategory,
  getCategoryById,
  deleteCategory,
  BASE_URL,
  getCategoryLaos,
  getCategoryGroupId,
} from "../../../api-url/category/index";
import {
  getChildren,
  formatData,
  formatTreeData,
  convertDataToDateString,
  getChildrenSelectTree,
} from "../../../utils/functions";
import axios from "axios";
import { Loading } from "../../Loading";
import { NotData } from "../../NotData";
import { useTranslation } from "react-i18next";

const $ = document.querySelector.bind(document);

const cx = classNames.bind(style);
export const Categories = () => {
  const { t } = useTranslation();

  const [nameAdd, setNameAdd] = useState("");
  const [numberAdd, setNumberAdd] = useState("");
  const [data, setData] = useState([]);
  const [edittingRow, setEdittingRow] = useState(null);
  const [keyEdit, setkeyEdit] = useState("");
  const [url, setUrl] = useState("");
  /// MOdal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [components, setComponents] = useState(<Loading />);
  //select Tree
  const [selectedNode, setSelectedNode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      setComponents(<Loading />);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data.length <= 0 && !isLoading) {
      setComponents(<NotData />);
    }
  }, [data]);

  useEffect(() => {
    getCategory()
      .then((res) => res.data)
      .then((data) => data.data)
      .then(async (dataInput) => {
        getCategory()
          .then((res) => res.data.data)
          .then(async (dataNew) => {
            await getChildrenSelectTree(
              dataNew,
              formatTreeData,
              getCategoryById
            );
            setTreeData(dataNew);
          });

        await getChildren(dataInput, formatTreeData, getCategoryById);

        const data = formatData(dataInput);
        // console.log(dataInput);
        return data.map((dataChild) => {
          const newData = data.find((data) => dataChild.parent === data._id);

          return {
            key: dataChild?._id,
            Name: dataChild?.name,
            Date: `${convertDataToDateString(dataChild?.createdAt)}`,
            Number_add: dataChild?.title,
            Select_value: dataChild?._id,
            Sub_Categories: newData?.name,
            images: dataChild?.images,
            groupId: dataChild.groupId[0],
            languageId: dataChild.languageId,
          };
        });
      })
      .then((dataE) => {
        getCategoryLaos()
          .then((data) => data.data.data)
          .then(async (dataL) => {
            await getChildren(dataL, formatTreeData, getCategoryById);
            const dataTransformer = [...dataE];
            const dataArr = [];
            // console.log(dataL);
            const newDataLaos = formatData(dataL);
            return newDataLaos.map((dataChild) => {
              return {
                key: dataChild._id,
                Name: dataChild.name,
                Date: `${convertDataToDateString(dataChild.createdAt)}`,
                Number_add: dataChild.name,
                Select_value: dataChild._id,
                Sub_Categories: dataChild.name,
                images: dataChild?.images,
                groupId: dataChild.groupId[0],
                languageId: dataChild.languageId,
              };
            });
            // newDataLaos.map((dataData) => {
            //   const newData = dataTransformer.filter(
            //     (data) => data.groupId === dataData.groupId
            //   );

            //   dataTransformer.splice(newData, 1);
            // });
            // dataArr.push(...newDataLaos, ...dataTransformer);

            // const dataArrLaos = dataArr.filter(
            //   (items) => items.languageId === 2
            // );

            // const dataArrE = dataE.filter((items) => items.languageId === 1);

            // return dataArrE.map((item) => {
            //   const dataApi = dataArrLaos.map((itemLaos) => {
            //     if (item.groupId === itemLaos.groupId) {
            //       return {
            //         key: item.key,
            //         Name: item.Name,
            //         Date: item.Date,
            //         Number_add: itemLaos.Name,
            //         Select_value: item.Select_value,
            //         Sub_Categories: item?.Sub_Categories,
            //         images: item?.images,
            //         groupId: item.groupId,
            //         languageId: item.languageId,
            //       };
            //     } else return null;
            //   });
            //   return [...dataApi];
            // });
          })
          .then((data) => {
            const dataArrE = dataE.filter((items) => items.languageId === 1);

            return dataArrE.map((item) => {
              const dataApi = data.map((itemLaos) => {
                if (item.groupId === itemLaos.groupId) {
                  return {
                    key: item.key,
                    Name: item.Name,
                    Date: item.Date,
                    Number_add: itemLaos.Name,
                    Select_value: item.Select_value,
                    Sub_Categories: item?.Sub_Categories,
                    images: item?.images,
                    groupId: item.groupId,
                    languageId: item.languageId,
                  };
                } else return null;
              });
              return [...dataApi];
            });
          })
          .then((data) => {
            let newData = [];
            for (let i = 0; i < data.length; i++) {
              for (let j = 0; j < data[i].length; j++) {
                newData.push(data[i][j]);
              }
            }
            var filtered = newData.filter(function (el) {
              return el != null;
            });
            return filtered;
          })
          .then((data) => {
            setData(data);
          })
          .then(() => setIsLoading(false));
      })
      .catch((err) => console.log(err));
  }, []);

  const showModal = () => {
    const noticeError = $(".noticeOff");

    noticeError?.classList.remove("noticeOn");
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const noticeError = $(".noticeOff");
    // console.log(selectedNode?._id);
    postCategory({
      name: nameAdd,
      parentId: selectedNode?._id,
      languageId: 1,
      images: url,
    })
      .then((data) => data.data.data)
      .then((data) => {
        // console.log(data.groupId);
        if (selectedNode?.groupId[0]) {
          getCategoryGroupId(selectedNode?.groupId[0])
            .then((dataG) => dataG.data.data)
            .then((newData) => newData[1]._id)
            .then((dataLaos) => {
              postCategory({
                name: numberAdd,
                parentId: dataLaos,
                languageId: 2,
                groupId: data.groupId,
                images: url,
              }).then((data) => {
                notification["success"]({
                  message: `${t("admin_static.notice.success")}`,
                  description: `${t("admin_static.notice.descriptionsSuc")}`,
                  style: {
                    color: "rgba(0, 0, 0, 0.65)",
                    border: "1px solid #b7eb8f",
                    backgroundColor: "#f6ffed",
                    borderRadius: "5px",
                  },
                });
                setIsModalVisible(false);
                getCategory()
                  .then((res) => res.data)
                  .then((data) => data.data)
                  .then(async (dataInput) => {
                    getCategory()
                      .then((res) => res.data.data)
                      .then(async (dataNew) => {
                        await getChildrenSelectTree(
                          dataNew,
                          formatTreeData,
                          getCategoryById
                        );
                        setTreeData(dataNew);
                      });

                    await getChildren(
                      dataInput,
                      formatTreeData,
                      getCategoryById
                    );

                    const data = formatData(dataInput);
                    // console.log(dataInput);
                    return data.map((dataChild) => {
                      const newData = data.find(
                        (data) => dataChild.parent === data._id
                      );

                      return {
                        key: dataChild?._id,
                        Name: dataChild?.name,
                        Date: `${convertDataToDateString(
                          dataChild?.createdAt
                        )}`,
                        Number_add: dataChild?.title,
                        Select_value: dataChild?._id,
                        Sub_Categories: newData?.name,
                        images: dataChild?.images,
                        groupId: dataChild.groupId[0],
                        languageId: dataChild.languageId,
                      };
                    });
                  })
                  .then((dataE) => {
                    getCategoryLaos()
                      .then((data) => data.data.data)
                      .then(async (dataL) => {
                        await getChildren(
                          dataL,
                          formatTreeData,
                          getCategoryById
                        );
                        const dataTransformer = [...dataE];
                        const dataArr = [];
                        // console.log(dataL);
                        const newDataLaos = formatData(dataL);
                        return newDataLaos.map((dataChild) => {
                          return {
                            key: dataChild._id,
                            Name: dataChild.name,
                            Date: `${convertDataToDateString(
                              dataChild.createdAt
                            )}`,
                            Number_add: dataChild.name,
                            Select_value: dataChild._id,
                            Sub_Categories: dataChild.name,
                            images: dataChild?.images,
                            groupId: dataChild.groupId[0],
                            languageId: dataChild.languageId,
                          };
                        });
                      })
                      .then((data) => {
                        const dataArrE = dataE.filter(
                          (items) => items.languageId === 1
                        );

                        return dataArrE.map((item) => {
                          const dataApi = data.map((itemLaos) => {
                            if (item.groupId === itemLaos.groupId) {
                              return {
                                key: item.key,
                                Name: item.Name,
                                Date: item.Date,
                                Number_add: itemLaos.Name,
                                Select_value: item.Select_value,
                                Sub_Categories: item?.Sub_Categories,
                                images: item?.images,
                                groupId: item.groupId,
                                languageId: item.languageId,
                              };
                            } else return null;
                          });
                          return [...dataApi];
                        });
                      })
                      .then((data) => {
                        let newData = [];
                        for (let i = 0; i < data.length; i++) {
                          for (let j = 0; j < data[i].length; j++) {
                            newData.push(data[i][j]);
                          }
                        }
                        var filtered = newData.filter(function (el) {
                          return el != null;
                        });
                        return filtered;
                      })
                      .then((data) => {
                        setData(data);
                      });
                  })
                  .catch((err) => console.log(err));
              });
            });
        } else {
          postCategory({
            name: numberAdd,
            parentId: null,
            languageId: 2,
            groupId: data.groupId,
            images: url,
          }).then((data) => {
            setIsModalVisible(false);
            getCategory()
              .then((res) => res.data)
              .then((data) => data.data)
              .then(async (dataInput) => {
                getCategory()
                  .then((res) => res.data.data)
                  .then(async (dataNew) => {
                    await getChildrenSelectTree(
                      dataNew,
                      formatTreeData,
                      getCategoryById
                    );
                    setTreeData(dataNew);
                  });

                await getChildren(dataInput, formatTreeData, getCategoryById);

                const data = formatData(dataInput);
                // console.log(dataInput);
                return data.map((dataChild) => {
                  const newData = data.find(
                    (data) => dataChild.parent === data._id
                  );

                  return {
                    key: dataChild?._id,
                    Name: dataChild?.name,
                    Date: `${convertDataToDateString(dataChild?.createdAt)}`,
                    Number_add: dataChild?.title,
                    Select_value: dataChild?._id,
                    Sub_Categories: newData?.name,
                    images: dataChild?.images,
                    groupId: dataChild.groupId[0],
                    languageId: dataChild.languageId,
                  };
                });
              })
              .then((dataE) => {
                getCategoryLaos()
                  .then((data) => data.data.data)
                  .then(async (dataL) => {
                    await getChildren(dataL, formatTreeData, getCategoryById);
                    const dataTransformer = [...dataE];
                    const dataArr = [];
                    // console.log(dataL);
                    const newDataLaos = formatData(dataL);
                    return newDataLaos.map((dataChild) => {
                      return {
                        key: dataChild._id,
                        Name: dataChild.name,
                        Date: `${convertDataToDateString(dataChild.createdAt)}`,
                        Number_add: dataChild.name,
                        Select_value: dataChild._id,
                        Sub_Categories: dataChild.name,
                        images: dataChild?.images,
                        groupId: dataChild.groupId[0],
                        languageId: dataChild.languageId,
                      };
                    });
                  })
                  .then((data) => {
                    const dataArrE = dataE.filter(
                      (items) => items.languageId === 1
                    );

                    return dataArrE.map((item) => {
                      const dataApi = data.map((itemLaos) => {
                        if (item.groupId === itemLaos.groupId) {
                          return {
                            key: item.key,
                            Name: item.Name,
                            Date: item.Date,
                            Number_add: itemLaos.Name,
                            Select_value: item.Select_value,
                            Sub_Categories: item?.Sub_Categories,
                            images: item?.images,
                            groupId: item.groupId,
                            languageId: item.languageId,
                          };
                        } else return null;
                      });
                      return [...dataApi];
                    });
                  })
                  .then((data) => {
                    let newData = [];
                    for (let i = 0; i < data.length; i++) {
                      for (let j = 0; j < data[i].length; j++) {
                        newData.push(data[i][j]);
                      }
                    }
                    var filtered = newData.filter(function (el) {
                      return el != null;
                    });
                    return filtered;
                  })
                  .then((data) => {
                    setData(data);
                  });
              })
              .catch((err) => console.log(err));
          });
        }
      })
      .catch((err) => {
        noticeError?.classList.add("noticeOn");
        setIsModalVisible(true);
      });
    setNameAdd("");
    setNumberAdd("");
    setSelectedNode(null);
    setUrl("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNameAdd("");
    setNumberAdd("");
    setSelectedNode(null);
    setEdittingRow(null);
    setUrl("");
  };

  const onChange = (newValue) => {};

  const cancel = () => {};
  const Delete = async (key) => {
    try {
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        newData.splice(index, 1);
        deleteCategory(key).then((res) => {
          getCategoryGroupId(groupId)
            .then((data) => data.data.data)
            .then((data) => data[0]._id)

            .then((data) => {
              // console.log(data);
              deleteCategory(data).then((data) => {
                notification["success"]({
                  message: `${t("admin_static.notice.success")}`,
                  description: `${t("admin_static.notice.descriptionsSuc")}`,
                  style: {
                    color: "rgba(0, 0, 0, 0.65)",
                    border: "1px solid #b7eb8f",
                    backgroundColor: "#f6ffed",
                    borderRadius: "5px",
                  },
                });
                getCategory()
                  .then((res) => res.data)
                  .then((data) => data.data)
                  .then(async (dataInput) => {
                    getCategory()
                      .then((res) => res.data.data)
                      .then(async (dataNew) => {
                        await getChildrenSelectTree(
                          dataNew,
                          formatTreeData,
                          getCategoryById
                        );
                        setTreeData(dataNew);
                      });

                    await getChildren(
                      dataInput,
                      formatTreeData,
                      getCategoryById
                    );

                    const data = formatData(dataInput);
                    // console.log(dataInput);
                    return data.map((dataChild) => {
                      const newData = data.find(
                        (data) => dataChild.parent === data._id
                      );

                      return {
                        key: dataChild?._id,
                        Name: dataChild?.name,
                        Date: `${convertDataToDateString(
                          dataChild?.createdAt
                        )}`,
                        Number_add: dataChild?.title,
                        Select_value: dataChild?._id,
                        Sub_Categories: newData?.name,
                        images: dataChild?.images,
                        groupId: dataChild.groupId[0],
                        languageId: dataChild.languageId,
                      };
                    });
                  })
                  .then((dataE) => {
                    getCategoryLaos()
                      .then((data) => data.data.data)
                      .then(async (dataL) => {
                        await getChildren(
                          dataL,
                          formatTreeData,
                          getCategoryById
                        );
                        const dataTransformer = [...dataE];
                        const dataArr = [];
                        // console.log(dataL);
                        const newDataLaos = formatData(dataL);
                        return newDataLaos.map((dataChild) => {
                          return {
                            key: dataChild._id,
                            Name: dataChild.name,
                            Date: `${convertDataToDateString(
                              dataChild.createdAt
                            )}`,
                            Number_add: dataChild.name,
                            Select_value: dataChild._id,
                            Sub_Categories: dataChild.name,
                            images: dataChild?.images,
                            groupId: dataChild.groupId[0],
                            languageId: dataChild.languageId,
                          };
                        });
                        // newDataLaos.map((dataData) => {
                        //   const newData = dataTransformer.filter(
                        //     (data) => data.groupId === dataData.groupId
                        //   );

                        //   dataTransformer.splice(newData, 1);
                        // });
                        // dataArr.push(...newDataLaos, ...dataTransformer);

                        // const dataArrLaos = dataArr.filter(
                        //   (items) => items.languageId === 2
                        // );

                        // const dataArrE = dataE.filter((items) => items.languageId === 1);

                        // return dataArrE.map((item) => {
                        //   const dataApi = dataArrLaos.map((itemLaos) => {
                        //     if (item.groupId === itemLaos.groupId) {
                        //       return {
                        //         key: item.key,
                        //         Name: item.Name,
                        //         Date: item.Date,
                        //         Number_add: itemLaos.Name,
                        //         Select_value: item.Select_value,
                        //         Sub_Categories: item?.Sub_Categories,
                        //         images: item?.images,
                        //         groupId: item.groupId,
                        //         languageId: item.languageId,
                        //       };
                        //     } else return null;
                        //   });
                        //   return [...dataApi];
                        // });
                      })
                      .then((data) => {
                        const dataArrE = dataE.filter(
                          (items) => items.languageId === 1
                        );

                        return dataArrE.map((item) => {
                          const dataApi = data.map((itemLaos) => {
                            if (item.groupId === itemLaos.groupId) {
                              return {
                                key: item.key,
                                Name: item.Name,
                                Date: item.Date,
                                Number_add: itemLaos.Name,
                                Select_value: item.Select_value,
                                Sub_Categories: item?.Sub_Categories,
                                images: item?.images,
                                groupId: item.groupId,
                                languageId: item.languageId,
                              };
                            } else return null;
                          });
                          return [...dataApi];
                        });
                      })
                      .then((data) => {
                        let newData = [];
                        for (let i = 0; i < data.length; i++) {
                          for (let j = 0; j < data[i].length; j++) {
                            newData.push(data[i][j]);
                          }
                        }
                        var filtered = newData.filter(function (el) {
                          return el != null;
                        });
                        return filtered;
                      })
                      .then((data) => {
                        setData(data);
                      });
                  })
                  .catch((err) => console.log(err));
              });
            });
        });
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const save = () => {
    const noticeError = $(".noticeOff");
    const newData = [...data];
    const index = newData.findIndex((item) => keyEdit.key === item.key);
    const updateData = (newData[index] = {
      key: newData[index].key,
      Date: newData[index].Date,
      Name: nameAdd,
      Sub_Categories:
        selectedNode?.positon === undefined
          ? newData[index].Sub_Categories
          : selectedNode?.positon,
      Number_add: numberAdd,
      Select_value: selectedNode?.value,
      images: newData[index].images,
    });
    newData[index] = updateData;
    // api
    updateCategory(updateData.key, {
      images: url,
      name: updateData.Name,
      parentId: selectedNode?._id,
      languageId: 1,
      categoryId: selectedNode?._id,
    })
      .then(() => {
        setIsModalVisible(false);
        setIsLoading(true);
        getCategoryGroupId(groupId)
          .then((data) => data.data.data)
          .then((data) => {
            getCategoryGroupId(groupId)
              .then((dataG) => dataG.data.data)
              .then((newData) => newData[1]._id)
              .then((dataLaos) => {
                updateCategory(data[1]._id, {
                  images: url,
                  name: updateData.Number_add,
                  // parentId: dataLaos,
                  languageId: 2,
                  // categoryId: dataLaos,
                }).then((data) => {
                  notification["success"]({
                    message: `${t("admin_static.notice.success")}`,
                    description: `${t("admin_static.notice.descriptionsSuc")}`,
                    style: {
                      color: "rgba(0, 0, 0, 0.65)",
                      border: "1px solid #b7eb8f",
                      backgroundColor: "#f6ffed",
                      borderRadius: "5px",
                    },
                  });
                  getCategory()
                    .then((res) => res.data)
                    .then((data) => data.data)
                    .then(async (dataInput) => {
                      getCategory()
                        .then((res) => res.data.data)
                        .then(async (dataNew) => {
                          await getChildrenSelectTree(
                            dataNew,
                            formatTreeData,
                            getCategoryById
                          );
                          setTreeData(dataNew);
                        });
                      await getChildren(
                        dataInput,
                        formatTreeData,
                        getCategoryById
                      );
                      const data = formatData(dataInput);
                      return data.map((dataChild) => {
                        const newData = data.find(
                          (data) => dataChild.parent === data._id
                        );
                        return {
                          key: dataChild?._id,
                          Name: dataChild?.name,
                          Date: `${convertDataToDateString(
                            dataChild?.createdAt
                          )}`,
                          Number_add: dataChild?.title,
                          Select_value: dataChild?._id,
                          Sub_Categories: newData?.name,
                          images: dataChild?.images,
                          groupId: dataChild.groupId[0],
                          languageId: dataChild.languageId,
                        };
                      });
                    })
                    .then((dataE) => {
                      getCategoryLaos()
                        .then((data) => data.data.data)
                        .then(async (dataL) => {
                          await getChildren(
                            dataL,
                            formatTreeData,
                            getCategoryById
                          );
                          const dataTransformer = [...dataE];
                          const dataArr = [];
                          // console.log(dataL);
                          const newDataLaos = formatData(dataL);
                          return newDataLaos.map((dataChild) => {
                            return {
                              key: dataChild._id,
                              Name: dataChild.name,
                              Date: `${convertDataToDateString(
                                dataChild.createdAt
                              )}`,
                              Number_add: dataChild.name,
                              Select_value: dataChild._id,
                              Sub_Categories: dataChild.name,
                              images: dataChild?.images,
                              groupId: dataChild.groupId[0],
                              languageId: dataChild.languageId,
                            };
                          });
                          // newDataLaos.map((dataData) => {
                          //   const newData = dataTransformer.filter(
                          //     (data) => data.groupId === dataData.groupId
                          //   );
                          //   dataTransformer.splice(newData, 1);
                          // });
                          // dataArr.push(...newDataLaos, ...dataTransformer);
                          // const dataArrLaos = dataArr.filter(
                          //   (items) => items.languageId === 2
                          // );
                          // const dataArrE = dataE.filter((items) => items.languageId === 1);
                          // return dataArrE.map((item) => {
                          //   const dataApi = dataArrLaos.map((itemLaos) => {
                          //     if (item.groupId === itemLaos.groupId) {
                          //       return {
                          //         key: item.key,
                          //         Name: item.Name,
                          //         Date: item.Date,
                          //         Number_add: itemLaos.Name,
                          //         Select_value: item.Select_value,
                          //         Sub_Categories: item?.Sub_Categories,
                          //         images: item?.images,
                          //         groupId: item.groupId,
                          //         languageId: item.languageId,
                          //       };
                          //     } else return null;
                          //   });
                          //   return [...dataApi];
                          // });
                        })
                        .then((data) => {
                          const dataArrE = dataE.filter(
                            (items) => items.languageId === 1
                          );
                          return dataArrE.map((item) => {
                            const dataApi = data.map((itemLaos) => {
                              if (item.groupId === itemLaos.groupId) {
                                return {
                                  key: item.key,
                                  Name: item.Name,
                                  Date: item.Date,
                                  Number_add: itemLaos.Name,
                                  Select_value: item.Select_value,
                                  Sub_Categories: item?.Sub_Categories,
                                  images: item?.images,
                                  groupId: item.groupId,
                                  languageId: item.languageId,
                                };
                              } else return null;
                            });
                            return [...dataApi];
                          });
                        })
                        .then((data) => {
                          let newData = [];
                          for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < data[i].length; j++) {
                              newData.push(data[i][j]);
                            }
                          }
                          var filtered = newData.filter(function (el) {
                            return el != null;
                          });
                          return filtered;
                        })
                        .then((data) => {
                          setData(data);
                        })
                        .then(() => setIsLoading(false));
                    })
                    .catch((err) => console.log(err));
                });
              });
          });
      })
      .catch(() => {
        setIsModalVisible(true);
        noticeError?.classList.add("noticeOn");
      });
    setUrl("");
  };

  const handleEditRow = (row) => {
    setkeyEdit(row);
    // console.log(row);
    setEdittingRow(row);
    setNameAdd(row?.Name);
    setNumberAdd(row?.Number_add);
    setGroupId(row.groupId);
    // console.log("url", row?.images);

    setSelectedNode({ value: row?.Sub_Categories });
    showModal(true);
    setUrl(row?.images);
  };

  const handleSelectDelete = (row) => {
    setSelectedNode(row);
    setGroupId(row.groupId);
    // console.log(row);
  };

  const handleFileInputChange = async (e) => {
    const images = new FormData();
    images.append("images", e.target.files[0]);
    const fileExtension = e.target.files[0].type.slice(6);

    if (checkTypeImage(fileExtension)) {
      axios
        .post("https://laoscitec.api.minastik.com/api/upload-image", images)
        .then((res) => res.data.data)
        .then((data) => {
          setUrl(data[0].lgUrl);
        });
    } else {
      notification["error"]({
        message: `${t("admin_news.notice.updateFail")}`,
        description: `${t("admin_news.notice.descriptionFailUploadImage")}`,
        style: {
          color: "rgba(0, 0, 0, 0.65)",
          border: "1px solid #ffa39e",
          backgroundColor: "#fff1f0",
          borderRadius: "5px",
        },
      });
    }
  };

  const checkTypeImage = (fileExtension) => {
    const IMAGE_TYPE = ["jpg", "jpeg", "png"];
    let isValidFile = false;

    for (let index of IMAGE_TYPE) {
      if (fileExtension === index) {
        isValidFile = true;
      }
    }
    return isValidFile;
  };

  const columns = [
    {
      title: `${t("admin_category.colums.name")}`,
      dataIndex: "Name",
      width: "30%",
      editable: true,
    },
    {
      title: `${t("admin_category.colums.subcategory")}`,
      dataIndex: "Sub_Categories",
      width: "30%",
      editable: true,
    },
    {
      title: t("Image"),
      dataIndex: "images",
      width: "10%",
      editable: true,
      render: (text) => {
        return (
          <>
            <div className="img_gallery">
              <img alt="" src={`${BASE_URL}${text}`} />
            </div>
          </>
        );
      },
    },
    {
      title: `${t("admin_category.colums.date")}`,
      dataIndex: "Date",
      width: "15%",
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            <p>
              <FontAwesomeIcon icon={faCalendarMinus} />
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: `${t("admin_category.colums.action")}`,
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <div className={cx("operation_btn")}>
            <Typography.Link>
              <div onClick={() => handleEditRow(record)}>
                <img src={pen} alt="" /> {t("admin_category.handle.edit")}
              </div>
            </Typography.Link>
            <Popconfirm
              title={t("admin_category.handle.confirm")}
              onConfirm={() => Delete(record.key)}
              icon={<FontAwesomeIcon icon={faCircleXmark} />}
              okText={t("admin_category.handle.delete")}
              cancelText={t("admin_category.handle.cancel")}
            >
              <Typography.Link onClick={() => handleSelectDelete(record)}>
                <FontAwesomeIcon icon={faTrashCan} />{" "}
                {t("admin_category.handle.delete")}
              </Typography.Link>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleSelect = (value, node, extra) => {
    // console.log({ value, node, extra });
    // console.log(node);
    setSelectedNode(node);
  };

  const handleDeleteImage = () => {
    setUrl("");
  };

  return (
    <div className={cx("categories_wrapper", "categories_table")}>
      <div className={cx("modal_wrapper")}>
        <Button type="primary" onClick={showModal}>
          <FontAwesomeIcon icon={faPlus} />
          {t("admin_category.handle.add")}
        </Button>
      </div>
      <Modal
        wrapClassName="modal_categories"
        title={
          edittingRow
            ? `${t("admin_category.handle.titleEdit")}`
            : `${t("admin_category.handle.add")}`
        }
        visible={isModalVisible}
        onOk={edittingRow ? save : handleOk}
        onCancel={handleCancel}
        cancelText={t("admin_category.handle.cancel")}
        okText={
          edittingRow
            ? `${t("admin_category.handle.upload")}`
            : `${t("admin_category.handle.addBt")}`
        }
      >
        <h3>Name / ຊື່</h3>
        <form>
          <div>
            <label>Eng</label>
            <input
              type="text"
              id="fname"
              name="fname"
              value={nameAdd}
              onChange={(e) => setNameAdd(e.target.value)}
            />
          </div>
          <div className="noticeOff">{t("admin_category.notice")}</div>
          <br></br>
          <div>
            <label>ປະເທດລາວ</label>
            <input
              type="text"
              id="lname"
              name="lname"
              value={numberAdd}
              onChange={(e) => setNumberAdd(e.target.value)}
            />
          </div>
          <br></br>
          <div>
            <h3
              style={{
                display: "inline-block",
                width: "72px",
                marginRight: "35px",
              }}
            >
              Parent
            </h3>
            <TreeSelect
              placeholder={t("admin_category.handle.plSelect")}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={treeData}
              treeDefaultExpandAll
              defaultValue={t("admin_category.handle.plSelect")}
              onChange={onChange}
              value={selectedNode?.value}
              onSelect={handleSelect}
            />
            <h3>Gallery / ຄັງຮູບ</h3>
            <div className={cx("thumbnail_wrapper")}>
              <div className={cx("thumbnail_box")}>
                <div className={cx("thumbnail")}>
                  {url?.length > 0 ? (
                    <img
                      style={{ maxHeight: "100px" }}
                      src={`${BASE_URL}${url}`}
                      alt={" "}
                      className={cx("image")}
                    />
                  ) : (
                    <></>
                  )}
                  <div className={cx("after")}>
                    <Button
                      onClick={handleDeleteImage}
                      className={cx("delete_image")}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <input
              type={"file"}
              id="upload_image"
              accept={"image/*"}
              title={" "}
              style={{ color: "transparent" }}
              onChange={handleFileInputChange}
              className={cx("upload_image")}
            />
          </div>
        </form>

        <br></br>
      </Modal>

      {!isLoading ? (
        <Table
          bordered
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            showTitle: false,
            onChange: cancel,
          }}
        />
      ) : (
        components
      )}
    </div>
  );
};

export default Categories;
