import axios from "axios";
export const BASE_URL = "https://laoscitec.api.minastik.com";
const request = (requestObject) => {
    axios.defaults.baseURL = "https://laoscitec.api.minastik.com";
    return axios.request(requestObject)
}

export const getNews_details = async (id,language) => {
  const lan = sessionStorage.getItem("LANGUAGE");
    const data = await  request({
        url: `/api/blog/detail/${id}`,
        method: "GET",
        params: {language: (language = lan)}
    })
    return data;
}

export const postNews_details = async () => {
    return request({
        url: `/api/blog/`,
        method: "POST"
    })
}

export const deleteNews_details = async (id) => {
    return request({
        url: `/api/blog/${id}`,
        method: "DELETE"
    })
}

export const updateNews_details = async (id) => {
    return request({
        url: `/api/blog/${id}`,
        method: "PUT"
    })
}