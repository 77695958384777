import style from "./Other.module.scss";
import classNames from "classnames/bind";
import "./Other.replace.scss";
import { useState, useEffect } from "react";
import { getCommon, updateCommon } from "../../../api-url/common/index";
import { Form, Input, notification } from "antd";
import { useTranslation } from "react-i18next";

const cx = classNames.bind(style);

export const Other = () => {
  const { t } = useTranslation();

  const [dataInput, setDataInput] = useState([]);
  const handleSubmit = async (e) => {
    const data = { ...e };
    const payloads = Object.keys(data).reduce((acc, curr) => {
      const arr = curr.split("-");
      if (!acc[arr[1]]) acc[arr[1]] = {};
      acc[arr[1]][arr[0]] = data[curr];
      return acc;
    }, {});
    await Promise.all(
      Object.keys(payloads).map((id, index) => {
        return updateCommon(id, payloads[id])
          .then((res) => {
            if (index < 1) {
              notification["success"]({
                message: `${t("admin_static.notice.success")}`,
                description: `${t("admin_static.notice.descriptionsSuc")}`,
                style: {
                  color: "rgba(0, 0, 0, 0.65)",
                  border: "1px solid #b7eb8f",
                  backgroundColor: "#f6ffed",
                  borderRadius: "5px",
                },
              });
            }
          })
          .catch((err) => {
            if (index < 1) {
              notification["error"]({
                message: `${t("admin_static.notice.updateFail")}`,
                description: `${t("admin_static.notice.descriptionsFail")}`,
                style: {
                  color: "rgba(0, 0, 0, 0.65)",
                  border: "1px solid #ffa39e",
                  backgroundColor: "#fff1f0",
                  borderRadius: "5px",
                },
              });
            }
          });
      })
    );
  };

  useEffect(() => {
    getCommon()
      .then((res) => res.data)
      .then((data) => data.data)
      .then((data) => {
        console.log(data);
        const newData = data.map((e) => {
          let newKey = "";
          if (e.key === "Phone1") {
            newKey = "Phone Number";
          } else if (e.key === "Phone2") {
            newKey = "Address";
          } else {
            newKey = "Gmail";
          }
          return {
            ...e,
            label: newKey,
          };
        });
        setDataInput(newData);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Form
        name="form_otherAdmin"
        className={cx("other_wrapper", "other_replace")}
        onFinish={(noti) => handleSubmit(noti)}
      >
        {dataInput.map((item, index) => {
          return (
            <div className={cx("other_form")} key={index}>
              <Form.Item
                name={`key-${item._id}`}
                label="Key"
                className={cx("input_data_other")}
                // value={item.key}
                initialValue={item.label}
              >
                <div className={cx("other_key")}>{item.label}</div>
                {/* <Input
                // defaultValue={item.key}
                // onChange={(e) => {
                //   setDataInputKey(e.target.value);
                // }}
                /> */}
              </Form.Item>
              <Form.Item
                name={`value-${item._id}`}
                label="Value"
                className={cx("input_data_other")}
                value={item.value}
                initialValue={item.value}
              >
                <Input
                // defaultValue={item.value}
                // onChange={(e) => setDataInputValue(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name={`description-${item._id}`}
                label="Description"
                className={cx("input_data_other")}
                value={item.description}
                initialValue={item.description}
              >
                <Input
                  className={cx("input_des")}
                  // defaultValue={item.description}
                  // onChange={(e) => {
                  //   setDataInputDes(e.target.value);
                  // }}
                />
              </Form.Item>
            </div>
          );
        })}
        <div className={cx("submit_btn")}>
          <button type="submit">Update</button>
        </div>
      </Form>
    </>
  );
};
export default Other;
