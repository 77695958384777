import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { login } from "../../../api-url/login";
import { LOGIN_AUTH } from "../../../reducers/index";
import "./Login.scss";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const $ = document.querySelector.bind(document);
function Login({ setAuth }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    sessionStorage.setItem("LANGUAGE", "en");
    localStorage.setItem("i18nextLng", "en");
    i18n.changeLanguage("en");

    // console.log("Success:", values);
    const errorLogin = $(".error");

    try {
      dispatch(
        LOGIN_AUTH({
          email: values.username,
          password: values.password,
        })
      );
      const {
        data: {
          data: { token },
        },
      } = await login({
        email: values.username,
        password: values.password,
      })
        .then((data) => data)
        .catch((err) => {
          // console.log(err);
          errorLogin.classList.add("error_login");
        });

      localStorage.setItem("ACCESS_TOKEN", token);
      setAuth(true);
    } catch (err) {
      // console.log(errorLogin);
      errorLogin?.classList.add("error_login");
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <div className="background_login">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div>
            <div className="login_form">
              <div className="login_title">
                <h1>LOGIN</h1>
              </div>
              <Form.Item
                label="USERNAME"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="PASSWORD"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 24,
                }}
              >
                <Link to="/admin_laoscitec/forgot_account">
                  Forgot Password?
                </Link>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  span: 24,
                }}
              >
                <Button type="primary" htmlType="submit">
                  LOG IN
                </Button>
              </Form.Item>
              <div className="error">
                Login failed: username or password is incorrect.
              </div>
              {/* <div className="Create_account">
                <Link to="/admin_laoscitec/create_account">
                  Do not have any account?
                </Link>
              </div> */}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
