import classNames from "classnames/bind";
import { useEffect, useState, useRef, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.replace.scss";
import style from "./Static_Content.module.scss";

import pen from "@assets/icons/icon_categories/pen.png";

import {
  faCircleXmark,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
  Upload,
} from "antd";
import {
  getAboutUs_E,
  getAboutUs_L,
  getHome_E,
  getHome_L,
  updateAboutUs,
  updateHome,
  BASE_URL,
} from "../../../api-url/stacticContent";

import { useTranslation } from "react-i18next";
import {
  dataURLtoFile,
  parserFromAPI,
  handlePaste,
  checkTypeImage,
} from "../../../utils/functions";
import { Loading } from "../../Loading";
import { NotData } from "../../NotData";
import axios from "axios";

const cx = classNames.bind(style);

export const lan = sessionStorage.getItem("LANGUAGE");

const toolbarRowIcon = {
  toolbar: [["image"]],
};

const toolbarDes = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, true] }],
    ["bold", "italic", "underline"], // toggled buttons
  ],
};
export const StacticsContent = () => {
  const { t } = useTranslation();
  const $ = document.querySelector.bind(document);
  const lan = sessionStorage.getItem("LANGUAGE");

  const [bannerHeadingE, setBannerHeadingE] = useState("");
  const [bannerHeadingLao, setBannerHeadingLao] = useState("");

  const [previewVisible, setPreviewVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [previewImage, setPreviewImage] = useState("");
  const [data, setData] = useState([]);
  const [tempTitle, setTempTitle] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [edittingRow, setEdittingRow] = useState(null);
  const [keyEdit, setkeyEdit] = useState("");

  const [iconRow, setIconRow] = useState("");
  const [description, setdescription] = useState("");
  const [descriptionL, setdescriptionL] = useState("");

  const [amountModal, setAmountModal] = useState("");
  const [idPageE, setIdPageE] = useState("");
  const [idPageL, setIdPageL] = useState("");

  const [titleAboutUs_E, setTitleAboutUs_E] = useState("");
  const [tempTitleAboutUs_E, setTempTitleAboutUs_E] = useState("");
  const [textAboutUs_E, setTextAboutUs_E] = useState("");
  const [idAboutUs_E, setIdAboutUs_E] = useState("");

  const [titleAboutUs_L, setTitleAboutUs_L] = useState("");
  const [tempTitleAboutUs_L, setTempTitleAboutUs_L] = useState("");
  const [textAboutUs_L, setTextAboutUs_L] = useState("");
  const [idAboutUs_L, setIdAboutUs_L] = useState("");

  const [desNew, setDesNew] = useState("");
  const [fileList, setFileList] = useState([]);
  const [checkData, setCheckData] = useState([]);
  const [checkDataA_E, setCheckDataA_E] = useState([]);
  const [checkDataA_L, setCheckDataA_L] = useState([]);

  const [urlImgae, setUrlImage] = useState([]);

  const [components, setComponents] = useState(<Loading />);

  const quillRefList = ["titleE", "titleL", "contentE", "contentL"];

  const quillTitleE = useRef("");
  const quillTitleL = useRef("");
  const quillContentE = useRef("");
  const quillContentL = useRef("");

  useEffect(() => {
    setTimeout(() => {
      setComponents(<NotData />);
    }, 10000);
    const getBase64FromUrl = async (url) => {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    };
    const noticeError = $(".noticeOff");
    const noticeSumbError = $(".noticeSumbitFail");
    const noticeSumbSuc = $(".noticeSumbitSuc");
    const noticeErrorAboutUs_E = $(".noticeOffAboutUs_E");
    const noticeSumbErrorAboutUs_E = $(".noticeSumbitFailAboutUs_E");
    const noticeSumbSucAboutUs_E = $(".noticeSumbitSucAboutUs_E");

    const noticeErrorAboutUs_L = $(".noticeOffAboutUs_L");
    const noticeSumbErrorAboutUs_L = $(".noticeSumbitFailAboutUs_L");
    const noticeSumbSucAboutUs_L = $(".noticeSumbitSucAboutUs_L");

    getHome_E()
      .then((data) => data.data.data)
      .then((data) => {
        noticeError?.classList.remove("noticeOn");
        noticeSumbError?.classList.remove("noticeFailOn");
        noticeSumbSuc?.classList.remove("noticeSucOn");

        setBannerHeadingE(data[0].label);
        setTempTitle(data[0].label);
        // setData(data[0].content.split("*"));
        setIdPageE(data[0]._id);
        const ArrThumbnail = [data[0].thumbnail];
        setUrlImage(data[0].thumbnail);
        ArrThumbnail.map((url, index) => {
          getBase64FromUrl(`https://laoscitec.api.minastik.com${url}`).then(
            (log) => {
              // console.log(log);
              const ArrThumbnailnew = [log];
              setFileList(() => {
                return ArrThumbnailnew.map((url, index) => {
                  const file = dataURLtoFile(url, `${index}.png`);

                  if (file && typeof file === "object") file.thumbUrl = url;

                  return file || {};
                });
              });
            }
          );
          return url;
        });

        return data[0].content.split("*");
      })
      .then((data) => {
        // console.log(data);
        setDesNew((prev) => [...prev, ...data]);

        return data.reduce((acc, item, id) => {
          const index = Math.floor(id / 3);

          if (acc[index]) {
            acc[index].amount = item;
          } else acc.push({ icon: item, id: id });
          if (id % 3 === 1) {
            acc[index].description = item;
          } else acc[index].amount = item;
          return acc;
        }, []);
      })
      .then((data) => {
        setData(data);
        return data;
      })
      .then((data) => {
        getHome_L()
          .then((data) => data.data.data)
          .then((data) => {
            setCheckData(data);
            noticeError?.classList.remove("noticeOn");
            noticeSumbError?.classList.remove("noticeFailOn");
            noticeSumbSuc?.classList.remove("noticeSucOn");

            setBannerHeadingLao(data[0].label);
            setTempTitle(data[0].label);
            // setData(data[0].content.split("*"));

            setIdPageL(data[0]._id);
            // const ArrThumbnail = [data[0].thumbnail];
            // console.log(ArrThumbnail);
            // setFileList(() => {
            //   return ArrThumbnail.map((url, index) => {
            //     const file = dataURLtoFile(
            //       `https://laoscitec.api.minastik.com${url}`,
            //       `${index}.png`
            //     );

            //     if (file && typeof file === "object")
            //       file.thumbUrl = `https://laoscitec.api.minastik.com${url}`;
            //     return file || {};
            //   });
            // });

            return data[0].content.split("*");
          });
      })

      .catch((err) => console.log(err));

    getAboutUs_E()
      .then((res) => res.data.data)
      .then((data) => {
        noticeErrorAboutUs_E?.classList.remove("noticeOnAboutUs");
        noticeSumbErrorAboutUs_E?.classList.remove("noticeFailOnAboutUs");
        noticeSumbSucAboutUs_E?.classList.remove("noticeSucOnAboutUs");
        setTitleAboutUs_E(data[0]?.label);
        setTempTitleAboutUs_E(data[0]?.label);
        setTextAboutUs_E(data[0]?.content);
        setIdAboutUs_E(data[0]?._id);
        setCheckDataA_E(data);
      })
      .catch((err) => console.log(err));

    getAboutUs_L()
      .then((res) => res.data.data)
      .then((data) => {
        noticeErrorAboutUs_L?.classList.remove("noticeOnAboutUs");
        noticeSumbErrorAboutUs_L?.classList.remove("noticeFailOnAboutUs");
        noticeSumbSucAboutUs_L?.classList.remove("noticeSucOnAboutUs");
        setTitleAboutUs_L(data[0]?.label);
        setTempTitleAboutUs_L(data[0]?.label);
        setTextAboutUs_L(data[0]?.content);
        setIdAboutUs_L(data[0]?._id);
        setCheckDataA_L(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleOk = (e) => {
    setData((pre) => {
      return [
        ...pre,
        {
          icon: iconRow,
          description: description,
          amount: amountModal,
        },
      ];
    });
    // console.log(description);
    setDesNew((pre) => {
      return [...pre, iconRow, description, amountModal];
    });
    setIconRow("");
    setdescriptionL("");
    setdescription("");
    setAmountModal("");
    setIsModalVisible(false);
  };

  const save = () => {
    const newData = [...data];
    // console.log(keyEdit.index);
    const index = newData.findIndex((item) => keyEdit.id === item.id);
    const updateData = (newData[index] = {
      description: description,
      amount: amountModal,
    });

    newData[index] = updateData;
    setData(newData);
    // console.log(newData);
    setIsModalVisible(false);
    setIconRow([]);
    setdescription();
    setdescriptionL();
    setAmountModal();
  };

  const handleEditRow = (row) => {
    setkeyEdit(row);
    // console.log(row);
    setEdittingRow(row);
    setdescription(row?.description);
    setAmountModal(row?.amount);
    showModal(true);
  };

  const handleDelete = (row) => {
    setkeyEdit(row);
  };
  // console.log(newData);
  // console.log(desNew);
  const Delete = async (key) => {
    try {
      const newData = [...data];
      const index = newData.findIndex((item) => keyEdit.id === item.id);
      // console.log(index);
      if (index > -1) {
        newData.splice(index, 1);
        desNew.splice(index * 2, 3);
        // console.log(newData);
        // console.log(desNew);

        // newData.reduce((acc, item) => {});
        setData(newData);
        setDesNew(desNew);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleCancel_img = () => setPreviewVisible(false);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    let formData = new FormData();
    formData.append("images", newFileList[0]?.originFileObj);
    if (newFileList[0]?.originFileObj) {
      axios
        .post("https://laoscitec.api.minastik.com/api/upload-image", formData)
        .then((res) => res.data.data)
        .then((data) => {
          setFileList(newFileList);
          setUrlImage(data[0].lgUrl);

          // getBase64FromUrl(
          //   `https://laoscitec.api.minastik.com${data[0].lgUrl}`
          // ).then((log) => console.log(log));

          // getBase64Image(data[0].lgUrl);
        })
        .catch((err) => {
          setFileList([]);
          if (newFileList) {
            notification["error"]({
              message: `CHANGE IMAGE FAILED`,
              description: `File too large`,
              style: {
                color: "rgba(0, 0, 0, 0.65)",
                border: "1px solid #ffa39e",
                backgroundColor: "#fff1f0",
                borderRadius: "5px",
              },
            });
          }
        });
    }
  };

  const handleChangeContentE = (e) => {
    const target = document.getElementById("quill2");
    handlePaste(target);
    setTextAboutUs_E(e);
  };

  const handleChangeContentL = (e) => {
    const target = document.getElementById("quill4");
    handlePaste(target);
    setTextAboutUs_L(e);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setdescription("");
    setIconRow("");
    setdescriptionL("");
    setAmountModal("");
    setEdittingRow(null);
  };

  const uploadButton = <div>{t("admin_static.home.changeImg")}</div>;

  const openNotificationWithIcon = async (type) => {
    const getBase64FromUrl = async (url) => {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    };
    const noticeError = $(".noticeOff");
    const noticeSumbError = $(".noticeSumbitFail");
    const noticeSumbSuc = $(".noticeSumbitSuc");

    // const dataThumb = fileList.map((data) => data.thumbUrl);
    // console.log(desNew);
    // console.log(lan === "en");

    updateHome(idPageE, {
      label: bannerHeadingE,
      // content: "123",
      content: desNew.join("*"),
      thumbnail: urlImgae,
      type: "home",
      languageId: 1,
    })
      .then(() => {
        // console.log(desNew);
        noticeError?.classList.remove("noticeOn");
        noticeSumbError?.classList.remove("noticeFailOn");
        notification["success"]({
          message: `${t("admin_static.notice.success")}`,
          description: `${t("admin_static.notice.descriptionsSuc")}`,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #b7eb8f",
            backgroundColor: "#f6ffed",
            borderRadius: "5px",
          },
        });
        getHome_E()
          .then((data) => data.data.data)
          .then((data) => {
            noticeError?.classList.remove("noticeOn");
            noticeSumbError?.classList.remove("noticeFailOn");
            noticeSumbSuc?.classList.remove("noticeSucOn");

            setBannerHeadingE(data[0].label);
            setTempTitle(data[0].label);
            // setData(data[0].content.split("*"));
            setIdPageE(data[0]._id);
            const ArrThumbnail = [data[0].thumbnail];
            ArrThumbnail.map((url, index) => {
              getBase64FromUrl(`https://laoscitec.api.minastik.com${url}`).then(
                (log) => {
                  const ArrThumbnailnew = [log];
                  setFileList(() => {
                    return ArrThumbnailnew.map((url, index) => {
                      const file = dataURLtoFile(url, `${index}.png`);

                      if (file && typeof file === "object") file.thumbUrl = url;

                      return file || {};
                    });
                  });
                }
              );
            });

            return data[0].content.split("*");
          })
          .then((data) => {
            // console.log(data);

            return data.reduce((acc, item, id) => {
              const index = Math.floor(id / 3);

              if (acc[index]) {
                acc[index].amount = item;
              } else acc.push({ icon: item, id: id });
              if (id % 3 === 1) {
                acc[index].description = item;
              } else acc[index].amount = item;
              return acc;
            }, []);
          })
          .then((data) => {
            if (lan === "en") {
              setData(data);
            } else return data;
          })
          .then((data) => {
            getHome_L()
              .then((data) => data.data.data)
              .then((data) => {
                noticeError?.classList.remove("noticeOn");
                noticeSumbError?.classList.remove("noticeFailOn");
                noticeSumbSuc?.classList.remove("noticeSucOn");

                setBannerHeadingLao(data[0].label);
                setTempTitle(data[0].label);
                // setData(data[0].content.split("*"));

                setIdPageL(data[0]._id);
                // const ArrThumbnail = [data[0].thumbnail];
                // setFileList(() => {
                //   return ArrThumbnail.map((url, index) => {
                //     const file = dataURLtoFile(url, `${index}.png`);

                //     if (file && typeof file === "object") file.thumbUrl = url;
                //     return file || {};
                //   });
                // });

                return data[0].content.split("*");
              })
              .then((data) => {
                // console.log(data);

                return data.reduce((acc, item, id) => {
                  const index = Math.floor(id / 3);

                  if (acc[index]) {
                    acc[index].amount = item;
                  } else acc.push({ icon: item, id: id });
                  if (id % 3 === 1) {
                    acc[index].description = item;
                  } else acc[index].amount = item;
                  return acc;
                }, []);
              })
              .then((data) => {
                if (lan === "laos") {
                  // setData(data);
                }
              })

              .catch((err) => console.log(err));
          })

          .catch((err) => console.log(err));
      })
      .catch((err) => {
        // console.log(err);
        noticeError?.classList.add("noticeOn");
        // noticeSumbError?.classList.add("noticeFailOn");
        notification["error"]({
          message: `${t("admin_static.notice.updateFail")}`,
          description: `${t("admin_static.notice.descriptionsFail")}`,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #ffa39e",
            backgroundColor: "#fff1f0",
            borderRadius: "5px",
          },
        });
      });

    updateHome(idPageL, {
      label: bannerHeadingLao,
      // content: "123",
      content: desNew.join("*"),
      thumbnail: urlImgae,
      type: "home",
      languageId: 2,
    })
      .then(() => {
        getHome_E()
          .then((data) => data.data.data)
          .then((data) => {
            noticeError?.classList.remove("noticeOn");
            noticeSumbError?.classList.remove("noticeFailOn");
            noticeSumbSuc?.classList.remove("noticeSucOn");

            setBannerHeadingE(data[0].label);
            setTempTitle(data[0].label);
            // setData(data[0].content.split("*"));
            setIdPageE(data[0]._id);

            return data[0].content.split("*");
          })
          .then((data) => {
            return data.reduce((acc, item, id) => {
              const index = Math.floor(id / 3);

              if (acc[index]) {
                acc[index].amount = item;
              } else acc.push({ icon: item, id: id });
              if (id % 3 === 1) {
                acc[index].description = item;
              } else acc[index].amount = item;
              return acc;
            }, []);
          })
          .then((data) => {
            if (lan === "en") {
              // setData(data);
            } else return data;
          })
          .then((data) => {
            getHome_L()
              .then((data) => data.data.data)
              .then((data) => {
                noticeError?.classList.remove("noticeOn");
                noticeSumbError?.classList.remove("noticeFailOn");
                noticeSumbSuc?.classList.remove("noticeSucOn");

                setBannerHeadingLao(data[0].label);
                setTempTitle(data[0].label);
                setIdPageL(data[0]._id);
                return data[0].content.split("*");
              })
              .then((data) => {
                // console.log(data);

                return data.reduce((acc, item, id) => {
                  const index = Math.floor(id / 3);

                  if (acc[index]) {
                    acc[index].amount = item;
                  } else acc.push({ icon: item, id: id });
                  if (id % 3 === 1) {
                    acc[index].description = item;
                  } else acc[index].amount = item;
                  return acc;
                }, []);
              })
              .then((data) => {
                if (lan === "laos") {
                  // setData(data);
                }
              })

              .catch((err) => console.log(err));
          })

          .catch((err) => console.log(err));
      })
      .catch((err) => {
        // console.log(err);
        noticeError?.classList.add("noticeOn");
        // noticeSumbError?.classList.add("noticeFailOn");
        notification["error"]({
          message: `${t("admin_static.notice.updateFail")}`,
          description: `${t("admin_static.notice.descriptionsFail")}`,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #ffa39e",
            backgroundColor: "#fff1f0",
            borderRadius: "5px",
          },
        });
      });
  };

  const openNotificationWithIconAbout_E = (type) => {
    const noticeErrorAboutUs_E = $(".noticeOffAboutUs_E");
    const noticeSumbErrorAboutUs_E = $(".noticeSumbitFailAboutUs_E");

    updateAboutUs(idAboutUs_E, {
      label: titleAboutUs_E,
      unsignedLabel: titleAboutUs_E,
      content: textAboutUs_E,
      languageId: 1,
      type: "about",
      thumbnail: " ",
    })
      .then(() => {
        noticeErrorAboutUs_E?.classList.remove("noticeOnAboutUs_E");
        noticeSumbErrorAboutUs_E?.classList.remove("noticeFailOnAboutUs_E");

        notification["success"]({
          message: `${t("admin_static.notice.success")}`,
          description: `${t("admin_static.notice.descriptionsSuc")}`,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #b7eb8f",
            backgroundColor: "#f6ffed",
            borderRadius: "5px",
          },
        });
        getAboutUs_E()
          .then((res) => res.data.data)
          .then((data) => {
            // console.log("success to update About Us");
            setTitleAboutUs_E(data[0]?.label);
            setTempTitleAboutUs_E(data[0]?.label);
            setTextAboutUs_E(data[0]?.content);
            setIdAboutUs_E(data[0]?._id);
          });
      })
      .catch((err) => {
        notification["error"]({
          message: `${t("admin_static.notice.updateFail")}`,
          description: `${t("admin_static.notice.descriptionsFail")}`,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #ffa39e",
            backgroundColor: "#fff1f0",
            borderRadius: "5px",
          },
        });
        console.log(err);
        // alert("YOU MUST CHANGE TITLE PAGE ABOUT US");
        noticeErrorAboutUs_E?.classList.add("noticeOnAboutUs_E");
      });
  };

  const openNotificationWithIconAbout_L = (type) => {
    const noticeErrorAboutUs_L = $(".noticeOffAboutUs_L");
    const noticeSumbErrorAboutUs_L = $(".noticeSumbitFailAboutUs_L");

    updateAboutUs(idAboutUs_L, {
      label: titleAboutUs_L,
      unsignedLabel: titleAboutUs_L,
      content: textAboutUs_L,
      languageId: 2,
      type: "about",
      thumbnail: " ",
    })
      .then(() => {
        noticeErrorAboutUs_L?.classList.remove("noticeOnAboutUs_L");
        noticeSumbErrorAboutUs_L?.classList.remove("noticeFailOnAboutUs_L");

        notification["success"]({
          message: `${t("admin_static.notice.success")}`,
          description: `${t("admin_static.notice.descriptionsSuc")}`,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #b7eb8f",
            backgroundColor: "#f6ffed",
            borderRadius: "5px",
          },
        });
        getAboutUs_L()
          .then((res) => res.data.data)
          .then((data) => {
            // console.log("success to update About Us");
            setTitleAboutUs_L(data[0]?.label);
            setTempTitleAboutUs_L(data[0]?.label);
            setTextAboutUs_L(data[0]?.content);
            setIdAboutUs_L(data[0]?._id);
          });
      })
      .catch((err) => {
        notification["error"]({
          message: `${t("admin_static.notice.updateFail")}`,
          description: `${t("admin_static.notice.descriptionsFail")}`,
          style: {
            color: "rgba(0, 0, 0, 0.65)",
            border: "1px solid #ffa39e",
            backgroundColor: "#fff1f0",
            borderRadius: "5px",
          },
        });
        console.log(err);
        // alert("YOU MUST CHANGE TITLE PAGE ABOUT US");
        noticeErrorAboutUs_L?.classList.add("noticeOnAboutUs_L");
      });
  };

  const checkTitle = () => {
    const noticeError = $(".noticeOff");
    const noticeSumbError = $(".noticeSumbitFail");
    var x = document.getElementById("fname1");
    if (x.value === tempTitle) {
      noticeError?.classList.add("noticeOn");
    } else {
      noticeError?.classList.remove("noticeOn");
      noticeSumbError?.classList.remove("noticeFailOn");
    }
  };

  const checkTitleAboutUs_E = () => {
    const noticeErrorAboutUs = $(".noticeOffAboutUs");
    const noticeSumbErrorAboutUs = $(".noticeSumbitFailAboutUs");
    if (titleAboutUs_E === tempTitleAboutUs_E) {
      noticeErrorAboutUs?.classList.add("noticeOnAboutUs");
    } else {
      noticeErrorAboutUs?.classList.remove("noticeOnAboutUs");
      noticeSumbErrorAboutUs?.classList.remove("noticeFailOnAboutUs");
    }
  };

  const columns = [
    {
      title: `${t("icon")}`,
      dataIndex: "icon",
      width: "8%",
      editable: true,
      render: (text, record, index) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: parserFromAPI(text).body.innerHTML,
            }}
          ></div>
        );
      },
    },
    {
      title: `${t("admin_static.columns.title")}`,
      dataIndex: "description",
      editable: true,
      render: (text, record, index) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: parserFromAPI(text).body.innerHTML,
            }}
          ></div>
        );
      },
    },
    {
      title: `${t("admin_static.columns.amount")}`,
      dataIndex: "amount",
      width: "15%",
      editable: true,
      render: (text, record, index) => {
        return (
          <div key={index} className={cx("operation_btn")}>
            <Typography.Link>
              <div onClick={() => handleEditRow(record)}>
                <img src={pen} alt="" />
              </div>
            </Typography.Link>

            <p>{text}</p>

            <Popconfirm
              key={index}
              title="Do You Want to Delete?"
              onConfirm={() => Delete(record.key)}
              icon={<FontAwesomeIcon key={index} icon={faCircleXmark} />}
              okText="Delete"
              cancelText="Cancle"
            >
              <Typography.Link>
                <FontAwesomeIcon
                  onClick={() => handleDelete(record)}
                  key={index}
                  icon={faTrashCan}
                />
              </Typography.Link>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const checkTypeImage = (fileExtension) => {
    const IMAGE_TYPE = ["jpg", "jpeg", "png"];
    let isValidFile = false;

    for (let index of IMAGE_TYPE) {
      if (fileExtension === index) {
        isValidFile = true;
      }
    }
    return isValidFile;
  };

  const imgHandler = (e) => {
    // handlers object will be merged with default handlers object
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file, false);
    };
  };

  const saveToServer = async (file, isPaste) => {
    const image = new FormData();
    image.append("images", file);
    const fileExtension = file.type.slice(6);

    if (checkTypeImage(fileExtension)) {
      await axios
        .post("https://laoscitec.api.minastik.com/api/upload-image", image)
        .then((res) => res.data.data)
        .then((data) => {
          insertToEditor(data[0].lgUrl);
        });
    } else {
      notification["error"]({
        message: `${t("admin_news.notice.updateFail")}`,
        description: `${t("admin_news.notice.descriptionFailUploadImage")}`,
        style: {
          color: "rgba(0, 0, 0, 0.65)",
          border: "1px solid #ffa39e",
          backgroundColor: "#fff1f0",
          borderRadius: "5px",
        },
      });
    }
  };

  const insertToEditor = (url) => {
    const TitleEnglish = quillTitleE.current.getEditor();
    const TitleLaos = quillTitleL.current.getEditor();
    const ContentEnglish = quillContentE.current.getEditor();
    const ContentLaos = quillContentL.current.getEditor();
    // console.log("fourth");
    if (TitleEnglish.hasFocus()) {
      const range = TitleEnglish.getSelection();
      TitleEnglish.insertEmbed(range.index, "image", `${BASE_URL}${url}`);
    } else if (TitleLaos.hasFocus()) {
      const range = TitleLaos.getSelection();
      TitleLaos.insertEmbed(range.index, "image", `${BASE_URL}${url}`);
    } else if (ContentEnglish.hasFocus()) {
      const range = ContentEnglish.getSelection();
      ContentEnglish.insertEmbed(range.index, "image", `${BASE_URL}${url}`);
    } else if (ContentLaos.hasFocus()) {
      const range = ContentLaos.getSelection();
      ContentLaos.insertEmbed(range.index, "image", `${BASE_URL}${url}`);
    }
  };

  const modulesContent = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, true] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons

          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

          [{ image: "image" }],
          [{ link: "link" }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
            t,
          ],
          [
            { font: [] }, // Thêm nhiều kiểu chữ
            { size: [] }, // Chọn size theo số
          ],
        ],
        handlers: {
          image: imgHandler,
        },
      },
    }),
    []
  );

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, true] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons

          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

          [{ link: "link" }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
            t,
          ],
        ],
      },
    }),
    []
  );

  return checkData.length && checkDataA_E.length && checkDataA_L.length ? (
    <div className={cx("wrapper_static_content", "static_content_ant")}>
      <div className={cx("home_page", "homePage")}>
        <h2>{t("admin_static.home.homePage")}</h2>
        <div className={cx("home_banner_heading", "homeBanner")}>
          <label>{t("admin_static.home.bannerTextE")}</label>
          <input
            type="text"
            id="fname1"
            value={bannerHeadingE}
            onChange={(e) => setBannerHeadingE(e.target.value)}
            onBlur={checkTitle}
          />
        </div>
        <div className="noticeOff">{t("admin_static.notice.changeLabel")}</div>
        <div className={cx("home_banner_heading", "homeBanner")}>
          <label>{t("admin_static.home.bannerTextLaos")}</label>
          <input
            type="text"
            id="fname1"
            value={bannerHeadingLao}
            onChange={(e) => setBannerHeadingLao(e.target.value)}
            onBlur={checkTitle}
          />
        </div>
        <div className="noticeOff">{t("admin_static.notice.changeLabel")}</div>
        <div className={cx("home_banner_image")}>
          <label>{t("admin_static.home.bannerImage")}</label>
          <div className={cx("upload_image")}>
            <Upload
              beforeUpload
              action="https://laoscitec.api.minastik.com/api/upload-image"
              listType="picture-card"
              fileList={fileList}
              // disabled

              onChange={handleChange}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={handleCancel_img}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </div>
        </div>
        <div className={cx("home_banner_table")}>
          <label>{t("admin_static.home.bannerTable")}</label>
          <div className="table_home">
            <Table
              rowKey={(re, index) => index}
              bordered
              dataSource={data}
              columns={columns}
              rowClassName="editable-row"
              pagination={false}
            />
          </div>
          <div className={cx("modal_wrapper")}>
            <Button type="primary" onClick={showModal}>
              <FontAwesomeIcon icon={faPlus} />
              {t("admin_static.home.add")}
            </Button>
            <Modal
              wrapClassName="modal_StacticContent"
              visible={isModalVisible}
              onOk={edittingRow ? save : handleOk}
              onCancel={handleCancel}
              cancelText={t("admin_static.handle.cancel")}
              okText={
                edittingRow
                  ? `${t("admin_static.handle.update")}`
                  : `${t("admin_static.handle.add")}`
              }
            >
              <table>
                <thead>
                  <tr>
                    <th className="table_header_modal">Icon</th>
                    <th colSpan={2} className="table_header_modal">
                      Description/ລາຍລະອຽດ
                    </th>
                    <th className="table_header_modal">
                      {t("admin_static.columns.amount")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table_content">
                      <ReactQuill
                        theme="snow"
                        modules={toolbarRowIcon}
                        value={iconRow}
                        onChange={setIconRow}
                      />
                    </td>
                    <td className="table_content">
                      <ReactQuill
                        theme="snow"
                        modules={toolbarDes}
                        value={description}
                        onChange={setdescription}
                        placeholder="type here ..."
                      />
                    </td>
                    <td className="table_content">
                      <ReactQuill
                        theme="snow"
                        modules={toolbarDes}
                        value={descriptionL}
                        onChange={setdescriptionL}
                        placeholder="ປະເທດລາວ..."
                      />
                    </td>
                    <td className="table_content">
                      <input
                        value={amountModal}
                        onChange={(e) => setAmountModal(e.target.value)}
                        placeholder="......"
                        type="text"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Modal>
          </div>
        </div>
      </div>

      <div className="noticeSumbitSuc">{t("admin_static.notice.success")}</div>
      <div className="noticeSumbitFail">
        {t("admin_static.notice.updateFail")}
      </div>
      <Space className="submit_btn">
        <Button onClick={(type) => openNotificationWithIcon(type)}>
          {t("admin_static.handle.update")}
        </Button>
      </Space>

      <div className={cx("about_page", "aboutUs")}>
        <h2>{t("admin_static.about-us-page.aboutPage")}</h2>

        <div className={cx("about_page_title", "aboutUsTitle")}>
          <div>
            <label>{t("admin_static.about-us-page.labelTextTitleE")}</label>
          </div>
          <ReactQuill
            theme="snow"
            id="quill1"
            ref={quillTitleE}
            onBlur={checkTitleAboutUs_E}
            modules={modules}
            value={titleAboutUs_E}
            onChange={setTitleAboutUs_E}
            placeholder={t("admin_static.about-us-page.labelPlaceholder")}
          />
          <div className="noticeOffAboutUs_E">
            {t("admin_static.notice.changeLabel")}
          </div>
        </div>
        <div className={cx("about_page_content", "aboutUsContent")}>
          <div>
            <label>{t("admin_static.about-us-page.labelTextContentE")}</label>
          </div>
          <ReactQuill
            theme="snow"
            id="quill2"
            ref={quillContentE}
            modules={modulesContent}
            value={textAboutUs_E}
            onChange={handleChangeContentE}
            placeholder={t("admin_static.about-us-page.labelPlaceholder")}
          />
        </div>

        <div className="noticeSumbitSucAboutUs_E">
          {t("admin_static.notice.success")}
        </div>
        <div className="noticeSumbitFailAboutUs_E">
          {t("admin_static.notice.updateFailAboutUs")}
        </div>
        <Space className="submit_btn">
          <Button onClick={(type) => openNotificationWithIconAbout_E(type)}>
            {t("admin_static.handle.update")}
          </Button>
        </Space>
      </div>

      <div className={cx("about_page", "aboutUs")}>
        <h2>{t("admin_static.about-us-page.aboutPage")}</h2>

        <div className={cx("about_page_title", "aboutUsTitle")}>
          <div>
            <label>{t("admin_static.about-us-page.labelTextTitleL")}</label>
          </div>
          <ReactQuill
            theme="snow"
            id="quill3"
            ref={quillTitleL}
            onBlur={checkTitleAboutUs_E}
            modules={modules}
            value={titleAboutUs_L}
            onChange={setTitleAboutUs_L}
            placeholder={t("admin_static.about-us-page.labelPlaceholder")}
          />
          <div className="noticeOffAboutUs_L">
            {t("admin_static.notice.changeLabel")}
          </div>
        </div>
        <div className={cx("about_page_content", "aboutUsContent")}>
          <div>
            <label>{t("admin_static.about-us-page.labelTextContentL")}</label>
          </div>
          <ReactQuill
            id="quill4"
            theme="snow"
            ref={quillContentL}
            modules={modulesContent}
            value={textAboutUs_L}
            onChange={handleChangeContentL}
            placeholder={t("admin_static.about-us-page.labelPlaceholder")}
          />
        </div>

        <div className="noticeSumbitSucAboutUs_L">
          {t("admin_static.notice.success")}
        </div>
        <div className="noticeSumbitFailAboutUs_L">
          {t("admin_static.notice.updateFailAboutUs")}
        </div>
        <Space className="submit_btn">
          <Button onClick={(type) => openNotificationWithIconAbout_L(type)}>
            {t("admin_static.handle.update")}
          </Button>
        </Space>
      </div>
    </div>
  ) : (
    components
  );
};
export default StacticsContent;
