import style from "./ProductList.module.scss";
import classNames from "classnames/bind";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import "./index.replace.scss";
import { Tabs, Pagination } from "antd";

// import merchImg from "@assets/images/Product_list/Rectangle_1_1.png";
// import fisherImg from "@assets/images/Product_list/Rectangle_1_2.png";
// import lobaImg from "@assets/images/Product_list/Rectangle_1_3.png";
import { Loading } from "../../../components/Loading";
import { NotData } from "../../../components/NotData";

import {
  getGroupIdProduct,
  getProduct_Admin,
} from "../../../api-url/admin/index";
import {
  getCategory,
  getCategoryGroupId,
  getCategoryById,
} from "../../../api-url/category";
import {
  getChildren,
  formatData,
  formatTreeData,
} from "../../../utils/functions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import { category } from "@routes/route_category";

const cx = classNames.bind(style);
const { TabPane } = Tabs;
const pageSize = 9;

export const ProductList = () => {
  const { t } = useTranslation();

  const tabPosition = "left";
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [changeTab, setChangeTab] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(pageSize);
  const [specificData, setSpecificData] = useState([]);
  const [paging, setPaging] = useState({});
  ///API Categories

  // const [searchParams] = useSearchParams();

  // const groupId = searchParams.get("groupId");
  // const groupIdParent = searchParams.get("groupIdParent");

  const [pageNumber, setPageNumber] = useState(1);
  const [components, setComponents] = useState(<Loading />);
  const location = useLocation();
  // const [menuArr, setMenuArr] = useState([]);
  const navigate = useNavigate();
  const [ID, setID] = useState(null);
  const [dataTest, setDataTest] = useState([]);

  const [isParent, setIsParent] = useState(true);
  // const [idParent,setIdParent] = useState(null)
  const lan = sessionStorage.getItem("LANGUAGE");
  const groupID = sessionStorage.getItem("groupID");
  const groupParentID = sessionStorage.getItem("groupParentID");
  const nameAnotherProduct = sessionStorage.getItem("nameAnotherProduct");
  const NameMainCategoryOther = sessionStorage.getItem("NameMainCategoryOther");
  const NameMainCategory = sessionStorage.getItem("NameMainCategory");
  const nameProduct = sessionStorage.getItem("nameProduct");

  useEffect(() => {
    if (lan === "laos") {
      return navigate(
        `/category/${NameMainCategoryOther}/${nameAnotherProduct}`
      );
    } else {
      return navigate(`/category/${NameMainCategory}/${nameProduct}`);
    }
  }, [
    NameMainCategory,
    NameMainCategoryOther,
    lan,
    nameAnotherProduct,
    nameProduct,
    navigate,
  ]);
  useEffect(() => {
    setTimeout(() => {
      setComponents(<NotData />);
    }, 10000);
    getCategoryGroupId(groupID)
      .then((res) => res.data.data)
      .then((data) => {
        data.forEach((item, index) => {
          const language = lan === "laos" ? 2 : 1;
          if (item.languageId === language) {
            setID(item._id);
          }
        });
        getCategoryGroupId(groupParentID)
          .then((res) => res.data.data)
          .then((data) => {
            var idParent;
            data.forEach((item, index) => {
              const language = lan === "laos" ? 2 : 1;
              if (item.languageId === language) {
                idParent = item._id;
              }
            });
            return idParent;
          })
          .then((idParent) => {
            // console.log(idParent);
            getCategoryById(idParent)
              .then((res) => res.data.data)
              .then(async (dataInput) => {
                await getChildren(dataInput, formatTreeData, getCategoryById);
                return dataInput;
              })
              .then((dataInput) => {
                dataInput.sort(function (a, b) {
                  let beforeData = a.name.toUpperCase();
                  let afterData = b.name.toUpperCase();

                  if (beforeData < afterData) {
                    return -1;
                  }
                  if (beforeData > afterData) {
                    return 1;
                  }
                  return 0;
                });
                const data = dataInput?.reduce((acc, child, index) => {
                  const lastOrder = acc[acc.length - 1]?.order;
                  if (!lastOrder) {
                    child.order = 0;
                    const subChilds = child?.children;
                    subChilds.forEach((child) => (child.order = 1));
                    subChilds.forEach((c) => (c.idParent = child.id));
                    subChilds.forEach((c) => (c.groupIdParent = child.groupId));
                    child.groupIdParent = child.groupId;
                    child.isParent = true;
                    return [child, ...subChilds];
                  } else {
                    child.order = lastOrder + 1;
                    const subChilds = child?.children;
                    subChilds.forEach((child) => (child.order = lastOrder + 2));
                    subChilds.forEach((c) => (c.idParent = child.id));
                    subChilds.forEach((c) => (c.groupIdParent = child.groupId));
                    child.isParent = true;
                    child.groupIdParent = child.groupId;
                    return [...acc, child, ...subChilds];
                  }
                }, []);
                setDataTest(data);
              });
          });
      });
    // console.log("abccc");
  }, [groupID, groupParentID, lan, location]);

  const [activeKey, setActiveKey] = useState(() => {
    const pathActive = location.pathname.split("/");
    return decodeURI(pathActive[pathActive?.length - 1].replace(/_/g, " "));
  });
  // console.log("location", location);
  useEffect(() => {
    setActiveKey(() => {
      const pathActive = location.pathname.split("/");
      return decodeURI(pathActive[pathActive?.length - 1].replace(/_/g, " "));
    });
  }, [location]);

  setTimeout(() => {
    setComponents(<NotData />);
  }, 10000);

  const getProduct = useCallback(async (pageNumber) => {
    getProduct_Admin(pageNumber)
      .then((res) => res.data)
      .then((data) => {
        // console.log(data.Paging);
        setPaging(data.Paging);
        return data.data;
      })
      .then((data) => {
        // console.log(data);

        setData((prev) => {
          return [...prev, ...data];
        });
      })
      .then()

      .catch((err) => console.log(err));
  }, []);
  const onChange = (pageNumber, pageSize) => {
    // console.log(paging.totalCount);
    // console.log(pageSize);
    setCurrent(pageNumber);
    setMinIndex((pageNumber - 1) * pageSize);
    setMaxIndex(pageNumber * pageSize);
    setPageNumber(Math.ceil(paging.totalCount / paging.pageSize));
  };
  useEffect(() => {
    // console.log("useEffect................");
    getProduct_Admin(1)
      .then((res) => {
        // console.log("useEffect................", res);

        return res?.data;
      })
      .then((data) => {
        // console.log(data);
        setPaging(data.Paging);
        return data?.data;
      })
      .then((data) => {
        // console.log(data);

        setData((prev) => [...prev, ...data]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const callback = async () => {
      if (paging?.pageSize && paging?.totalCount) {
        const totalPage = Math.ceil(paging?.totalCount / paging?.pageSize);
        if (totalPage && totalPage > 1) {
          for (let i = 2; i <= totalPage; i++) {
            await getProduct(i);
          }
        }
      }
    };
    callback();
  }, [getProduct, paging?.pageSize, paging?.totalCount]);
  // console.log(data)
  useEffect(() => {
    // console.log("dataaaaa", data);
    setSpecificData(() => {
      // console.log(data);
      // console.log(activeKey);
      const newState = data.filter(
        (item) => item?.categoryId?.name === activeKey
      );
      // console.log("Received", newState);
      return newState.reverse();
    });
  }, [activeKey, data]);

  const handleChangeTab = (key) => {
    // console.log(key);
    setChangeTab(key);
    setActiveKey(key);
    handleChangeData(key);
    setCurrent(1);
    setMinIndex(0);
    setMaxIndex(1 * pageSize);
  };
  // console.log(dataTest.length);
  const handleSendData = (id, groupId, name) => {
    sessionStorage.setItem("groupId", groupId);

    getGroupIdProduct(groupId)
      .then((res) => res.data.data)
      .then((data) => {
        data.forEach((item, index) => {
          if (item._id !== id) {
            if (lan === "laos") {
              sessionStorage.setItem("id", item._id);
              sessionStorage.setItem("nameProductDetail", item.name);
              sessionStorage.setItem("idOther", id);
              sessionStorage.setItem("nameProductDetailOther", name);
            } else {
              sessionStorage.setItem("id", id);
              sessionStorage.setItem("nameProductDetail", name);
              sessionStorage.setItem("idOther", item._id);
              sessionStorage.setItem("nameProductDetailOther", item.name);
            }
          }
        });
      });
  };
  const [isChildren, setIsChildren] = useState([]);

  useEffect(() => {
    const pathActive = location.pathname.split("/");
    const naaa = decodeURI(
      pathActive[pathActive?.length - 1].replace(/_/g, " ")
    );
    const dataNeed = dataTest.filter((item) => item.name === naaa);
    setIsChildren(dataNeed[0]?.children);
  }, [dataTest, location.pathname]);

  const handleChangeData = (
    name,
    groupId,
    id,
    isParent,
    children,
    idParent,
    groupIdParent
  ) => {
    setChangeTab(name);
    setActiveKey(name);
    setIsParent(isParent);
    setIsChildren(children);
    if (isParent) {
      getCategoryGroupId(groupId)
        .then((res) => res.data.data)
        .then((data) => {
          data.forEach((item, index) => {
            if (item._id !== id) {
              if (lan === "laos") {
                sessionStorage.setItem("nameAnotherProduct", name);
                sessionStorage.setItem("nameProduct", item.name);
              } else {
                sessionStorage.setItem("nameProduct", name);
                sessionStorage.setItem("nameAnotherProduct", item.name);
              }
            }
          });
        });
    } else {
      getCategoryGroupId(groupIdParent)
        .then((res) => res.data.data)
        .then((data) => {
          data.forEach((item, index) => {
            if (item._id !== idParent) {
              if (lan === "laos") {
                sessionStorage.setItem("nameAnotherProduct", name);
                sessionStorage.setItem("nameProduct", item.name);
              } else {
                sessionStorage.setItem("nameProduct", name);
                sessionStorage.setItem("nameAnotherProduct", item.name);
              }
            }
          });
        });
    }
  };

  return dataTest.length ? (
    <>
      <div className={cx("wrapper_product_list", "product-list-container")}>
        <div className={cx("header_prodList")}>
          <h2 className={cx("custom_font_family")}>{activeKey}</h2>
        </div>
        <div className={cx("body_prodList")}>
          <div className={cx("list_product")}>
            <div className={cx("header_product")}>
              <div className={cx("product_name")}>
                {dataTest?.map((item, index) => (
                  <>
                    <ul
                      style={{ cursor: "pointer", fontWeight: "600" }}
                      onClick={() => {
                        handleChangeData(
                          item.name,
                          item?.groupId[0],
                          item._id,
                          item.isParent,
                          item.children,
                          item?.idParent,
                          item?.groupIdParent[0]
                        );
                      }}
                      className={cx(
                        "item_parent",
                        item.name === activeKey && "activeItem",
                        item.isParent && "classParent"
                      )}
                      key={index}
                    >
                      {item.name}
                    </ul>
                  </>
                ))}
              </div>
              <div className={cx("product_detail__", isParent && "parent")}>
                {isParent ? (
                  <>
                    {isChildren?.map((item, index) => (
                      <div key={index} className={cx("product")}>
                        <div className={cx("product_img")} key={index}>
                          <div onClick={() => handleChangeTab(item?.name)}>
                            <img
                              src={`https://laoscitec.api.minastik.com${item?.images[0]}`}
                              alt=""
                            />
                          </div>
                          <p className={cx("sub_title")}>{item?.name}</p>
                        </div>
                      </div>
                    ))}
                    {specificData?.map(
                      (data, index) =>
                        index >= minIndex &&
                        index < maxIndex && (
                          <div className={cx("product")}>
                            <div
                              className={cx("product_img")}
                              key={data.name + data._id}
                            >
                              <Link
                                onClick={() =>
                                  handleSendData(
                                    data._id,
                                    data.groupId,
                                    data.name.replace(/ /g, "_")
                                  )
                                }
                                to={{
                                  pathname: `${decodeURI(
                                    location.pathname
                                  )}/${data.name.replace(/ /g, "_")}`,
                                  search: `?id=${data._id}&groupId=${data.groupId}`,
                                }}
                              >
                                <div key={data.name + data._id}>
                                  <img
                                    src={`https://laoscitec.api.minastik.com${data.images[0]}`}
                                    alt=""
                                  />
                                </div>
                                <p className={cx("sub_title")}>{data.name}</p>
                              </Link>
                            </div>
                          </div>
                        )
                    )}
                  </>
                ) : specificData.length ? (
                  <>
                    <div className={cx("product")}>
                      {specificData?.map(
                        (data, index) =>
                          index >= minIndex &&
                          index < maxIndex && (
                            <div
                              className={cx("product_img")}
                              key={data.name + data._id}
                            >
                              <Link
                                onClick={() =>
                                  handleSendData(
                                    data._id,
                                    data.groupId,
                                    data.name.replace(/ /g, "_")
                                  )
                                }
                                to={{
                                  pathname: `${decodeURI(
                                    location.pathname
                                  )}/${data.name.replace(/ /g, "_")}`,
                                  search: `?id=${data._id}&groupId=${data.groupId}`,
                                }}
                              >
                                <div key={data.name + data._id}>
                                  <img
                                    src={`https://laoscitec.api.minastik.com${data.images[0]}`}
                                    alt=""
                                  />
                                </div>
                                <p className={cx("sub_title")}>{data.name}</p>
                              </Link>
                            </div>
                          )
                      )}
                    </div>

                    <Pagination
                      pageSize={paging?.pageSize - 1}
                      current={current}
                      total={specificData?.length}
                      onChange={onChange}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    />
                  </>
                ) : (
                  components
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    components
  );
};
