import { ConfigProvider } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/footer";
import Breadcrumb from "./Breadcrumb/index";

export const MainLayout = ({ children }) => {
  return (
    <ConfigProvider locale={vn}>
      <Header />
      <Breadcrumb />
      {children}
      <Footer />
    </ConfigProvider>
  );
};
