import style from "./ProductList.module.scss";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import "./index.replace.scss";
import { Tabs, Pagination } from "antd";
import { Loading } from "../../../components/Loading";
import { NotData } from "../../../components/NotData";
import { getProduct_Admin } from "../../../api-url/admin/index";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const cx = classNames.bind(style);
const { TabPane } = Tabs;
const pageSize = 9;

export const ProductListEducation = () => {
  const { t } = useTranslation();

  // const [activeKey, setActiveKey] = useState("Speacial Vehicle");
  const tabPosition = "left";
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [changeTab, setChangeTab] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(pageSize);
  const [specificData, setSpecificData] = useState([]);
  const [paging, setPaging] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const [components, setComponents] = useState(<Loading />);
  const location = useLocation();
  const [menuArr, setMenuArr] = useState();

  useEffect(() => {
    const {
      state: { foo },
    } = location;
    if (foo) {
      const { children } = JSON.parse(foo);
      // console.log(children);
      const data = children?.reduce((acc, child, index) => {
        const lastOrder = acc[acc.length - 1]?.order;
        if (!lastOrder) {
          child.order = 0;
          const subChilds = child?.children;
          subChilds.forEach((child) => (child.order = 1));
          child.isParent = true;
          return [child, ...subChilds];
        } else {
          child.order = lastOrder + 1;
          const subChilds = child?.children;
          subChilds.forEach((child) => (child.order = lastOrder + 2));
          child.isParent = true;
          return [...acc, child, ...subChilds];
        }
      }, []);
      // console.log("data>>>>>>>>>>>>", data);
      setMenuArr(data);
    }
  }, [location]);
  const [activeKey, setActiveKey] = useState(
    `${location.pathname.split("/education/").join("").replace(/_/, " ")}`
  );
  // console.log(
  //   location.pathname.split("/education/").join("").replace(/_/, " ")
  // );
  setTimeout(() => {
    setComponents(<NotData />);
  }, 10000);
  const getProduct = useCallback(async (pageNumber) => {
    getProduct_Admin(pageNumber)
      .then((res) => res.data)
      .then((data) => {
        // console.log(data.Paging);
        setPaging(data.Paging);
        return data.data;
      })
      .then((data) => {
        // console.log(data);
        setData((prev) => [...prev, ...data]);
      })
      .catch((err) => console.log(err));
  }, []);
  const onChange = (pageNumber, pageSize) => {
    // console.log(paging.totalCount);
    // console.log(pageSize);
    setCurrent(pageNumber);
    setMinIndex((pageNumber - 1) * pageSize);
    setMaxIndex(pageNumber * pageSize);
    setPageNumber(Math.ceil(paging.totalCount / paging.pageSize));
  };
  useEffect(() => {
    getProduct_Admin(1)
      .then((res) => {
        // console.log(res);
        return res?.data;
      })
      .then((data) => {
        // console.log(data);
        setPaging(data.Paging);
        return data?.data;
      })
      .then((data) => {
        // console.log(data);

        setData((prev) => [...prev, ...data]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const callback = async () => {
      if (paging?.pageSize && paging?.totalCount) {
        const totalPage = Math.ceil(paging?.totalCount / paging?.pageSize);
        if (totalPage && totalPage > 1) {
          for (let i = 2; i <= totalPage; i++) {
            await getProduct(i);
          }
        }
      }
    };
    callback();
  }, [getProduct, paging?.pageSize, paging?.totalCount]);
  // console.log(data);
  useEffect(() => {
    setSpecificData(() => {
      const newState = data.filter(
        (item) => item?.categoryId?.name === activeKey
      );
      // console.log("Received", newState);
      return newState.reverse();
    });
  }, [activeKey, data]);

  const handleChangeTab = (key) => {
    // console.log(key);
    setChangeTab(key);
    setActiveKey(key);
    setCurrent(1);
    setMinIndex(0);
    setMaxIndex(1 * pageSize);
  };
  return (
    <>
      <>
        <div
          className={cx(
            "wrapper_product_list",
            "product-list-education-container"
          )}
        >
          <div className={cx("header_prodList")}>
            <h2>{t("product_list_education.education")}</h2>
          </div>
          <div className={cx("body_prodList")}>
            <div className={cx("list_product")}>
              <div className={cx("header_product")}>
                <div className={cx("product_name")}>
                  <Tabs
                    activeKey={activeKey}
                    tabPosition={tabPosition}
                    onChange={handleChangeTab}
                  >
                    {menuArr?.map((item) => {
                      return (
                        <>
                          <TabPane
                            tab={item.name}
                            key={item.name}
                            // hideAdd
                            // defaultActiveKey={"activeKey"}
                            // popupClassName="acb"
                            className="acb"
                          >
                            {specificData.length ? (
                              <>
                                <div className={cx("product")}>
                                  {specificData?.map(
                                    (data, index) =>
                                      index >= minIndex &&
                                      index < maxIndex && (
                                        <div
                                          className={cx("product_img")}
                                          key={index}
                                        >
                                          <Link
                                            to={{
                                              pathname: `/product_detail/${data.name.replace(
                                                / /g,
                                                "_"
                                              )}`,
                                              search: `?id=${data._id}&groupId=${data.groupId}`,
                                            }}
                                          >
                                            <div>
                                              <img
                                                src={`https://laoscitec.api.minastik.com${data.images[0]}`}
                                                alt=""
                                              />
                                            </div>
                                            <p className={cx("sub_title")}>
                                              {data.name}
                                            </p>
                                          </Link>
                                        </div>
                                      )
                                  )}
                                </div>

                                <Pagination
                                  pageSize={paging?.pageSize - 1}
                                  current={current}
                                  total={specificData?.length}
                                  onChange={onChange}
                                />
                              </>
                            ) : (
                              components
                            )}
                          </TabPane>
                        </>
                      );
                    })}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
